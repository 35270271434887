import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { dashboardColors } from '../../../Config';
import DashboardLoader from './DashboardLoader';

// Register the necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function FinanceManagerInvoicesReport({ barChartName = 'Bar Chart Name', barchartData }) {

    const [InvoicesReport, setInvoicesReport] = useState([]);
    const [barChartFacilitiesList, setBarChartFacilitiesList] = useState([]);
    const [barChartInvoiceTypesList, setBarChartInvoiceTypesList] = useState([]);
    const [barChartSelectedFacility, setBarChartSelectedFacility] = useState('');
    const [barChartSelectedInvoiceType, setBarChartSelectedInvoiceType] = useState('');

    const generateUniqueColor = (index) => {
        const colors = dashboardColors;
        return colors[index % colors.length];
    };

    useEffect(() => {
        filterData();
    }, [barchartData]);


    const filterData = () => {
        setBarChartFacilitiesList([...new Set(barchartData?.map(item => item["facility_name"]).filter(name => name !== null))]);
        setBarChartInvoiceTypesList([...new Set(barchartData?.map(item => item["invoice_type"]).filter(name => name !== null))]);

        const filteredData = barchartData?.filter(item => {
            return (
                (barChartSelectedFacility ? item["facility_name"] === barChartSelectedFacility : true) &&
                (barChartSelectedInvoiceType ? item["invoice_type"] === barChartSelectedInvoiceType : true)
            );
        });

        const transformData = (data) => {
            const invoiceTypes = [...new Set(data?.map(item => item["invoice_type"]))];
            const billingPeriods = [...new Set(data?.map(item => item["billing_period"]))].sort((a, b) => new Date(a) - new Date(b));
            return invoiceTypes.map((type, index) => {
                return {
                    label: type,
                    backgroundColor: generateUniqueColor(index),
                    data: billingPeriods.map(period => {
                        const monthlyData = data.filter(item => item["billing_period"] === period && item["invoice_type"] === type);
                        return monthlyData.reduce((sum, item) => sum + parseFloat(item["invoice_amount"]), 0);
                    }),
                };
            });
        };

        setInvoicesReport(transformData(filteredData?.length ? filteredData : barchartData));
    }

    const handleFacilityChange = (e) => {
        setBarChartSelectedFacility(e.target.value);
        const filteredData = barchartData?.filter(item => {
            return (
                (e.target.value ? item["facility_name"] === e.target.value : true) &&
                (barChartSelectedInvoiceType ? item["invoice_type"] === barChartSelectedInvoiceType : true)
            );
        });

        const invoiceTypes = [...new Set(filteredData?.map(item => item["invoice_type"]))];
        const billingPeriods = [...new Set(filteredData?.map(item => item["billing_period"]))].sort((a, b) => new Date(a) - new Date(b));
        const transformedData = invoiceTypes.map((type, index) => {
            return {
                label: type,
                backgroundColor: generateUniqueColor(index),
                data: billingPeriods.map(period => {
                    const monthlyData = filteredData.filter(item => item["billing_period"] === period && item["invoice_type"] === type);
                    return monthlyData.reduce((sum, item) => sum + parseFloat(item["invoice_amount"]), 0);
                }),
            };
        });

        setInvoicesReport(transformedData);
    };

    const billingPeriods = [...new Set(barchartData?.map(item => item["billing_period"]))].sort((a, b) => new Date(a) - new Date(b));

    const data = {
        labels: billingPeriods,
        datasets: InvoicesReport,
    };


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        barPercentage: 0.35,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Invoice Amount',
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    return (
        <div className="container dashboard-chart-container" >
            <h5 style={{ textAlign: "start", width: "100%" }}>{barChartName}</h5>
            {barchartData ? <>
                <div className="d-flex justify-content-end gap-2 w-100">
                    <div className="">
                        <label className="form-label">
                            Select Facility:
                            <select className="form-select form-select-sm" style={{ width: "130px", textOverflow: "ellipsis" }} value={barChartSelectedFacility} onChange={handleFacilityChange}>
                                <option value="">All</option>
                                {barChartFacilitiesList?.map((facility) => (
                                    <option key={facility} value={facility}>
                                        {facility}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
                <div style={{ width: "100%", height: "250px" }}>
                    <Bar data={data} options={options} />
                </div>
            </>
                :
                <DashboardLoader />
            }
        </div>
    );
}

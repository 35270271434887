import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { dashboardColors } from '../../../Config';
import DashboardLoader from './DashboardLoader';

// Register the necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const generateUniqueColor = (index) => {
    const colors = dashboardColors;
    return colors[index % colors.length];
};

export default function FacilityManagerElectricityReport({ barChartName = 'Line Chart Name', ElectricityReportData, viewMode, setViewMode }) {
    const [barChartSelectedFacility, setBarChartSelectedFacility] = useState('');
    const [consumptionType, setConsumptionType] = useState('eb_consumption');

    const transformData = (data, periods) => {
        const facilityNames = [...new Set(data.map(item => item["facility_name"]))];
        return facilityNames.map((name, index) => ({
            label: name,
            borderColor: generateUniqueColor(index),
            backgroundColor: generateUniqueColor(index),
            data: periods.map(period => {
                const monthlyData = data.filter(item => item["billing_period"] === period && item["facility_name"] === name);
                return monthlyData.reduce((sum, item) => sum + parseFloat(item[consumptionType]), 0);
            }),
            fill: true,
            borderWidth: 2, // Thin lines
            pointRadius: 2, // Small circles
            pointHoverRadius: 5, // Slightly larger on hover
            tension: 0.4, // Smooth curves
        }));
    };

    const facilitiesList = useMemo(() => {
        return ElectricityReportData ? [...new Set(ElectricityReportData.map(item => item["facility_name"]).filter(name => name !== null))] : [];
    }, [ElectricityReportData]);

    const billingPeriods = useMemo(() => {
        return ElectricityReportData ? [...new Set(ElectricityReportData.map(item => item["billing_period"]))].sort((a, b) => new Date(a) - new Date(b)) : [];
    }, [ElectricityReportData]);

    const filteredData = useMemo(() => {
        return ElectricityReportData ? ElectricityReportData.filter(item => barChartSelectedFacility ? item["facility_name"] === barChartSelectedFacility : true) : [];
    }, [ElectricityReportData, barChartSelectedFacility]);

    const InvoicesReport = useMemo(() => {
        return transformData(filteredData, billingPeriods);
    }, [filteredData, billingPeriods, consumptionType]);

    const handleFacilityChange = useCallback((facility) => {
        setBarChartSelectedFacility(facility);
    }, []);

    const data = useMemo(() => ({
        labels: billingPeriods,
        datasets: InvoicesReport,
    }), [billingPeriods, InvoicesReport]);

    const options = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Show grid lines
                    color: '#e0e0e0', // Light grey color for grid lines
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: consumptionType === 'eb_consumption' ? 'Electricity Consumption (kWh)' : 'DG Consumption (kWh)',
                },
                grid: {
                    display: false, // Show grid lines
                    color: '#e0e0e0', // Light grey color for grid lines
                },
            },
        },
    }), [consumptionType]);

    return (
        <div className="container" style={{ minHeight: "380px", height: "100%", width: "100%", backgroundColor: "#FFFFFF", padding: "10px" }}>
            <h5 style={{ textAlign: "start", width: "100%" }}>{barChartName}</h5>
            {ElectricityReportData ? (
                <>
                    <div className="d-flex justify-content-end gap-2 w-100">
                        <div className="">
                            <label className="form-label">
                                Select Facility:
                                <select className="form-select form-select-sm" style={{ width: "100px" }} value={barChartSelectedFacility} onChange={(e) => {
                                    handleFacilityChange(e.target.value);
                                }}>
                                    <option value="">All</option>
                                    {facilitiesList.map((facility) => (
                                        <option key={facility} value={facility}>
                                            {facility}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div className="">
                            <label className="form-label">
                                Consumption Type:
                                <select className="form-select form-select-sm" style={{ width: "130px", textOverflow: "ellipsis" }} value={consumptionType} onChange={(e) => {
                                    setConsumptionType(e.target.value);
                                }}>
                                    <option value="eb_consumption">Electricity</option>
                                    <option value="dg_consumption">DG</option>

                                </select>
                            </label>
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "250px" }}>
                        <Line data={data} options={options} />
                    </div>
                    <div className="legend-container" style={{ overflowX: 'auto', textWrap: "nowrap", width: "500px", margin: "auto" }}>
                        {InvoicesReport.map((dataset, index) => (
                            dataset?.label && (
                                <span key={index} style={{ marginRight: '10px', display: 'inline-block' }}>
                                    <span style={{ backgroundColor: dataset.backgroundColor, width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></span>
                                    {dataset.label}
                                </span>
                            )
                        ))}
                    </div>
                </>
            ) : (
                <DashboardLoader />
            )}
        </div>
    );
}

import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import * as d3 from 'd3';
import { dashboardColors } from '../../../Config';
import DashboardLoader from './DashboardLoader';
import { numberFormatWithCommas } from '../ReusableFunctions';

const FacilityManagerFacilitiesTreeMap = ({ facilitiesArea }) => {
    const uniqueFacilities = [...new Set(facilitiesArea?.map(facility => facility.facility_name))];
    const [selectedFacility, setSelectedFacility] = useState(uniqueFacilities[0]);
    const [filteredData, setFilteredData] = useState([]);
    const svgRef = useRef();

    useEffect(() => {
        setFilteredData(facilitiesArea?.filter(
            (facility) => selectedFacility ? facility.facility_name === selectedFacility : facility.facility_name === uniqueFacilities[0]
        ));
    }, [selectedFacility, facilitiesArea]);

    const generateUniqueColor = useCallback((index) => {
        return dashboardColors[index % dashboardColors.length];
    }, []);

    const data = useMemo(() => ({
        name: 'root',
        children: filteredData?.map((facility, index) => ({
            name: facility.customer_name,
            value: 1, // Set a constant value for each node
            area: facility.area,
            color: generateUniqueColor(index),
        })) || [],
    }), [filteredData, generateUniqueColor]);

    useEffect(() => {
        if (svgRef.current && data.children.length > 0) {
            const width = svgRef.current.parentElement.offsetWidth;
            const height = 400;

            const root = d3.hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value);

            d3.treemap()
                .size([width, height])
                .padding(1)
                (root);

            const svg = d3.select(svgRef.current)
                .attr('width', width)
                .attr('height', height);

            svg.selectAll('g').remove();

            const cell = svg.selectAll('g')
                .data(root.leaves())
                .enter().append('g')
                .attr('transform', d => `translate(${d.x0},${d.y0})`);

            cell.append('rect')
                .attr('id', d => d.data.name)
                .attr('width', d => d.x1 - d.x0)
                .attr('height', d => d.y1 - d.y0)
                .attr('fill', d => d.data.color);

            cell.append('title')
                .text(d => `${d.data.name}\n${numberFormatWithCommas(d.data.area)} sq ft`);

            cell.append('foreignObject')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', d => d.x1 - d.x0)
                .attr('height', d => d.y1 - d.y0)
                .append('xhtml:div')
                .style('width', '100%')
                .style('height', '100%')
                .style('display', 'flex')
                .style('justify-content', 'center')
                .style('align-items', 'center')
                .style('overflow', 'hidden')
                .style('text-overflow', 'ellipsis')
                .style('white-space', 'wrap')
                .style('font-size', '8px')
                .style('color', 'black')
                .style('padding', '5px')
                .text(d => `${d.data.name} - ${numberFormatWithCommas(d.data.area)} sq ft`);
        }
    }, [data]);

    return (
        <div className='dashboard-chart-container'>
            <h5>Customer wise space utilization by facility</h5>
            {facilitiesArea ? <>
                <div className='d-flex justify-content-end gap-2 w-100'>
                    <div className="">
                        <label className="form-label">
                            Facility:
                            <select className="form-select form-select-sm" style={{ width: "130px", textOverflow: "ellipsis" }} value={selectedFacility} onChange={(e) => setSelectedFacility(e.target.value)}>
                                {uniqueFacilities.map((facilityName) => (
                                    <option key={facilityName} value={facilityName}>
                                        {facilityName}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
                <div style={{ height: '100%', width: '100%' }}>
                    <svg ref={svgRef}></svg>
                    <div className="mt-2">
                        <strong>Total Area: </strong>
                        {filteredData?.length > 0 ? numberFormatWithCommas(filteredData[0].facility_total_area) : 0} sq ft
                    </div>
                </div>
            </> :
                <DashboardLoader />
            }
        </div>
    );
};

export default FacilityManagerFacilitiesTreeMap;

import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Element from './Element';
import { FaRegClone } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { MdDelete, MdErrorOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { FETCHING_ERROR, getAllTemplates } from '../../Redux/Actions/Actions';
import FormLoader from '../Reusable_Components/FromLoader';
import { Button, Modal } from 'react-bootstrap';
import { sorting_lists, timeElapsed } from '../Reusable_Components/ReusableFunctions';
import AlertPopUp from '../Reusable_Components/Alert';
import TableComponent from '../Reusable_Components/TableComponent';
import Search from '../Reusable_Components/Search';

export default function TemplatesList() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const templatesList = useSelector((state) => state.templatesList)
    const loading = useSelector((state) => state.loading)
    const fetchingError = useSelector((state) => state.fetchingError)

    const [templates, setTemplates] = useState([])
    const [preview, setPreview] = useState(false);
    const [previewObject, setPreviewObject] = useState([])
    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        // let templatesList = JSON.parse(localStorage.getItem("Templates"))
        // templatesList = templatesList?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
        // setTemplates(templatesList ? templatesList : [])
        dispatch(getAllTemplates())
    }, [])

    useEffect(() => {
        setTemplates(sorting_lists(templatesList))
    }, [templatesList]);
    const handleEnable = (each) => {
        return false;
    }

    return (
        <div>
            {preview ? <div className='d-flex flex-column p-3' style={{ width: "210mm", backgroundColor: "white" }}>
                {previewObject?.map((previewObj, index) => {
                    return <div key={previewObj.id} id={previewObj.id + " " + index} className='d-flex'>
                        {previewObj.items.map((item, index) => {
                            return <Element preview={true} key={index} object={item} />
                        })}
                    </div>
                })}
                <div className='d-flex justify-content-end w-100'>
                    <button className='btn btn-secondary' onClick={() => {
                        setPreview(false)
                        setPreviewObject([])
                    }}>Back</button>
                </div>
            </div> : <>
                <div className='d-flex justify-content-between my-2'>
                    <h3>Templates</h3>
                    <div className='d-flex align-items-center gap-2'>
                        <Search
                            value={searchText}
                            handleSearch={(e) => {
                                setSearchText(e.target.value)
                                const filteredData = templatesList.filter((template) => template.template_name.toLowerCase().includes(e.target.value.toLowerCase()))
                                setTemplates(filteredData)
                            }}
                        />
                        <Link to={'/form/templates'}><button className='btn btn-secondary'>Create new template</button></Link>
                    </div>
                </div>
                <TableComponent
                    headers={[
                        {
                            name: "Template Name",
                            type: "text",
                            key: "template_name"
                        }, {
                            name: "Description",
                            type: "text",
                            key: "description"
                        }, {
                            name: "Created By",
                            type: "text",
                            key: "created_by",
                        }, {
                            name: "Created",
                            type: "time_stamp",
                            key: "created_at",
                            sort: true
                        },
                        {
                            name: "Action",
                            type: "button",
                            buttons: [{
                                name: "View",
                                icon: "./images/icons/ViewViewIconEye.svg",
                                disable: handleEnable,
                                action: (data) => {
                                    setPreview(true)
                                    setPreviewObject(JSON.parse(data?.json_data))
                                }
                            },
                            {
                                name: "Clone",
                                icon: "./images/icons/clone.svg",
                                disable: handleEnable,
                                action: (data) => {
                                    let json_data = JSON.parse(data?.json_data)
                                    navigate('/form/templateClone', { state: { data: json_data } })
                                }
                            }

                            ]
                        }
                    ]}
                    data={templates}
                />
            </>}
            {fetchingError && <AlertPopUp
                alertShow={fetchingError}
                alertType="error"
                alertMsg="Error"
                alertValue={fetchingError}
                alertButtons={[{ name: "Close", action: () => { dispatch({ type: FETCHING_ERROR, payload: null }) } }, {
                    name: "Retry", action: () => {
                        dispatch(getAllTemplates())
                        dispatch({
                            type: FETCHING_ERROR,
                            payload: null
                        })
                    }
                }]}
            />}
            {loading && <FormLoader />}
        </div>
    )
}

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { dashboardColors } from '../../../Config';
import DashboardLoader from './DashboardLoader';

// Register the necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const generateUniqueColor = (index) => {
    const colors = dashboardColors;
    return colors[index % colors.length];
};

export default function FacilityManagerConsumptionByCustomer({ barChartName = 'Line Chart Name', consumptioByCustomer }) {
    const [barChartSelectedCustomer, setBarChartSelectedCustomer] = useState('');
    const [consumptionType, setConsumptionType] = useState('eb_consumption');

    const transformData = (data, periods) => {
        const customerNames = [...new Set(data.map(item => item["customer_name"]))];
        return customerNames.map((name, index) => ({
            label: name,
            borderColor: generateUniqueColor(index),
            backgroundColor: generateUniqueColor(index),
            data: periods.map(period => {
                const monthlyData = data.filter(item => item["billing_period"] === period && item["customer_name"] === name);
                return monthlyData.reduce((sum, item) => sum + parseFloat(consumptionType ? item[consumptionType] : item["eb_consumption"]), 0);
            }),
            fill: false,
            borderWidth: 2, // Thin lines
            pointRadius: 2, // Small circles
            pointHoverRadius: 5, // Slightly larger on hover
            tension: 0.4, // Smooth curves
        }));
    };

    const customersList = useMemo(() => {
        return consumptioByCustomer ? [...new Set(consumptioByCustomer.map(item => item["customer_name"]).filter(name => name !== null))] : [];
    }, [consumptioByCustomer]);

    const billingPeriods = useMemo(() => {
        return consumptioByCustomer ? [...new Set(consumptioByCustomer.map(item => item["billing_period"]))].sort((a, b) => new Date(a) - new Date(b)) : [];
    }, [consumptioByCustomer]);

    const filteredData = useMemo(() => {
        return consumptioByCustomer ? consumptioByCustomer.filter(item => barChartSelectedCustomer ? item["customer_name"] === barChartSelectedCustomer : true) : [];
    }, [consumptioByCustomer, barChartSelectedCustomer]);

    const InvoicesReport = useMemo(() => {
        return transformData(filteredData, billingPeriods);
    }, [filteredData, billingPeriods, consumptionType]);

    const handleCustomerChange = useCallback((customer) => {
        setBarChartSelectedCustomer(customer);
    }, []);

    const handleChangeConsumptionType = (value) => {
        setConsumptionType(value);
    }

    const data = useMemo(() => ({
        labels: billingPeriods,
        datasets: InvoicesReport,
    }), [billingPeriods, InvoicesReport]);

    const options = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Show grid lines
                    // Light grey color for grid lines
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: consumptionType === 'eb_consumption' ? 'EB Consumption in (kWh)' :
                        consumptionType === 'dg_consumption' ? 'DG Consumption in (kWh)' :
                            'Water Consumption in (kL)',
                },
                grid: {
                    display: false, // Show grid lines
                    color: '#e0e0e0', // Light grey color for grid lines
                },
            },
        },
    }), [consumptionType]);

    return (
        <div className="container dashboard-chart-container">
            <h5 style={{ textAlign: "start", width: "100%" }}>{barChartName}</h5>
            {consumptioByCustomer ? (
                <>
                    <div className="d-flex justify-content-end gap-2 w-100">
                        <div className="">
                            <label className="form-label">
                                Select Customer:
                                <select className="form-select form-select-sm" style={{ width: "130px", textOverflow: "ellipsis" }} value={barChartSelectedCustomer} onChange={(e) => {
                                    handleCustomerChange(e.target.value);
                                }}>
                                    <option value="">All</option>
                                    {customersList.map((customer) => (
                                        <option key={customer} value={customer}>
                                            {customer}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div className="">
                            <label className="form-label">
                                Cosumption Type :
                                <select className="form-select form-select-sm" style={{ width: "130px", textOverflow: "ellipsis" }} onChange={(e) => handleChangeConsumptionType(e.target.value)}>
                                    <option value="eb_consumption">EB Consumption</option>
                                    <option value="dg_consumption">DG Consumption</option>
                                    <option value="water_consumption">Water Consumption</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "250px" }}>
                        <Line data={data} options={options} />
                    </div>
                    <div className="legend-container" style={{ overflowX: 'auto', textWrap: "nowrap", width: "500px", margin: "auto" }}>
                        {InvoicesReport.map((dataset, index) => (
                            dataset?.label && (
                                <span key={index} style={{ marginRight: '10px', display: 'inline-block' }}>
                                    <span style={{ backgroundColor: dataset.backgroundColor, width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></span>
                                    {dataset.label}
                                </span>
                            )
                        ))}
                    </div>
                </>
            ) : (
                <DashboardLoader />
            )}
        </div>
    );
}

import { error } from 'pdf-lib';
import {
  PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
  PUT_CH_ADD_NEW_CONTRACT_FAILURE,
  PUT_TC_SUMBIT_METER_READING_SUCCESS,
  PUT_TC_SUMBIT_METER_READING_FAILURE,
  PUT_CH_ADD_NEW_LICENSE_PERMIT_SUCCESS,
  PUT_CH_ADD_NEW_LICENSE_PERMIT_FAILURE,
  GET_CH_CONTRACTS_SUCCESS,
  GET_CH_CONTRACTS_FAILURE,
  GET_CH_FMSCONTRACTS_SUCCESS,
  GET_CH_FMSCONTRACTS_FAILURE,
  PUT_ADD_MISC_INVOICE_SUCCESS,
  PUT_ADD_MISC_INVOICE_FAILURE,
  GET_GST_LOGS_SUCCESS,
  GET_GST_LOGS_FAILURE,
  GET_EMAIL_LOGS_SUCCESS,
  GET_EMAIL_LOGS_FAILURE,
  GET_CH_FMSCONTRACTS_SUCCESS_FOR_DROPDOWN,
  GET_CH_FMSCONTRACTS_FAILURE_FOR_DROPDOWN,
  GET_CH_CONTRACTS_SUCCESS_FOR_DROPDOWN,
  GET_CH_CONTRACTS_FAILURE_FOR_DROPDOWN,
  PUT_GST_SUBMIT_SUCCESS,
  PUT_GST_SUBMIT_FAILURE,
  GET_CH_CONTRACTS_SUCCESS_FOR_DROPDOWN_TECHNICIAN,
  GET_CH_CONTRACTS_FAILURE_FOR_DROPDOWN_TECHNICIAN,
  PUT_CH_LICENSES_AND_PERMITS_SUCCESS,
  PUT_CH_LICENSES_AND_PERMITS_FAILURE,
  GET_CH_DRAFT_CONTRACTS_SUCCESS,
  GET_CH_DRAFT_CONTRACTS_FAILURE,
  UPDATE_CH_DRAFT_CONTRACT_SUCCESS,
  UPDATE_CH_DRAFT_CONTRACT_FAILURE,
  SET_ERROR_FALSE,
  UPDATE_CH_FMSCONTRACT_SUCCESS,
  UPDATE_CH_FMSCONTRACT_FAILURE,
  PUT_CH_ADD_NEW_CONTRACT_DELETE_SUCCESS,
  PUT_CH_SUBMIT_CONTRACT_SUCCESS,
  PUT_CH_SUBMIT_CONTRACT_FAILURE,
  PUT_CH_ADD_NEW_FACILITY_SUCCESS,
  PUT_CH_ADD_NEW_FACILITY_FAILURE,
  PUT_CH_ADD_NEW_METER_SUCCESS,
  PUT_CH_ADD_NEW_METER_FAILURE,
  PUT_CH_ADD_NEW_FMSCONTACT_SUCCESS,
  REGENERATE_INVOICE_DATA_SUCCESS,
  REGENERATE_INVOICE_DATA_FAILURE,
  CREATE_NEW_FIELD,
  GET_ALL_FIELDS,
  SET_LOADER,
  SHOW_MODAL,
  DELETE_FIELD,
  CREATE_NEW_FIELD_ERROR,
  PUT_TC_SUMBIT_METER_IMAGE_SUCCESS,
  PUT_TC_SUMBIT_METER_IMAGE_FAILURE,
  GET_CH_ALL_METERS_SUCCCESS,
  GET_CH_ALL_METERS_FAILURE,
  FIN_MAN_GET_ALL_METER_READINGS_SUCCESS,
  FIN_MAN_GET_ALL_METER_READINGS_FAILURE,
  CREATE_NEW_PANEL,
  GET_ALL_PANELS,
  FILTER_PANELS,
  FILTER_FIELDS,
  DELETE_PANEL,
  CREATE_NEW_TEMPLATE,
  GET_ALL_TEMPLATES,
  CREATE_NEW_TEMPLATE_ERROR,
  FETCHING_ERROR,
  DELETE_FIELD_ERROR,
  TOTAL_NUMBER_OF_RECORDS,
  PUT_INVOICE_DATA_SUCCESS,
  PUT_INVOICE_DATA_FAILURE,
  CREATION_ERROR,
  CREATE_PANEL_ERROR,
  DELETE_PANEL_ERROR,
  CREATION_SUCCESS,
  GET_ALL_FORMS,
  GET_CH_ALL_DEPARTMENTS_SUCCESS,
  GET_CH_ALL_DEPARTMENTS_FAILURE,
  GET_CH_UTILITY_RATES_SUCCESS,
  GET_CH_UTILITY_RATES_FAILURE,
  UPDATE_COMPLETE_CONTRACT_SUCCESS,
  UPDATE_COMPLETE_CONTRACT_FAILURE,
  FETCH_FACILITIES_SUCCESS,
  FETCH_FACILITIES_FAILURE,
  FETCH_FACILITIES_SUCCESS_FOR_NAV,
  FETCH_FACILITIES_FAILURE_FOR_NAV,
  PUT_CH_UPDATE_FACILITY_SUCCESS,
  PUT_CH_UPDATE_FACILITY_FAILURE,
  COMMERCIAL_HEAD_UPLOAD_DOCUMENT_SUCCESS,
  COMMERCIAL_HEAD_UPLOAD_DOCUMENT_FAILURE,
  GET_CH_BILLING_ENTITIES_SUCCESS,
  GET_CH_BILLING_ENTITIES_FAILURE,
  COMMERCIAL_HEAD_GET_DOCUMENTS_SUCCESS,
  COMMERCIAL_HEAD_GET_DOCUMENTS_FAILURE,
  COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_SUCCESS,
  COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_FAILURE,
  COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_SUCCESS,
  COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_FAILURE,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  DOCUMENT_LOADER_TRIGGER_TRUE,
  DOCUMENT_LOADER_TRIGGER_FALSE,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_FAILURE,
  GET_ALL_INVOICES,
  GET_ALL_INVOICES_ERROR,
  SET_PAGINATION_COUNT_TO,
  SET_PAGINATION_COUNT_INCREASE,
  SET_PAGINATION_COUNT_DECREASE,
  NEXT_PAGE_INVOICES,
  FAC_MAN_GET_ALL_UNASSIGNED_METERS_SUCCESS,
  FAC_MAN_GET_ALL_UNASSIGNED_METERS_FAILURE,
  FAC_MAN_GET_ASSIGNED_METERS_SUCCESS,
  FAC_MAN_GET_ASSIGNED_METERS_FAILURE,
  GET_ALL_USERS,
  GET_ALL_USERS_ERROR,
  SEARCH_TERM,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAILURE,
  GET_HSN_SUCCESS,
  GET_HSN_FAILURE,
  UPDATE_HSN_SUCCESS,
  UPDATE_HSN_FAILURE,
  GET_TAX_RATES_SUCCESS,
  GET_TAX_RATES_FAILURE,
  UPDATE_TAX_RATES_SUCCESS,
  UPDATE_TAX_RATES_FAILURE,
  GET_ALL_PARTNER_COMPANIES_SUCCESS,
  GET_ALL_PARTNER_COMPANIES_FAILURE,
  GET_PARTNER_COMPANY_DETAILS_SUCCESS,
  GET_PARTNER_COMPANY_DETAILS_FAILURE,
  UPDATE_PARTNER_COMPANY_DETAILS_SUCCESS,
  UPDATE_PARTNER_COMPANY_DETAILS_FAILURE,
  CREATE_PARTNER_COMPANY_SUCCESS,
  CREATE_PARTNER_COMPANY_FAILURE,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILURE,
  UPLOAD_TERMS_AND_CONDITIONS_SUCCESS,
  UPLOAD_TERMS_AND_CONDITIONS_FAILURE,
  UPLOAD_PAYMENT_TERMS_SUCCESS,
  UPLOAD_PAYMENT_TERMS_FAILURE,
  PAGINATION_LOADER_TRIGGER_TRUE,
  PAGINATION_LOADER_TRIGGER_FALSE,
  CONTRACT_API_LOADER_TRIGGER_TRUE,
  CONTRACT_API_LOADER_TRIGGER_FALSE,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_LOGO_FAILURE,
  ADD_NEW_SERVICE_SUCCESS,
  ADD_NEW_SERVICE_FAILURE,
  GET_ALL_SERVICES_SUCCESS,
  GET_ALL_SERVICES_FAILURE,
  PUT_CH_ADD_NEW_FMSCONTRACT_SUCCESS,
  PUT_CH_ADD_NEW_FMSCONTRACT_FAILURE,
  GET_SERVICE_DETAILS_SUCCESS,
  GET_SERVICE_DETAILS_FAILURE,
  UPDATE_SERVICE_DETAILS_SUCCESS,
  UPDATE_SERVICE_DETAILS_FAILURE,
  UPLOAD_DSC_SUCCESS,
  UPLOAD_DSC_FAILURE,
  PUT_CH_UPDATE_METER_SUCCESS,
  PUT_CH_UPDATE_METER_FAILURE,
  GET_ALL_METER_READINGS_BY_METER_ID_FAILURE,
  GET_ALL_METER_READINGS_BY_METER_ID_SUCCESS,
  UPDATE_METER_READING_SUCCESS,
  UPDATE_METER_READING_FAILURE,
  UPDATE_METER_READING_API_LOADER_TRIGGER_FALSE,
  UPDATE_METER_READING_API_LOADER_TRIGGER_TRUE,
  GET_ALL_ASSETS_LIST_SUCCESS,
  GET_ALL_ASSETS_LIST_FAILURE,
  GET_ASSET_CATEGORY_SUCCESS,
  GET_ASSET_CATEGORY_FAILURE,
  GET_EVENT_CATEGORIES_SUCCESS,
  GET_EVENT_CATEGORIES_FAILURE,
  GET_ASSETS_BY_CATEGORY_SUCCESS,
  GET_ASSETS_BY_CATEGORY_FAILURE,
  CREATE_SCHEDULE_EVENT_SUCCESS,
  CREATE_SCHEDULE_EVENT_FAILURE,
  GET_FACMAN_FROM_USERS,
  GET_FACMAN_FROM_USERS_ERROR,
  GET_ASSET_STATUS_LIST_SUCCESS,
  GET_ASSET_STATUS_LIST_FAILURE,
  GET_ASSET_CATEGORY_LIST_SUCCESS,
  GET_ASSET_CATEGORY_LIST_FAILURE,
  GET_ASSET_TYPE_LIST_SUCCESS,
  GET_ASSET_TYPE_LIST_FAILURE,
  CREATING_NEW_ASSET_SUCCESS,
  CREATING_NEW_ASSET_FAILURE,
  UPDATING_ASSET_SUCCESS,
  UPDATING_ASSET_FAILURE,
  READ_AN_EVENT_SUCCESS,
  READ_AN_EVENT_FAILURE,

  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_COMPANIES_FAILURE,
  GET_ALL_SUPERVISOR_LIST,
  GET_ALL_SUPERVISOR_LIST_ERROR,
  CREATE_NEW_COMPANY_SUCCESS,
  CREATE_NEW_COMPANY_FAILURE,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  GET_ALL_COMPANIES_DROPDOWN_FAILURE,
  GET_ALL_COMPANIES_DROPDOWN_SUCCESS,
  CREATE_NEW_AMC_SUCCESS,
  CREATE_NEW_AMC_FAILURE,
  GET_AMC_CATEGORY_LIST_FAILURE,
  GET_AMC_CATEGORY_LIST_SUCCESS,
  GET_AMC_RENEWAL_LIST_SUCCESS,
  GET_AMC_RENEWAL_LIST_FAILURE,
  GET_ALL_AMC_FOR_ASSET_SUCCESS,
  GET_ALL_AMC_FOR_ASSET_FAILURE,
  GET_SPICIFIC_AMC_DETAILS_SUCCESS,
  GET_SPICIFIC_AMC_DETAILS_FAILURE,
  GET_ALL_DOCUMENTS_FOR_ASSET_SUCCESS,
  GET_ALL_DOCUMENTS_FOR_ASSET_FAILURE,
  UPLOAD_DOCUMENT_FOR_ASSET_SUCCESS,
  UPLOAD_DOCUMENT_FOR_ASSET_FAILURE,
  UPDATE_SCHEDULE_EVENT_SUCCESS,
  UPDATE_SCHEDULE_EVENT_FAILURE,
  uploadDocumentForAsset,
  GET_ALL_ASSETS_LIST_DROPDOWN_SUCCESS,
  GET_ALL_ASSETS_LIST_DROPDOWN_FAILURE,
  UPLOAD_DOCUMENT_FOR_AMC_SUCCESS,
  UPLOAD_DOCUMENT_FOR_AMC_FAILURE,
  GET_ALL_DOCUMENTS_FOR_AMC_SUCCESS,
  GET_ALL_DOCUMENTS_FOR_AMC_FAILURE,
  GET_MAINTENANCE_HISTORY_FOR_ASSET_SUCCESS,
  GET_MAINTENANCE_HISTORY_FOR_ASSET_FAILURE,
  GET_MAINTENANCE_HISTORY_FOR_FACILITY_SUCCESS,
  GET_MAINTENANCE_HISTORY_FOR_FACILITY_FAILURE,
  GET_INVOICE_PAYMENTS_SUCCESS,
  GET_INVOICE_PAYMENTS_FAILURE,
  GET_SPECIFIC_INVOICE_PAYMENTS_SUCCESS,
  GET_SPECIFIC_INVOICE_PAYMENTS_FAILURE,
  GET_COMM_HEAD_CUSTOMERS_SPACES_REPORT_SUCCESS,
  GET_COMM_HEAD_CUSTOMERS_SPACES_REPORT_FAILURE,
  GET_COMM_HEAD_CONTRACTS_AGING_REPORT_SUCCESS,
  GET_COMM_HEAD_CONTRACTS_AGING_REPORT_FAILURE,
  GET_FINANCE_MANAGER_INVOICES_REPORT_SUCCESS,
  GET_FINANCE_MANAGER_INVOICES_REPORT_FAILURE,
  GET_FINANCE_MANAGER_INVOICE_REPORT_BY_CUSTOMER_SUCCESS,
  GET_FINANCE_MANAGER_INVOICE_REPORT_BY_CUSTOMER_FAILURE,
  GET_INVOICES_COUNT_BY_STATUS_SUCCESS,
  GET_INVOICES_COUNT_BY_STATUS_FAILURE,
  GET_CONTRACTS_COUNT_BY_STATUS_SUCCESS,
  GET_CONTRACTS_COUNT_BY_STATUS_FAILURE,
  PAYMENTS_DOCUMENT_UPLOAD_SUCCESS,
  PAYMENTS_DOCUMENT_UPLOAD_FAILURE,
  GET_ALL_PAYMENTS_DOCUMENTS_SUCCESS,
  GET_ALL_PAYMENTS_DOCUMENTS_FAILURE,
  GET_FACILITY_MANAGER_METER_REPORT_SUCCESS,
  GET_FACILITY_MANAGER_METER_REPORT_FAILURE,
  GET_FACILITY_MANAGER_METER_REPORT_DAILY_SUCCESS,
  GET_FACILITY_MANAGER_METER_REPORT_DAILY_FAILURE,
  GET_FACILITY_MANAGER_CONSUMPTION_BY_CUSTOMER_SUCCESS,
  GET_FACILITY_MANAGER_CONSUMPTION_BY_CUSTOMER_FAILURE,
  GET_FACILITY_MANAGER_FACILITIES_AREA_FAILURE,
  GET_FACILITY_MANAGER_FACILITIES_AREA_SUCCESS,
  ADD_NEW_ASSET_CATEGORY_SUCCESS,
  ADD_NEW_ASSET_CATEGORY_FAILURE,

} from '../Actions/Actions';
import { all } from 'axios';

const initialState = {
  newFieldCreated: null,
  fetchingError: null,
  fieldsList: null,
  showModal: false,
  deleteField: null,
  deleteFieldError: null,
  createFieldError: null,
  createPanel: null,
  createPanelError: null,
  panelsList: null,
  newInvoiceNumber: null,
  gstsubmmisiondata: null,
  totalRecords: null,
  deletePanelSuccess: null,
  deletePanelError: null,
  createTemplateSuccess: null,
  createTemplateError: null,
  templatesList: null,
  formsList: null,
  creationSuccess: null,
  creationError: null,
  createNewContract: null,
  newFmsContractId: null,
  updateDraftContract: null,
  updateFMSContract: null,
  submittedContract: null,
  submittedCompleteContract: null,
  updatedFacility: null,
  submittedMeter: null,
  submittedFacility: null,
  addedMeter: null,
  submittedImage: null,
  gstLogs: null,
  contracts: [],
  contractsFMS: [],
  draftContracts: [],
  draftContractsFMS: [],
  contractsForDropdown: [],
  contractsForDropdownTechnician: [],
  fmscontractsForDropdown: [],
  loading: false,
  newLicenseData: null,
  meterList: [],
  gstDetails: null,
  editInvoiceSubmisssion: null,
  generateInvoiceStatus: null,
  error: null,
  specificDraftDetails: null,
  allMeters: null,
  allDepartments: null,
  utilityRates: null,
  updateCompleteContractStatus: null,
  facilitiesList: null,
  facilitiesListForNav: null,
  commercialHeadUploadDocument: null,
  billingEntities: [],
  fmsContacts: null,
  commercialHeadUploadDocumentList: null,
  commercialHeadGetDocumentsForContract: null,
  commercialHeadGetFacilityDocumentsList: null,
  commercialHeadUploadFacilityDocumentList: null,
  paymentsUploadDocument: null,
  allPaymentDocuments: null,
  addUserSuccess: null,
  addUserError: null,
  addMeterError: null,
  documentLoaderTrigger: false,
  authUserSuccess: null,
  authUserError: null,
  invoicesList: null,
  invoicesListError: null,
  paginationCount: 1,
  nextPage: false,
  documentLoaderTrigger: false,
  allUnassignedMeters: null,
  assignedMeters: null,
  usersList: null,
  usersListError: null,
  searchTerm: "",
  updateUserStatusSuccess: null,
  updateUserStatusFailure: null,
  hsnCode: null,
  hsnCodeError: null,
  updateHsnSuccess: null,
  updateHsnFailure: null,
  taxRates: null,
  taxRatesError: null,
  updateTaxRatesSuccess: null,
  updateTaxRatesError: null,
  partnerCompaniesList: null,
  partnerCompaniesListError: null,
  partnerCompanyDetails: null,
  partnerCompanyDetailsError: null,
  updatePartnerCompanyDetailsSuccess: null,
  updatePartnerCompanyDetailsError: null,
  createPartnerCompanySuccess: null,
  createPartnerCompanyError: null,
  setPasswordSuccess: null,
  setPasswordFailure: null,
  sendOTPSuccess: null,
  sendOTPFailure: null,
  uploadTermsAndConditionsSuccess: null,
  uploadTermsAndConditionsError: null,
  uploadPaymentTermsSuccess: null,
  uploadPaymentTermsError: null,
  paginationLoader: false,
  contractApiLoader: false,
  emailLogs: null,
  uploadLogoSuccess: null,
  uploadLogoError: null,
  addNewService: null,
  addNewServiceError: null,
  servicesList: null,
  licensesAndPermitsData: null,
  getAllServicesError: null,
  serviceDetails: null,
  getServiceDetailsError: null,
  updateServiceSuccess: null,
  updateServiceError: null,
  uploadDscSuccess: null,
  uploadDscError: null,
  updateMeterSuccess: null,
  meterReadingsforMeterReports: null,
  updateMeterReading: null,
  allAssetsList: null,
  assetCategory: null,
  allAssetStatusList: null,
  allAssetCategoryList: null,
  allAssetTypeList: null,
  creatingNewAssetResponse: null,
  updatingExistingAssetResponse: null,
  newLicenseId: null,
  eventCategory: null,
  assetByCategory: null,
  createNewEvent: null,
  facmanList: null,
  getEventList: null,
  getAllCompanies: null,
  supervisorList: null,
  supervisorListError: null,
  createNewCompanyResponse: null,
  updateCompanyResponse: null,
  getAllCompaniesForDropdown: null,
  newAmc: null,
  amcRenewalList: null,
  amcCategoryList: null,
  allAmcForAsset: null,
  specificAmcDetails: null,
  documentsForAnAsset: null,
  uploadDocumentForAssetResponse: null,
  updateScheduledEventResponse: null,
  getAllAssetsListDropdown: null,
  uploadedDocumentsForAmcResponse: null,
  documentsForAnAmc: null,
  assetMaintenanceList: null,
  facilityMaintenanceList: null,
  getAreaByCustomer: null,
  getAreaByCustomerError: null,
  agingReportData: null,
  agingReportDataError: null,
  invoicesReport: null,
  invoicesReportError: null,
  invoiceReportByCustomer: null,
  invoiceReportByCustomerError: null,
  allInvoicePayments: null,
  specificInvoicePayments: null,
  invoiceCounts: null,
  invoiceCountsError: null,
  contractsCount: null,
  contractsCountError: null,
  facManMeterReport: null,
  facManMeterReportError: null,
  dailyMeterReport: null,
  dailyMeterReportError: null,
  cusmptionByCustomer: null,
  cusmptionByCustomerError: null,
  facManFacilitiesArea: null,
  facManFacilitiesAreaError: null,
  addNewAssetCategory: null,
};

const ReduxReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_ASSET_CATEGORY_SUCCESS:
      return {
        ...state,
        addNewAssetCategory: action.payload,
        error: null,
      };
    case ADD_NEW_ASSET_CATEGORY_FAILURE:
      return {
        ...state,
        addNewAssetCategory: null,
        error: action.payload,
      };
      
    case GET_SPECIFIC_INVOICE_PAYMENTS_SUCCESS:
      return {
        ...state,
        specificInvoicePayments: action.payload,
        error: null,
      };
    case GET_SPECIFIC_INVOICE_PAYMENTS_FAILURE:
      return {
        ...state,
        specificInvoicePayments: null,
        error: action.payload,
      };
    case GET_INVOICE_PAYMENTS_SUCCESS:
      return {
        ...state,
        allInvoicePayments: action.payload,
        error: null,
      };
    case GET_INVOICE_PAYMENTS_FAILURE:
      return {
        ...state,
        allInvoicePayments: null,
        error: action.payload,
      };
    case GET_MAINTENANCE_HISTORY_FOR_FACILITY_SUCCESS:
      return {
        ...state,
        facilityMaintenanceList: action.payload,
        error: null,
      };
    case GET_MAINTENANCE_HISTORY_FOR_FACILITY_FAILURE:
      return {
        ...state,
        facilityMaintenanceList: null,
        error: action.payload,
      };
    case GET_MAINTENANCE_HISTORY_FOR_ASSET_SUCCESS:
      return {
        ...state,
        assetMaintenanceList: action.payload,
        error: null,
      };
    case GET_MAINTENANCE_HISTORY_FOR_ASSET_FAILURE:
      return {
        ...state,
        assetMaintenanceList: null,
        error: action.payload,
      };
    case UPDATE_SCHEDULE_EVENT_SUCCESS:
      return {
        ...state,
        updateScheduledEventResponse: action.payload,
        error: null,
      };
    case UPDATE_SCHEDULE_EVENT_FAILURE:
      return {
        ...state,
        updateScheduledEventResponse: null,
        error: action.payload,
      };

    case GET_ALL_DOCUMENTS_FOR_AMC_SUCCESS:
      return {
        ...state,
        documentsForAnAmc: action.payload,
        error: null,
      };
    case GET_ALL_DOCUMENTS_FOR_AMC_FAILURE:
      return {
        ...state,
        documentsForAnAmc: null,
        error: action.payload,
      };
    case UPLOAD_DOCUMENT_FOR_AMC_SUCCESS:
      return {
        ...state,
        uploadedDocumentsForAmcResponse: action.payload,
        error: null,
      };
    case UPLOAD_DOCUMENT_FOR_AMC_FAILURE:
      return {
        ...state,
        uploadedDocumentsForAmcResponse: null,
        error: action.payload,
      };
    case GET_ALL_ASSETS_LIST_DROPDOWN_SUCCESS:
      return {
        ...state,
        getAllAssetsListDropdown: action.payload,
        error: null,
      };
    case GET_ALL_ASSETS_LIST_DROPDOWN_FAILURE:
      return {
        ...state,
        getAllAssetsListDropdown: null,
        error: action.payload,
      };
    case UPLOAD_DOCUMENT_FOR_ASSET_SUCCESS:
      return {
        ...state,
        uploadDocumentForAssetResponse: action.payload,
        error: null,
      };
    case UPLOAD_DOCUMENT_FOR_ASSET_FAILURE:
      return {
        ...state,
        uploadDocumentForAssetResponse: null,
        error: action.payload,
      };
    case GET_ALL_DOCUMENTS_FOR_ASSET_SUCCESS:
      return {
        ...state,
        documentsForAnAsset: action.payload,
        error: null,
      };
    case GET_ALL_DOCUMENTS_FOR_ASSET_FAILURE:
      return {
        ...state,
        documentsForAnAsset: null,
        error: action.payload,
      };
    case READ_AN_EVENT_SUCCESS:
      return {
        ...state,
        getEventList: action.payload,
        error: null,
      };
    case READ_AN_EVENT_FAILURE:
      return {
        ...state,
        getEventList: null,
        error: action.payload,
      };


    case GET_SPICIFIC_AMC_DETAILS_SUCCESS:
      return {
        ...state,
        specificAmcDetails: action.payload,
        error: null,
      };
    case GET_SPICIFIC_AMC_DETAILS_FAILURE:
      return {
        ...state,
        specificAmcDetails: null,
        error: action.payload,
      };

    case GET_ALL_AMC_FOR_ASSET_SUCCESS:
      return {
        ...state,
        allAmcForAsset: action.payload,
        error: null,
      };
    case GET_ALL_AMC_FOR_ASSET_FAILURE:
      return {
        ...state,
        allAmcForAsset: null,
        error: action.payload,
      };

    case GET_AMC_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        amcCategoryList: action.payload,
        error: null,
      };
    case GET_AMC_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        amcCategoryList: null,
        error: action.payload,
      };
    case GET_AMC_RENEWAL_LIST_SUCCESS:
      return {
        ...state,
        amcRenewalList: action.payload,
        error: null,
      };
    case GET_AMC_RENEWAL_LIST_FAILURE:
      return {
        ...state,
        amcRenewalList: null,
        error: action.payload,
      };
    case CREATE_NEW_AMC_SUCCESS:
      return {
        ...state,
        newAmc: action.payload,
        error: null,
      };
    case CREATE_NEW_AMC_FAILURE:
      return {
        ...state,
        newAmc: null,
        error: action.payload,
      };
    case GET_ALL_COMPANIES_DROPDOWN_SUCCESS:
      return {
        ...state,
        getAllCompaniesForDropdown: action.payload,
        error: null,
      };
    case GET_ALL_COMPANIES_DROPDOWN_FAILURE:
      return {
        ...state,
        getAllCompaniesForDropdown: null,
        error: action.payload,
      };

    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        updateCompanyResponse: action.payload,
        error: null,
      };
    case UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        updateCompanyResponse: null,
        error: action.payload,
      };


    case CREATE_NEW_COMPANY_SUCCESS:
      return {
        ...state,
        createNewCompanyResponse: action.payload,
        error: null,
      };
    case CREATE_NEW_COMPANY_FAILURE:
      return {
        ...state,
        createNewCompanyResponse: null,
        error: action.payload,
      };
    case GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        getAllCompanies: action.payload,
        error: null,
      };
    case GET_ALL_COMPANIES_FAILURE:
      return {
        ...state,
        getAllCompanies: null,
        error: action.payload,
      };
    case GET_FACMAN_FROM_USERS:
      return {
        ...state,
        facmanList: action.payload,
        error: null,
      };
    case GET_FACMAN_FROM_USERS_ERROR:
      return {
        ...state,
        facmanList: null,
        error: action.payload,
      };

    case CREATE_SCHEDULE_EVENT_SUCCESS:
      return {
        ...state,
        createNewEvent: action.payload,
        error: null,
      };
    case CREATE_SCHEDULE_EVENT_FAILURE:
      return {
        ...state,
        createNewEvent: null,
        error: action.payload,
      }

    case GET_ASSETS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        assetByCategory: action.payload,
        error: null,
      };
    case GET_ASSETS_BY_CATEGORY_FAILURE:
      return {
        ...state,
        assetByCategory: null,
        error: action.payload,
      }

    case GET_EVENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        eventCategory: action.payload,
        error: null,
      };
    case GET_EVENT_CATEGORIES_FAILURE:
      return {
        ...state,
        eventCategory: null,
        error: action.payload,
      }

    case GET_ASSET_CATEGORY_SUCCESS:
      return {
        ...state,
        assetCategory: action.payload,
        error: null,
      };
    case GET_ASSET_CATEGORY_FAILURE:
      return {
        ...state,
        assetCategory: null,
        error: action.payload,
      }


    case UPDATING_ASSET_SUCCESS:
      return {
        ...state,
        updatingExistingAssetResponse: action.payload,
        error: null,
      };
    case UPDATING_ASSET_FAILURE:
      return {
        ...state,
        updatingExistingAssetResponse: null,
        error: action.payload,
      };

    case CREATING_NEW_ASSET_SUCCESS:
      return {
        ...state,
        creatingNewAssetResponse: action.payload,
        error: null,
      };
    case CREATING_NEW_ASSET_FAILURE:
      return {
        ...state,
        creatingNewAssetResponse: null,
        error: action.payload,
      };

    case GET_ASSET_TYPE_LIST_SUCCESS:
      return {
        ...state,
        allAssetTypeList: action.payload,
        error: null,
      };
    case GET_ASSET_TYPE_LIST_FAILURE:
      return {
        ...state,
        allAssetTypeList: null,
        error: action.payload,
      }

    case GET_ASSET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        allAssetCategoryList: action.payload,
        error: null,
      };
    case GET_ASSET_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        allAssetCategoryList: null,
        error: action.payload,
      }

    case GET_ASSET_STATUS_LIST_SUCCESS:
      return {
        ...state,
        allAssetStatusList: action.payload,
        error: null,
      };
    case GET_ASSET_STATUS_LIST_FAILURE:
      return {
        ...state,
        allAssetStatusList: null,
        error: action.payload,
      }


    case GET_ALL_ASSETS_LIST_SUCCESS:
      return {
        ...state,
        allAssetsList: action.payload,
        error: null,
      };
    case GET_ALL_ASSETS_LIST_FAILURE:
      return {
        ...state,
        allAssetsList: null,
        error: action.payload,
      }

    case UPDATE_METER_READING_API_LOADER_TRIGGER_TRUE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_METER_READING_API_LOADER_TRIGGER_FALSE:
      return {
        ...state,
        loading: false,
      };
    case PUT_GST_SUBMIT_SUCCESS:
      return {
        ...state,
        gstsubmmisiondata: action.payload,
        error: null,
      };
    case PUT_GST_SUBMIT_FAILURE:
      return {
        ...state,
        gstsubmmisiondata: null,
        error: action.payload,
      };
    case UPDATE_METER_READING_SUCCESS:
      return {
        ...state,
        updateMeterReading: action.payload,
        error: null,
      };
    case UPDATE_METER_READING_FAILURE:
      return {
        ...state,
        updateMeterReading: null,
        error: action.payload,
      };

    case PUT_CH_ADD_NEW_LICENSE_PERMIT_SUCCESS:
      return {
        ...state,
        newLicenseId: action.payload,
        error: null,
      };
    case PUT_CH_ADD_NEW_LICENSE_PERMIT_FAILURE:
      return {
        ...state,
        newLicenseId: null,
        error: action.payload,
      };

    case GET_ALL_METER_READINGS_BY_METER_ID_SUCCESS:
      return {
        ...state,
        meterReadingsforMeterReports: action.payload,
        loading: false,
        error: null,
      };
    case GET_ALL_METER_READINGS_BY_METER_ID_FAILURE:
      return {
        ...state,
        meterReadingsforMeterReports: null,
        loading: false,
        error: action.payload,
      };
    case PUT_CH_LICENSES_AND_PERMITS_SUCCESS:
      return {
        ...state,
        licensesAndPermitsData: action.payload,
        error: null,
      };
    case PUT_CH_LICENSES_AND_PERMITS_FAILURE:
      return {
        ...state,
        licensesAndPermitsData: null,
        error: action.payload,
      }
    case PAGINATION_LOADER_TRIGGER_TRUE:
      return {
        ...state,
        paginationLoader: action.payload
      };
    case PAGINATION_LOADER_TRIGGER_FALSE:
      return {
        ...state,
        paginationLoader: action.payload
      }
    case CONTRACT_API_LOADER_TRIGGER_TRUE:
      return {
        ...state,
        contractApiLoader: action.payload
      };
    case CONTRACT_API_LOADER_TRIGGER_FALSE:
      return {
        ...state,
        contractApiLoader: action.payload
      }
    case SET_ERROR_FALSE:
      return {
        ...state,
        error: action.payload,
      };
    case DOCUMENT_LOADER_TRIGGER_TRUE:
      return {
        ...state,
        documentLoaderTrigger: action.payload
      };
    case DOCUMENT_LOADER_TRIGGER_FALSE:
      return {
        ...state,
        documentLoaderTrigger: action.payload
      }
    case COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        commercialHeadUploadFacilityDocumentList: action.payload,
        error: null,
      };
    case COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_FAILURE:
      return {
        ...state,
        commercialHeadUploadFacilityDocumentList: null,
        error: action.payload,
      }
    case PAYMENTS_DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        paymentsUploadDocument: action.payload,
        error: null,
      };
    case PAYMENTS_DOCUMENT_UPLOAD_FAILURE:
      return {
        ...state,
        paymentsUploadDocument: null,
        error: action.payload,
      }
    case GET_ALL_PAYMENTS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        allPaymentDocuments: action.payload,
        error: null,
      };
    case GET_ALL_PAYMENTS_DOCUMENTS_FAILURE:
      return {
        ...state,
        allPaymentDocuments: null,
        error: action.payload,
      }
    case PUT_ADD_MISC_INVOICE_SUCCESS:
      return {
        ...state,
        newInvoiceNumber: action.payload,
        error: null,
      }
    case PUT_ADD_MISC_INVOICE_FAILURE:
      return {
        ...state,
        newInvoiceNumber: null,
        error: action.payload,
      }
    case COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        commercialHeadGetFacilityDocumentsList: action.payload,
        error: null,
      };
    case COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_FAILURE:
      return {
        ...state,
        commercialHeadGetFacilityDocumentsList: null,
        error: action.payload,
      }
    case FIN_MAN_GET_ALL_METER_READINGS_SUCCESS:
      return {
        ...state,
        meterList: action.payload,
        error: null,
      };
    case FIN_MAN_GET_ALL_METER_READINGS_FAILURE:
      return {
        ...state,
        meterList: null,
        error: action.payload,
      }
    case COMMERCIAL_HEAD_GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        commercialHeadGetDocumentsForContract: action.payload,
        error: null,
      };
    case COMMERCIAL_HEAD_GET_DOCUMENTS_FAILURE:
      return {
        ...state,
        commercialHeadGetDocumentsForContract: null,
        error: action.payload,
      }
    case COMMERCIAL_HEAD_UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        commercialHeadUploadDocumentList: action.payload,
        error: null,
      };
    case TOTAL_NUMBER_OF_RECORDS:
      return {
        ...state,
        totalRecords: action.payload,
      };

    case COMMERCIAL_HEAD_UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        commercialHeadUploadDocumentList: null,
        error: action.payload,
      }
    case UPDATE_COMPLETE_CONTRACT_SUCCESS:
      return {
        ...state,
        submittedContract: action.payload,
        error: null,
      };


    case UPDATE_COMPLETE_CONTRACT_FAILURE:
      return {
        ...state,
        submittedContract: null,
        error: action.payload,
      }
    case GET_CH_UTILITY_RATES_SUCCESS:
      return {
        ...state,
        utilityRates: action.payload,
        error: null,
      };


    case GET_CH_UTILITY_RATES_FAILURE:
      return {
        ...state,
        utilityRates: null,
        error: action.payload,
      }

    case GET_CH_ALL_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        allDepartments: action.payload,
        error: null,
      };


    case GET_CH_ALL_DEPARTMENTS_FAILURE:
      return {
        ...state,
        allDepartments: null,
        error: action.payload,
      }

    case PUT_INVOICE_DATA_SUCCESS:
      return {
        ...state,
        editInvoiceSubmisssion: action.payload,
        error: null,
      };


    case PUT_INVOICE_DATA_FAILURE:
      return {
        ...state,
        editInvoiceSubmisssion: null,
        error: action.payload,
      }

    case REGENERATE_INVOICE_DATA_SUCCESS:
      return {
        ...state,
        generateInvoiceStatus: action.payload,
        error: null,
      };


    case REGENERATE_INVOICE_DATA_FAILURE:
      return {
        ...state,
        generateInvoiceStatus: null,
        error: action.payload,
      }

    case GET_CH_ALL_METERS_SUCCCESS:
      return {
        ...state,
        allMeters: action.payload,
        error: null,

      };


    case GET_CH_ALL_METERS_FAILURE:
      return {
        ...state,
        allMeters: null,
        error: action.payload,
      }

    case PUT_CH_ADD_NEW_CONTRACT_DELETE_SUCCESS:
      return {
        ...state,
        createNewContract: action.payload,
        error: null,
      };

    case PUT_CH_ADD_NEW_CONTRACT_SUCCESS:
      return {
        ...state,
        createNewContract: action.payload,
        error: null,
      };

    case PUT_CH_ADD_NEW_FMSCONTRACT_SUCCESS:
      return {
        ...state,
        newFmsContractId: action.payload,
        error: null,
      };
    case PUT_CH_ADD_NEW_FMSCONTACT_SUCCESS:
      return {
        ...state,
        fmsContacts: action.payload,
        error: null,
      };

    case PUT_CH_ADD_NEW_FMSCONTRACT_FAILURE:
      return {
        ...state,
        newFmsContractId: null,
        error: action.payload,
      }

    case PUT_CH_UPDATE_FACILITY_SUCCESS:
      return {
        ...state,
        updatedFacility: action.payload,
        error: null,
      };


    case PUT_CH_UPDATE_FACILITY_FAILURE:
      return {
        ...state,
        updatedFacility: null,
        error: action.payload,
      }


    case PUT_CH_ADD_NEW_CONTRACT_FAILURE:
      return {
        ...state,
        createNewContract: null,
        error: action.payload,
      }

    case PUT_CH_ADD_NEW_FACILITY_SUCCESS:
      return {
        ...state,
        submittedFacility: action.payload,
        error: null,
      };


    case PUT_CH_ADD_NEW_FACILITY_FAILURE:
      return {
        ...state,
        submittedFacility: null,
        error: action.payload,
      }

    case PUT_CH_ADD_NEW_METER_SUCCESS:
      return {
        ...state,
        addedMeter: action.payload,
        addMeterError: null,
      };


    case PUT_CH_ADD_NEW_METER_FAILURE:
      return {
        ...state,
        addedMeter: null,
        addMeterError: action.payload,
      }

    case PUT_CH_UPDATE_METER_SUCCESS:
      return {
        ...state,
        updateMeterSuccess: action.payload,
        error: null,
      };


    case PUT_CH_UPDATE_METER_FAILURE:
      return {
        ...state,
        updateMeterSuccess: null,
        error: action.payload,
      }


    case PUT_CH_SUBMIT_CONTRACT_SUCCESS:
      return {
        ...state,
        submittedCompleteContract: action.payload,
        error: null,
      };


    case PUT_CH_SUBMIT_CONTRACT_FAILURE:
      return {
        ...state,
        submittedCompleteContract: null,
        error: action.payload,
      }

    case PUT_TC_SUMBIT_METER_READING_SUCCESS:
      return {
        ...state,
        submittedMeter: action.payload,
        error: null,
      };


    case PUT_TC_SUMBIT_METER_READING_FAILURE:
      return {
        ...state,
        submittedMeter: null,
        error: action.payload,
      }

    case GET_GST_LOGS_SUCCESS:
      return {
        ...state,
        gstLogs: action.payload,
        error: null,
      };
    case GET_GST_LOGS_FAILURE:
      return {
        ...state,
        gstLogs: null,
        error: action.payload,
      }

    case GET_EMAIL_LOGS_SUCCESS:
      return {
        ...state,
        emailLogs: action.payload,
        error: null,
      };
    case GET_EMAIL_LOGS_FAILURE:
      return {
        ...state,
        emailLogs: null,
        error: action.payload,
      }

    case PUT_TC_SUMBIT_METER_IMAGE_SUCCESS:
      return {
        ...state,
        submittedImage: action.payload,
        error: null,
      };


    case PUT_TC_SUMBIT_METER_IMAGE_FAILURE:
      return {
        ...state,
        submittedImage: null,
        error: action.payload,
      }

    case UPDATE_CH_DRAFT_CONTRACT_SUCCESS:
      return {
        ...state,
        updateDraftContract: action.payload,
        error: null,
      };

    case UPDATE_CH_DRAFT_CONTRACT_FAILURE:
      return {
        ...state,
        updateDraftContract: null,
        error: action.payload,
      }

    case UPDATE_CH_FMSCONTRACT_SUCCESS:
      return {
        ...state,
        updateFMSContract: action.payload,
        error: null,
      };

    case UPDATE_CH_FMSCONTRACT_FAILURE:
      return {
        ...state,
        updateFMSContract: null,
        error: action.payload,
      }
    case GET_CH_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: action.payload,
        loading: false,
        error: null,
      };
    case GET_CH_CONTRACTS_FAILURE:
      return {
        ...state,
        contracts: [],
        loading: false,
        error: action.payload,
      };

    case GET_CH_FMSCONTRACTS_SUCCESS:
      return {
        ...state,
        contractsFMS: action.payload,
        loading: false,
        error: null,
      };
    case GET_CH_FMSCONTRACTS_FAILURE:
      return {
        ...state,
        contractsFMS: [],
        loading: false,
        error: action.payload,
      };
    case GET_CH_CONTRACTS_SUCCESS_FOR_DROPDOWN:
      return {
        ...state,
        contractsForDropdown: action.payload,
        loading: false,
        error: null,
      };
    case GET_CH_CONTRACTS_FAILURE_FOR_DROPDOWN:
      return {
        ...state,
        contractsForDropdown: [],
        loading: false,
        error: action.payload,
      };
    case GET_CH_CONTRACTS_SUCCESS_FOR_DROPDOWN_TECHNICIAN:
      return {
        ...state,
        contractsForDropdownTechnician: action.payload,
        loading: false,
        error: null,
      };
    case GET_CH_CONTRACTS_FAILURE_FOR_DROPDOWN_TECHNICIAN:
      return {
        ...state,
        contractsForDropdownTechnician: [],
        loading: false,
        error: action.payload,
      };
    case GET_CH_FMSCONTRACTS_SUCCESS_FOR_DROPDOWN:
      return {
        ...state,
        fmscontractsForDropdown: action.payload,
        loading: false,
        error: null,
      };
    case GET_CH_FMSCONTRACTS_FAILURE_FOR_DROPDOWN:
      return {
        ...state,
        fmscontractsForDropdown: [],
        loading: false,
        error: action.payload,
      };
    case GET_CH_DRAFT_CONTRACTS_SUCCESS:
      return {
        ...state,
        draftContracts: action.payload,
        loading: false,
        error: null,
      };
    case GET_CH_DRAFT_CONTRACTS_FAILURE:
      return {
        ...state,
        draftContracts: [],
        loading: false,
        error: action.payload,
      };
    case CREATE_NEW_FIELD:
      return {
        ...state,
        newFieldCreated: action.payload,
      };
    case GET_ALL_FIELDS:
      return {
        ...state,
        fieldsList: action.payload.map((item) => {
          return { ...item, type: "field" }
        }),
      };
    case FETCHING_ERROR:
      return {
        ...state,
        fetchingError: action.payload
      };
    case SET_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };
    case DELETE_FIELD:
      return {
        ...state,
        deleteField: action.payload,
      };
    case DELETE_FIELD_ERROR:
      return {
        ...state,
        deleteFieldError: action.payload,
      };
    case CREATE_NEW_FIELD_ERROR:
      return {
        ...state,
        createFieldError: action.payload,
      };
    case CREATE_NEW_PANEL:
      return {
        ...state,
        createPanel: action.payload,
      };
    case GET_ALL_PANELS:
      return {
        ...state,
        panelsList: action.payload.map((item) => {
          return { ...item, type: "panel" }
        }),
      };
    case DELETE_PANEL:
      return {
        ...state,
        deletePanelSuccess: action.payload
      };
    case DELETE_PANEL_ERROR:
      return {
        ...state,
        deletePanelError: action.payload
      };
    case FILTER_FIELDS:
      return {
        ...state,
        fieldsList: state.fieldsList.filter((field) => field.field_id !== action.payload),
      };
    case FILTER_PANELS:
      return {
        ...state,
        panelsList: state.panelsList.filter((panel) => panel.panel_id !== action.payload),
      };
    case CREATE_NEW_TEMPLATE:
      return {
        ...state,
        createTemplateSuccess: action.payload,
      };
    case CREATE_NEW_TEMPLATE_ERROR:
      return {
        ...state,
        createTemplateError: action.payload,
      };
    case GET_ALL_TEMPLATES:
      return {
        ...state,
        templatesList: action.payload.map((item) => {
          return { ...item, type: "template" }
        }),
      };
    case CREATE_PANEL_ERROR:
      return {
        ...state,
        createPanelError: action.payload
      };

    case CREATION_SUCCESS:
      return {
        ...state,
        creationSuccess: action.payload
      };

    case CREATION_ERROR:
      return {
        ...state,
        creationError: action.payload
      };

    case GET_ALL_FORMS:
      return {
        ...state,
        formsList: action.payload
      };

    case FETCH_FACILITIES_SUCCESS:
      return {
        ...state,
        facilitiesList: action.payload,
        error: null
      };
    case FETCH_FACILITIES_FAILURE:
      return {
        ...state,
        facilitiesList: null,
        error: action.payload
      };
    case FETCH_FACILITIES_SUCCESS_FOR_NAV:
      return {
        ...state,
        facilitiesListForNav: action.payload,
        error: null
      };
    case FETCH_FACILITIES_FAILURE_FOR_NAV:
      return {
        ...state,
        facilitiesListForNav: null,
        error: action.payload
      };

    case ADD_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: action.payload,
      };

    case ADD_USER_FAILURE:
      return {
        ...state,
        addUserError: action.payload,
      };

    case GET_ALL_INVOICES:
      return {
        ...state,
        invoicesList: action.payload,
      };
    case GET_ALL_INVOICES_ERROR:
      return {
        ...state,
        invoicesListError: action.payload,
        invoicesList: [],
      };
    case SET_PAGINATION_COUNT_TO:
      return {
        ...state,
        paginationCount: action.payload,
      };

    case SET_PAGINATION_COUNT_INCREASE:
      return {
        ...state,
        paginationCount: state.paginationCount + action.payload,
      };

    case SET_PAGINATION_COUNT_DECREASE:
      return {
        ...state,
        paginationCount: state.paginationCount - action.payload,
      };

    case NEXT_PAGE_INVOICES:
      return {
        ...state,
        nextPage: action.payload,
      };


    case GET_CH_BILLING_ENTITIES_SUCCESS:
      return {
        ...state,
        billingEntities: action.payload,
        error: null
      };
    case GET_CH_BILLING_ENTITIES_FAILURE:
      return {
        ...state,
        billingEntities: null,
        error: action.payload
      };

    case GET_ALL_USERS:
      return {
        ...state,
        usersList: action.payload,
      };

    case GET_ALL_USERS_ERROR:
      return {
        ...state,
        usersListError: action.payload,
      };

    case GET_ALL_SUPERVISOR_LIST:
      return {
        ...state,
        supervisorList: action.payload,
      };

    case GET_ALL_SUPERVISOR_LIST_ERROR:
      return {
        ...state,
        supervisorListError: action.payload,
      };


    case SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload
      }

    case AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        authUserSuccess: action.payload,
      };

    case AUTHENTICATE_USER_FAILURE:
      return {
        ...state,
        authUserError: action.payload,
      };

    case FAC_MAN_GET_ALL_UNASSIGNED_METERS_SUCCESS:
      return {
        ...state,
        allUnassignedMeters: action.payload,
        error: null,
      }
    case FAC_MAN_GET_ALL_UNASSIGNED_METERS_FAILURE:
      return {
        ...state,
        allUnassignedMeters: null,
        error: action.payload,
      }

    case FAC_MAN_GET_ASSIGNED_METERS_SUCCESS:
      return {
        ...state,
        assignedMeters: action.payload,
        error: null,
      }
    case FAC_MAN_GET_ASSIGNED_METERS_FAILURE:
      return {
        ...state,
        assignedMeters: null,
        error: action.payload,
      }
    case UPDATE_USER_STATUS_SUCCESS:
      return {
        ...state,
        updateUserStatusSuccess: action.payload,
      };

    case UPDATE_USER_STATUS_FAILURE:
      return {
        ...state,
        updateUserStatusFailure: action.payload,
      };

    case GET_HSN_SUCCESS:
      return {
        ...state,
        hsnCode: action.payload,
      };

    case GET_HSN_FAILURE:
      return {
        ...state,
        hsnCodeError: action.payload,
      };

    case UPDATE_HSN_SUCCESS:
      return {
        ...state,
        updateHsnSuccess: action.payload,
      };

    case UPDATE_HSN_FAILURE:
      return {
        ...state,
        updateHsnFailure: action.payload,
      };

    case GET_TAX_RATES_SUCCESS:
      return {
        ...state,
        taxRates: action.payload,
      };

    case GET_TAX_RATES_FAILURE:
      return {
        ...state,
        taxRatesError: action.payload,
      };

    case UPDATE_TAX_RATES_SUCCESS:
      return {
        ...state,
        updateTaxRatesSuccess: action.payload,
      };

    case UPDATE_TAX_RATES_FAILURE:
      return {
        ...state,
        updateTaxRatesError: action.payload,
      };

    case GET_ALL_PARTNER_COMPANIES_SUCCESS:
      return {
        ...state,
        partnerCompaniesList: action.payload,
      };

    case GET_ALL_PARTNER_COMPANIES_FAILURE:
      return {
        ...state,
        partnerCompaniesListError: action.payload,
      };

    case GET_PARTNER_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        partnerCompanyDetails: action.payload,
      };

    case GET_PARTNER_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        partnerCompanyDetailsError: action.payload,
      };

    case UPDATE_PARTNER_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        updatePartnerCompanyDetailsSuccess: action.payload,
      };

    case UPDATE_PARTNER_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        updatePartnerCompanyDetailsError: action.payload,
      };

    case CREATE_PARTNER_COMPANY_SUCCESS:
      return {
        ...state,
        createPartnerCompanySuccess: action.payload,
      };

    case CREATE_PARTNER_COMPANY_FAILURE:
      return {
        ...state,
        createPartnerCompanyError: action.payload,
      };


    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        setPasswordSuccess: action.payload,
      };

    case SET_PASSWORD_FAILURE:
      return {
        ...state,
        setPasswordFailure: action.payload,
      };

    case SEND_OTP_SUCCESS:
      return {
        ...state,
        sendOTPSuccess: action.payload,
      };

    case SEND_OTP_FAILURE:
      return {
        ...state,
        sendOTPFailure: action.payload,
      };

    case UPLOAD_TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        uploadTermsAndConditionsSuccess: action.payload,
      };

    case UPLOAD_TERMS_AND_CONDITIONS_FAILURE:
      return {
        ...state,
        uploadTermsAndConditionsError: action.payload
      };

    case UPLOAD_PAYMENT_TERMS_SUCCESS:
      return {
        ...state,
        uploadPaymentTermsSuccess: action.payload,
      };

    case UPLOAD_PAYMENT_TERMS_FAILURE:
      return {
        ...state,
        uploadPaymentTermsError: action.payload
      };

    case UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        uploadLogoSuccess: action.payload,
      };

    case UPLOAD_LOGO_FAILURE:
      return {
        ...state,
        uploadLogoError: action.payload
      }
    case ADD_NEW_SERVICE_SUCCESS:
      return {
        ...state,
        addNewService: action.payload,
      };

    case ADD_NEW_SERVICE_FAILURE:
      return {
        ...state,
        addNewServiceError: action.payload,
      };

    case GET_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        servicesList: action.payload,
      };

    case GET_ALL_SERVICES_FAILURE:
      return {
        ...state,
        getAllServicesError: action.payload,
      };

    case GET_SERVICE_DETAILS_SUCCESS:
      return {
        ...state,
        serviceDetails: action.payload,
      };

    case GET_SERVICE_DETAILS_FAILURE:
      return {
        ...state,
        getServiceDetailsError: action.payload,
      };

    case UPDATE_SERVICE_DETAILS_SUCCESS:
      return {
        ...state,
        updateServiceSuccess: action.payload,
      };

    case UPDATE_SERVICE_DETAILS_FAILURE:
      return {
        ...state,
        updateServiceError: action.payload,
      };

    case UPLOAD_DSC_SUCCESS:
      return {
        ...state,
        uploadDscSuccess: action.payload,
      };

    case UPLOAD_DSC_FAILURE:
      return {
        ...state,
        uploadDscError: action.payload,
      };

    case GET_COMM_HEAD_CUSTOMERS_SPACES_REPORT_SUCCESS:
      return {
        ...state,
        getAreaByCustomer: action.payload,
      };

    case GET_COMM_HEAD_CUSTOMERS_SPACES_REPORT_FAILURE:
      return {
        ...state,
        getAreaByCustomerError: action.payload,
      };

    case GET_COMM_HEAD_CONTRACTS_AGING_REPORT_SUCCESS:
      return {
        ...state,
        agingReportData: action.payload,
      };

    case GET_COMM_HEAD_CONTRACTS_AGING_REPORT_FAILURE:
      return {
        ...state,
        agingReportDataError: action.payload,
      };

    case GET_FINANCE_MANAGER_INVOICES_REPORT_SUCCESS:
      return {
        ...state,
        invoicesReport: action.payload,
      };

    case GET_FINANCE_MANAGER_INVOICES_REPORT_FAILURE:
      return {
        ...state,
        invoicesReportError: action.payload,
      };

    case GET_FINANCE_MANAGER_INVOICE_REPORT_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        invoiceReportByCustomer: action.payload,
      };

    case GET_FINANCE_MANAGER_INVOICE_REPORT_BY_CUSTOMER_FAILURE:
      return {
        ...state,
        invoiceReportByCustomerError: action.payload,
      };

    case GET_INVOICES_COUNT_BY_STATUS_SUCCESS:
      return {
        ...state,
        invoiceCounts: action.payload,
      };

    case GET_INVOICES_COUNT_BY_STATUS_FAILURE:
      return {
        ...state,
        invoiceCountsError: action.payload,
      };

    case GET_CONTRACTS_COUNT_BY_STATUS_SUCCESS:
      return {
        ...state,
        contractsCount: action.payload,
      };

    case GET_CONTRACTS_COUNT_BY_STATUS_FAILURE:
      return {
        ...state,
        contractsCountError: action.payload,
      };

    case GET_FACILITY_MANAGER_METER_REPORT_SUCCESS:
      return {
        ...state,
        facManMeterReport: action.payload,
      };

    case GET_FACILITY_MANAGER_METER_REPORT_FAILURE:
      return {
        ...state,
        facManMeterReportError: action.payload,
      };

    case GET_FACILITY_MANAGER_METER_REPORT_DAILY_SUCCESS:
      return {
        ...state,
        dailyMeterReport: action.payload,
      };

    case GET_FACILITY_MANAGER_METER_REPORT_DAILY_FAILURE:
      return {
        ...state,
        dailyMeterReportError: action.payload,
      };

    case GET_FACILITY_MANAGER_CONSUMPTION_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        cusmptionByCustomer: action.payload,
      };

    case GET_FACILITY_MANAGER_CONSUMPTION_BY_CUSTOMER_FAILURE:
      return {
        ...state,
        cusmptionByCustomerError: action.payload,
      };

    case GET_FACILITY_MANAGER_FACILITIES_AREA_SUCCESS:
      return {
        ...state,
        facManFacilitiesArea: action.payload,
      };

    case GET_FACILITY_MANAGER_FACILITIES_AREA_FAILURE:
      return {
        ...state,
        facManFacilitiesAreaError: action.payload,
      };


    default:
      return state;
  }





};
export default ReduxReducer;

import React, { useEffect, useState, useRef } from 'react'
import { GoSearch } from "react-icons/go";
import { MdCurrencyRupee } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import { useDispatch, useSelector } from 'react-redux';
import { finmanGetMeterReadings, GetMeterReadingsByMeterId, updateMeterReadinginMeterReports, technicianUpdateMeterReadings, UPDATE_METER_READING_SUCCESS } from '../../Redux/Actions/Actions';
import { set } from 'date-fns';
import Loader from '../Reusable_Components/loader';
import AlertPopUp from '../Reusable_Components/Alert';
import { validateValue, handleKeyDownForMeterReadings, formatNumericInput } from "../Reusable_Components/ReusableFunctions";
import { SiVelog } from 'react-icons/si';
import Footer from "../Login/Footer";
import TableComponent from '../Reusable_Components/TableComponent';
import axios from 'axios';
import { base_url } from '../../utilities';
import moment from 'moment';
import { MdInfoOutline } from "react-icons/md";


export default function FIinanceManagerMeterReports() {
    const tableCompRef = useRef();
    const getLastDayOfCurrentMonth = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1; // October is 9 + 1 = 10

        // Creating a date for the first day of the next month, then subtracting one day to get the last day of the current month
        const lastDayOfMonth = new Date(year, month, 0);

        // Formatting the date manually to YYYY-MM-DD
        const lastDay = String(lastDayOfMonth.getDate()).padStart(2, '0');
        const lastMonth = String(lastDayOfMonth.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed

        return `${year}-${lastMonth}-${lastDay}`;
    };

    const lastDateOfCurrentMonth = getLastDayOfCurrentMonth();


    function formatDate(date) {
        const d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
    }

    const [currentPage, setCurrentPage] = useState(1)
    const [showImageModal, setshowImageModal] = useState(false)

    const [selectMeter, setSelectMeter] = useState("all")
    // const [invoiceData,setInvoiceData] = useState(invoiceTableDummyData)

    const [sortOrder, setSortOrder] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        currentDate.setMonth(currentMonth - 5);
        currentDate.setDate(1);
        return formatDate(currentDate);
    });
    const [endDate, setEndDate] = useState(formatDate(new Date()));
    const meterReportsList = useSelector(state => state.meterList)
    const error = useSelector(state => state.error);
    const [isLoading, setIsLoading] = useState(false)
    //for alert pop up
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])


    const [selectedMeter, setSelectedMeter] = useState(null);//store items
    const [selectedContractMeterRef, setSelectedContractMeterRef] = useState(null);
    const [fromDate, setFromDate] = useState(startDate)

    const [toDate, setToDate] = useState(lastDateOfCurrentMonth)
    const [meterReading, setMeterReading] = useState([]);
    const [newMeterReading, setNewMeterReading] = useState('');
    const meterReadingsforMeterReports = useSelector(state => state.meterReadingsforMeterReports);

    const [modalImage, setModalImage] = useState(null);
    const [errorValue, setErrorValue] = useState(null);

    const [showAddReadingModal, setShowAddReadingModal] = useState(false);

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [editedRow, setEditedRow] = useState(null);
    const contractApiLoader = useSelector(state => state.contractApiLoader)

    const persona = localStorage.getItem("group");

    const [meterReadingEntries, setMeterReadingEntries] = useState([]); //new readings


    // for Modal -add reading

    const [readingDate, setReadingDate] = useState('');
    // const [startDateModal, setStartDateModal] = useState(() => {
    //     const currentDate = new Date();
    //     const currentMonth = currentDate.getMonth();
    //     currentDate.setMonth(currentMonth);
    //     currentDate.setDate(1);
    //     return formatDate(currentDate);
    // });

    const [startDateModal, setStartDateModal] = useState(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
    
        // First day of the current month
        const firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1);
    
        // Last day of the previous month
        const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth - 1);
    
        // Minimum allowed date: either the 1st of the current month or last 3 days of the previous month
        const minDate = new Date(lastDayOfPreviousMonth);
        minDate.setDate(lastDayOfPreviousMonth.getDate() - 2); // Go back 2 more days
    
        return formatDate(minDate); // Ensure this function returns a valid date string
    });


    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const fetchReports = () => {
        setIsLoading(true)
        const data = {
            facility_id: localStorage.getItem("selected_facility_id"),
            start_date: startDate,
            end_date: endDate,
            meter_type: selectMeter
        };

        dispatch(finmanGetMeterReadings(data))
    }

    useEffect(() => {
        if (error?.response?.data?.message || error?.data?.data[1]?.message) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.data?.data[1] ? error?.data?.data[1].message : error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                    tableCompRef?.current?.clearEditedField();

                }
            }])
        }
        setIsLoading(false);
    }, [error])

    useEffect(() => {
        fetchReports()
    }, [])

    useEffect(() => {
        if (meterReportsList?.length > 0) {
            const groupedData = meterReportsList.reduce((acc, item) => {
                let contract = acc.find(c => c.contract_number === item.contract_number);
                if (!contract) {
                    contract = {
                        contract_number: item.contract_number,
                        contract_name: item.contract_name,
                        meters: []
                    };
                    acc.push(contract);
                }

                const meter = {
                    meter_number: item.meter_number,
                    meter_type: item.meter_type,
                    reading_type: item.reading_type,
                    final_reading: item.final_reading,
                    total_units: item.total_units,
                    cost_per_unit: item.cost_per_unit,
                    initial_reading: item.initial_reading,
                    total_amount: item.total_amount,
                    assigned_name: item.assigned_name,
                    meter_id: item.meter_id,
                    reading_unit: item.reading_unit,
                };
                contract.meters.push(meter);
                return acc;
            }, []);
            // setFilteredData(groupedData)
            handleSearch(searchTerm)
        }
        if (meterReportsList?.length === 0) {
            setFilteredData([])
        }
        setIsLoading(false)
    }, [meterReportsList])

    const convertData = (data) => {
        if (data?.length > 0) {
            // setMeterReports(meterReportsList)
            // setFilteredData(meterReportsList)
            const groupedData = data.reduce((acc, item) => {
                let contract = acc.find(c => c.contract_number === item.contract_number);
                if (!contract) {
                    contract = {
                        contract_number: item.contract_number,
                        contract_name: item.contract_name,
                        meters: []
                    };
                    acc.push(contract);
                }

                const meter = {
                    meter_number: item.meter_number,
                    meter_type: item.meter_type,
                    reading_type: item.reading_type,
                    final_reading: item.final_reading,
                    total_units: item.total_units,
                    cost_per_unit: item.cost_per_unit,
                    initial_reading: item.initial_reading,
                    total_amount: item.total_amount,
                    assigned_name: item.assigned_name,
                    meter_id: item.meter_id,
                    reading_unit: item.reading_unit,
                };
                contract.meters.push(meter);
                return acc;
            }, []);
            setFilteredData(groupedData)
        }
        if (data?.length === 0) {
            setFilteredData([])
        }
    }


    const sortContractNumber = () => {
        const sortedData = filteredData.sort((a, b) => {
            if (sortOrder) {
                return a.contract_number.localeCompare(b.contract_number)
            } else {
                return b.contract_number.localeCompare(a.contract_number)
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    const sortContractName = () => {
        const sortedData = filteredData.sort((a, b) => {
            if (sortOrder) {
                return a.contract_name.localeCompare(b.contract_name)
            } else {
                return b.contract_name.localeCompare(a.contract_name)
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    function formatToIndianRupee(number) {
        if (number === 0 || number === null || number === undefined) return 0;
        let parts = number?.toString().split(".");
        const numStr = parts[0];
        let decimalPart = parts?.length > 1 ? ("." + (parts[1]?.length > 1 ? parts[1].substring(0, 2) : parts[1] + '0')) : "";

        if (numStr?.length <= 3) return numStr + decimalPart;
        let lastThree = numStr.substring(numStr?.length - 3);
        const otherNumbers = numStr.substring(0, numStr?.length - 3);
        if (otherNumbers !== '') {
            lastThree = ',' + lastThree;
        }
        const output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + decimalPart;
        return output;
    }


    const handleSearch = (text) => {
        if (validateValue(text)) {
            setSearchTerm(text);
            const searchText = text?.toLowerCase();
            const filtered = meterReportsList.filter(report =>
                report.contract_number?.toLowerCase().includes(searchText) ||
                report.contract_name?.toLowerCase().includes(searchText)
            );
            convertData(filtered);
        }
    }

    const handleApply = () => {
        const filters = {
            meter_id: selectedMeter?.meter_id,
            reading_type: selectedMeter?.reading_type,
            start_date: fromDate,
            end_date: toDate,
        };
        dispatch(GetMeterReadingsByMeterId(filters));
        tableCompRef?.current?.clearEditables();
    };

    const handleClear = () => {
        setFromDate(startDate);
        setToDate(lastDateOfCurrentMonth);
        setMeterReading(meterReadingsforMeterReports);
        const filters = {
            meter_id: selectedMeter?.meter_id,
            reading_type: selectedMeter?.reading_type,
            start_date: startDate,
            end_date: lastDateOfCurrentMonth,

        };
        dispatch(GetMeterReadingsByMeterId(filters));
        tableCompRef?.current?.clearEditables();
    };


    const handleView = (entry) => {
        if (entry.image_url !== null) {
            setIsLoading(true)
            axios.get(base_url + 'documents?filter_condition=%7B%22s3_keys%22%3A%5B%22' + entry.image_url + '%22%5D%7D')
                .then(response => {
                    const image = response.data.data[0]
                    setModalImage('data:image/webp;base64,' + image.file.split(`webpbase64`)[1].slice(0, -1))
                    setIsLoading(false);
                }
                )
                .catch(error => {
                    setErrorValue(error?.response?.data.message);
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([{
                        name: 'Close', action: () => {
                            setAlertShow(false)
                        }
                    }])
                    setIsLoading(false)
                })
        }
        setshowImageModal(true);
    };
    const closeModal = () => {
        setshowImageModal(false);
        setModalImage(null);
    }

    const handleMeterClick = (item, each) => {
        setIsLoading(true);
        setSelectedMeter(item);
        setSelectedContractMeterRef(each);


        const filters = {
            meter_id: item?.meter_id,
            reading_type: item?.reading_type,
            start_date: startDate,
            end_date: toDate,
        };

        dispatch(GetMeterReadingsByMeterId(filters));
        setIsLoading(false);
        setCurrentPage(2);
    };
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    const updateMeterReading = useSelector(state => state.updateMeterReading);
    useEffect(() => {
        if (updateMeterReading && !error) {
            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue('Meter reading value is successfully updated.')
            setAlertButtons([{
                name: 'Close', action: () => {
                    dispatch({
                        type: UPDATE_METER_READING_SUCCESS,
                        payload: null,
                    });
                    tableCompRef?.current?.clearEditables();

                    setAlertShow(false)
                }
            }])
        }
    }, [updateMeterReading]
    )


    const oppfunction1 = (value, rowIndex, columnIndex, row) => {

        setEditedRow(row);
        const meterReadingUpdate = {
            meter_id: row.meter_id,
            reading_type: row.reading_type,
            meter_reading: value,
            reading_date: row.reading_date,
            reading_unit: row.reading_unit,
            updated_by: localStorage.getItem("username"),
        }

        let tempTableData = [...meterReadingsforMeterReports];
        let data = tempTableData[rowIndex];
        data.meter_reading = value;
        data.updated_by = localStorage.getItem("username");
        tempTableData[rowIndex] = data;
        setMeterReading(tempTableData);

        if (row?.meter_id) {
            dispatch(updateMeterReadinginMeterReports(meterReadingUpdate));

        } else {
            console.error("Error: row.meter_id is undefined or null.");
        }
    };

    const clearAddReadingStates = () => {
        setReadingDate('');
        setNewMeterReading('');
    }
    const addreadingfunc = () => {
        setIsLoading(true);
        const data = {
            meter_id: selectedMeter?.meter_id,
            reading_type: selectedMeter?.reading_type,
            meter_reading: parseFloat(newMeterReading),
            reading_date: readingDate,
            reading_unit: selectedMeter?.reading_unit,
        }
        axios
            .post(base_url + "facilities/meterReadings", data)
            .then((response) => {



                if (response.data.status === "success") {
                    setAlertShow(true)
                    setAlertType('success')
                    setAlertMessage('Success')
                    setAlertValue('Meter reading value is successfully added.')
                    setAlertButtons([{ name: 'Close', action: () => { setAlertShow(false) } }])
                    setShowAddReadingModal(false)
                    clearAddReadingStates()
                    setIsLoading(false);

                    //     const modReadingDate = formatDate(new Date(readingDate));
                    //     let finalDate = modReadingDate;
                    //     if (modReadingDate >= toDate) {
                    //         finalDate = modReadingDate;

                    //     } else {
                    //         finalDate = toDate;
                    //     }

                    // setToDate(finalDate);


                    const filters = {
                        meter_id: selectedMeter?.meter_id,
                        reading_type: selectedMeter?.reading_type,
                        start_date: fromDate,
                        end_date: lastDateOfCurrentMonth,
                    };

                    dispatch(GetMeterReadingsByMeterId(filters));

                }
            }
            )
            .catch((error) => {
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{ name: 'Close', action: () => { setAlertShow(false) } }])
                setIsLoading(false);

            })
        // showNewreadingSuccess(true);
    };

    const handleCancel = () => {
        setShowAddReadingModal(false)
        clearAddReadingStates();
    };


    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const handleCloseUpdatesucessModal = () => {
        setShowSuccessModal(false);
        const filters = {
            meter_id: editedRow.meter_id,
            reading_type: editedRow.reading_type,
            start_date: formatDate(fromDate),
            end_date: formatDate(toDate),
        };
        dispatch(GetMeterReadingsByMeterId(filters));
    };

    const handleBack = () => {
        setFromDate(startDate);
        setToDate(lastDateOfCurrentMonth);

        setCurrentPage(1)
    };
    const handleEnable = (each) => {
        return false;
    }

    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>


            {isLoading && <Loader />}
            {contractApiLoader && <Loader />}
            <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
            {(currentPage === 1) && <div className='meter_container_reports '>
                <div className='fm-invoice-con'>
                    <div className='fm-invoice-heading-con'>
                        <h3 className="invoice-heading">Meter Reports</h3>
                    </div>
                </div>
                <div className='fm-invoice-con'>
                    <div className='fin-meter-reports-con'>
                        <div className='d-flex align-items-center gap-3 mt-2'>
                            <select className='fin_man_meter_dropdown' style={{ marginTop: '10px' }} onChange={(e) => setSelectMeter(e.target.value)} value={selectMeter}>
                                <option value={"all"}>Meter Type</option>
                                <option value={"electricity"}>Electricity Meter</option>
                                <option value={"water"}>Water Meter</option>
                            </select>
                        </div>
                        <div className='d-flex flex-column'>
                            <label>Start date</label>
                            <input type='date' onChange={e => {
                                if (endDate < e.target.value) {
                                    const newEndDate = new Date(e.target.value)
                                    newEndDate.setMonth(newEndDate.getMonth() + 1)
                                    const formattedEndDate = newEndDate.toISOString().split('T')[0]
                                    setEndDate(formattedEndDate)
                                }
                                setStartDate(e.target.value)
                            }}

                                onKeyDown={(e) => e.preventDefault()}
                                value={startDate}
                                className='fin_man_date_range' />
                        </div>
                        <div className='d-flex flex-column'>
                            <label>End date</label>
                            <input type='date' value={endDate}
                                onKeyDown={(e) => e.preventDefault()}
                                min={startDate}
                                max={formatDate(new Date())}
                                onChange={e =>
                                    setEndDate(e.target.value)
                                } className='fin_man_date_range' />
                        </div>
                        <div className='fin_man_meter_search_bar_container' style={{ marginTop: '20px' }}>
                            <input type='search' placeholder='Search' value={searchTerm} className='fin_man_meter_search_bar' onChange={e => handleSearch(e.target.value)} />
                            <GoSearch className='fin_man_meter_search_bar_icon' />
                        </div>
                        <button className="invoice_btn" style={{ marginTop: '18px', color: 'white', height: '20px !important' }} onClick={() => {
                            fetchReports()
                        }}>Apply</button>
                        <button className="invoice_btn" style={{ marginTop: '18px', color: 'white', height: '20px !important' }}
                            onClick={() => {
                                const currentDate = new Date();
                                const currentMonth = currentDate.getMonth();
                                currentDate.setMonth(currentMonth - 5);
                                currentDate.setDate(1);
                                const startDate1 = formatDate(currentDate);
                                const endDate1 = formatDate(new Date())
                                setEndDate(endDate1);
                                setStartDate(startDate1)
                                setSelectMeter("all")
                                setSearchTerm('')
                                setIsLoading(true)
                                const data = {
                                    facility_id: localStorage.getItem("selected_facility_id"),
                                    start_date: startDate1,
                                    end_date: endDate1,
                                    meter_type: 'all'
                                };
                                dispatch(finmanGetMeterReadings(data))
                            }}>Clear</button>
                    </div>
                </div>
                {selectMeter && <div className='fin_meter_reports_table'>
                    <table>
                        <thead>
                            <tr className='fin_man_table_head commercial-head-table text-center'>
                                <th className='C-pointer' onClick={() => sortContractNumber()}>Contract No.<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                <th className='C-pointer' onClick={() => sortContractName()}>Contract Name<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                <th>Meter Type</th>
                                <th>Meter Number</th>
                                <th>Assigned Name</th>
                                <th>Start Reading</th>
                                <th>End Reading</th>
                                <th>Total Units</th>
                                {persona !== 'Facility Manager' && (
                                    <>
                                        <th>Cost/Unit <br />(<img src='./images/icons/rupee.svg' alt='currency' />)</th>
                                        <th>Total Amount <br />(<img src='./images/icons/rupee.svg' alt='currency' />)</th>
                                    </>
                                )}

                            </tr>
                        </thead>
                        <tbody className='fin_man_table_body'>
                            {filteredData && filteredData?.length !== 0 ?
                                filteredData?.map((each, index) => {
                                    return <tr key={index} className='table_row'>
                                        <td >{each?.contract_number}</td>
                                        <td>{each?.contract_name}</td>
                                        <td>
                                            {each?.meters?.map((item, idx) => (
                                                <div key={idx}>
                                                    {item.reading_type[0]?.toUpperCase() + item.reading_type?.substring(1)}
                                                </div>
                                            ))}
                                        </td>
                                        <td className='table_text_left'>

                                            {each?.meters?.map((item, idx) => (
                                                <div
                                                    key={idx}
                                                    onClick={() => handleMeterClick(item, each)}

                                                    className="meter-link"
                                                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                >
                                                    {item.meter_number}
                                                </div>
                                            ))}
                                        </td>
                                        <td className='table_text_left'>
                                            {each?.meters?.map((item, idx) => (
                                                <div key={idx}>{item.assigned_name}</div>
                                            ))}
                                        </td>
                                        <td className='table_text_right'>
                                            {each?.meters?.map((item, index) => {
                                                if (index === 0) return <>{formatToIndianRupee(item.initial_reading)} {item.reading_type === 'water' ? 'kL' : 'kWh'}</>
                                                else return <><br />{formatToIndianRupee(item.initial_reading)} {item.reading_type === 'water' ? 'kL' : 'kWh'}</>
                                            })}
                                        </td>
                                        <td className='table_text_right'>
                                            {each?.meters?.map((item, index) => {
                                                if (index === 0) return <>{formatToIndianRupee(item.final_reading)} {item.reading_type === 'water' ? 'kL' : 'kWh'}</>
                                                else return <><br />{formatToIndianRupee(item.final_reading)} {item.reading_type === 'water' ? 'kL' : 'kWh'}</>
                                            })}
                                        </td>
                                        <td className='table_text_right'>
                                            {each?.meters?.map((item, index) => {
                                                if (index === 0) return <>{formatToIndianRupee(item.total_units)} {item.reading_type === 'water' ? 'kL' : 'kWh'}</>
                                                else return <><br />{formatToIndianRupee(item.total_units)} {item.reading_type === 'water' ? 'kL' : 'kWh'}</>
                                            })}
                                        </td>
                                        {persona !== 'Facility Manager' && (
                                            <>
                                                <td className='table_text_right'>
                                                    {each?.meters?.map((item, index) => {
                                                        if (index === 0) return <>{formatToIndianRupee(item.cost_per_unit)}</>;
                                                        else return <><br />{formatToIndianRupee(item.cost_per_unit)}</>;
                                                    })}
                                                </td>
                                                <td className='table_text_right'>
                                                    {each?.meters?.map((item, index) => {
                                                        if (index === 0) return <>{formatToIndianRupee(item.total_amount)}</>;
                                                        else return <><br />{formatToIndianRupee(item.total_amount)}</>;
                                                    })}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                })
                                :
                                <tr>
                                    <td colSpan='10' className='text-center'>No Records Found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>}
            </div>}

            {/* meter details */}
            {currentPage === 2 && selectedMeter && selectedContractMeterRef && (
                <div className='meterreadings_homepage'>
                    <div className='meterreadings_con'>

                        <div className='meter-readings-heading-con'>
                            <h3 className="invoice-heading">Meter Details</h3>
                        </div>
                        <div className='meter-report-container'>
                            <div className="meter-report-row">
                                <div className="meter-report-col">
                                    <span className="label">Contract Name:</span>
                                    <input
                                        type="text"
                                        value={selectedContractMeterRef?.contract_name}
                                        disabled
                                        className="meter-input-field"
                                    />
                                </div>
                                <div className="meter-report-col">
                                    <span className="label">Meter Type:</span>
                                    <input
                                        type="text"
                                        value={selectedMeter?.reading_type}
                                        disabled
                                        className="meter-input-field"
                                    />
                                </div>
                            </div>

                            <div className="meter-report-row">
                                <div className="meter-report-col">
                                    <span className="label">Meter Number:</span>
                                    <input
                                        type="text"
                                        value={selectedMeter?.meter_number}
                                        disabled
                                        className="meter-input-field"
                                    />
                                </div>
                                <div className="meter-report-col">
                                    <span className="label">Assigned Meter Name:</span>
                                    <input
                                        type="text"
                                        value={selectedMeter?.assigned_name}
                                        disabled
                                        className="meter-input-field"
                                    />
                                </div></div>
                        </div>

                        <div className='meterreports_filterdiv'>
                            <div className='filter-section'>
                                <label>Select Date Range:</label>
                                <div className='date-range-inputs'>
                                    <div className='date-input'>
                                        <input
                                            type='date'
                                            value={fromDate}
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={e => setFromDate(formatDate(e.target.value))}
                                            className='fin_man_date_range'
                                        />
                                    </div>
                                    <div className='date-input'>
                                        <input
                                            type='date'
                                            value={toDate}
                                            onKeyDown={(e) => e.preventDefault()}
                                            min={fromDate}
                                            // max={getLastDayOfCurrentMonth()}
                                            onChange={e => setToDate(formatDate(e.target.value))}
                                            className='fin_man_date_range'
                                        />
                                    </div>
                                </div>
                                <div className='button-group'>
                                    <button className="invoice_btn apply-btn" onClick={handleApply}>Apply</button>
                                    <button className="invoice_btn clear-btn" onClick={handleClear}>Clear</button>
                                </div>
                            </div>
                            {persona !== "Finance Manager" ? (
                                <div className='add-reading-button' onClick={setShowAddReadingModal}>
                                    <span>Add Reading</span>
                                    <img src='./images/icons/addreading.svg' alt='add reading' />
                                </div>
                            ) : null
                            }
                        </div>

                        {/* table */}
                        <div className='table-containerTC mt-2' style={{ overflow: "auto" }}>
                            {meterReadingsforMeterReports?.length === 0 ? (
                                <div style={{ fontSize: 'large', textAlign: 'center', margin: '3rem' }}>No readings available for the selected date range.</div>
                            ) : (
                                <TableComponent
                                    ref={tableCompRef}
                                    headers={[
                                        { name: "Reading Date", type: "date", key: "reading_date", sort: true },
                                        {
                                            name: "Meter Reading", type: "number", key: "meter_reading", sort: true,
                                            formatNumberWithComas: true,
                                            edit: (row) => {
                                                if (persona === "Finance Manager") {
                                                    return false;
                                                }
                                                
                                                const readingDate = row.reading_date ? new Date(row.reading_date) : null;
                                                if (!readingDate) return false;
                                        
                                                const today = new Date();
                                                const currentMonth = today.getMonth();
                                                const currentYear = today.getFullYear();
                                        
                                                // Check if the readingDate is in the current month
                                                if (readingDate.getMonth() === currentMonth && readingDate.getFullYear() === currentYear) {
                                                    return true;
                                                }
                                        
                                                // Get last day of the previous month
                                                const firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1);
                                                const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth - 1);
                                        
                                                // Check if readingDate is within the last 3 days of the previous month
                                                return (
                                                   true
                                                );
                                            },
                                            inputType: "number",
                                            unitsKey: "reading_unit"
                                        },
                                        { name: "Last Updated At", type: "time_stamp", key: "updated_at", sort: true, updatedKey: "true" },
                                        { name: "Updated By", type: "text", key: "updated_by", sort: true },
                                        {
                                            name: "Image ", type: "button", buttons: [
                                                {
                                                    name: "View",
                                                    icon: "./images/icons/View.png",
                                                    disable: handleEnable,
                                                    action: handleView
                                                },
                                            ]
                                        }
                                    ]}
                                    oppfunction1={oppfunction1}
                                    data={meterReadingsforMeterReports}
                                    loading={isLoading}
                                />)}
                        </div>
                        {/* <div className="infoFotter"> */}
                        <div className="d-flex gap-3" style={{ marginTop: '15px', justifyContent: 'right' }}>
                            <button className="btn btn-secondary" onClick={handleBack} >Back</button>
                        </div>
                        {/* image popup modal*/}
                        <Modal show={showImageModal} keyboard={false} backdrop="static" centered onHide={closeModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Image</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="popper-modal"
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
                                >
                                    {modalImage ? <img src={modalImage} alt="meter" style={{ width: "100%" }} /> :
                                        <>
                                            {!isLoading && <span style={{ fontSize: "16px", padding: "20px" }}>
                                                <MdInfoOutline style={{ marginRight: '10px' }} />This image does not exist or it is not uploaded.
                                            </span>}
                                        </>
                                    }

                                </div>
                            </Modal.Body>
                        </Modal>
                        {/* updated reading success modal */}
                        <Modal show={showSuccessModal} keyboard={false} backdrop="static" centered onHide={handleCloseUpdatesucessModal} className="popper-modal">
                            <Modal.Header>
                                <Modal.Title>Success</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div >
                                    Meter reading value is successfully updated.
                                </div>
                            </Modal.Body>
                            <Modal.Footer >
                                <Button variant="secondary" onClick={handleCloseUpdatesucessModal}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        {/* add reading modal */}
                        <Modal show={showAddReadingModal} keyboard={false} backdrop="static" centered
                            onHide={() => {
                                setShowAddReadingModal(false)
                                clearAddReadingStates()
                            }}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add {capitalizeFirstLetter(selectedMeter?.reading_type)} Reading</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <div className="meter-reading-inner-div1">
                                        <p>Reading Date <span style={{ color: "red" }}>*</span></p>
                                        {/* date to accept only current month dates */}
                                        <input
                                            type='date'
                                            // min={startDateModal}
                                            max={getLastDayOfCurrentMonth()}
                                            value={readingDate}
                                            onChange={(e) => {
                                                const date = e.target.value
                                                const checkReading = meterReadingsforMeterReports?.find(item => item.reading_date === date)
                                                if (checkReading) {
                                                    setAlertShow(true)
                                                    setAlertType('error')
                                                    setAlertMessage('Error')
                                                    setAlertValue('Reading for this date already exists.')
                                                    setAlertButtons([{ name: 'Close', action: () => { setAlertShow(false) } }])
                                                    setShowAddReadingModal(false)
                                                    clearAddReadingStates();
                                                } else {
                                                    setAlertShow(false)
                                                    setReadingDate(e.target.value)
                                                }
                                            }}
                                        />
                                    </div>

                                    {/* for readings */}
                                    <div className="meter-reading-inner-div1">
                                        <p> Meter Name</p>
                                        <input
                                            type='text'
                                            value={selectedMeter?.assigned_name}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="meter-reading-inner-div1">
                                        <p>Reading <span style={{ color: "red" }}>*</span></p>
                                        <input
                                            type='text'
                                            value={newMeterReading}
                                            onChange={(e) => {
                                                const input = e.target.value;
                                                const formattedInput = formatNumericInput(input, 3);
                                                setNewMeterReading(formattedInput)
                                            }}
                                            onKeyDown={handleKeyDownForMeterReadings}
                                        />
                                    </div>


                                </div>
                            </Modal.Body>
                            <Modal.Footer className="footer">
                                <Button className="confirmation" onClick={handleCancel}>Cancel</Button>
                                <Button variant="secondary" className="confirmation-left"
                                    disabled={!readingDate || !newMeterReading}
                                    style={{ opacity: !readingDate || !newMeterReading ? '0.5' : '1' }}
                                    onClick={() => {
                                        addreadingfunc()
                                    }} >Submit</Button>
                            </Modal.Footer>
                        </Modal>
                        {/* reading added success modal */}
                        {/* <Modal show={showNewreadingSuccess} keyboard={false} backdrop="static" centered  className="popper-modal">
                            <Modal.Header>
                                <Modal.Title>Success</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div >
                                Meter Reading is successfully added.
                                </div>
                            </Modal.Body>
                            <Modal.Footer >
                                <Button variant="secondary">
                                Close
                                </Button>
                            </Modal.Footer>
                        </Modal> */}
                    </div>
                </div>

            )}

            <Footer />

        </>
    )
}

import React, { useEffect, useState } from "react";
import NavBarTop from "../Reusable_Components/NavBarTop";
import LeftNavBar from "../Reusable_Components/LeftNavBar";
import DashboardCard from "../Reusable_Components/Dashboard/DashboardCard";
import CommercialHeadContractsAgingBarChart from "../Reusable_Components/Dashboard/CommercialHeadContractsAgingBarChart";
import CommercialHeadContractSpacesPieChart from "../Reusable_Components/Dashboard/CommercialHeadContractSpacesPieChart";
import { useDispatch, useSelector } from "react-redux";
import { GET_COMM_HEAD_CONTRACTS_AGING_REPORT_FAILURE, GET_COMM_HEAD_CUSTOMERS_SPACES_REPORT_FAILURE, GET_CONTRACTS_COUNT_BY_STATUS_FAILURE, getCommercialHeadContractsAgingReport, getCommercialHeadSpaceByCustomers, getContractsCountByStatus } from "../../Redux/Actions/Actions";
import AlertPopUp from "../Reusable_Components/Alert";

const agingData = [
    {
        "Facility Name": "ACLC-11",
        "Customer Name": "Amazon",
        "AgeInMonths": 1,
        "Count": 2
    },
    {
        "Facility Name": "ACLC-11",
        "Customer Name": "Amazon",
        "AgeInMonths": 12,
        "Count": 4
    },
    {
        "Facility Name": "ACLC-11",
        "Customer Name": "FedEx",
        "AgeInMonths": 6,
        "Count": 1
    },
    {
        "Facility Name": "ACLC-11",
        "Customer Name": "SpiceJet",
        "AgeInMonths": 1,
        "Count": 1
    },
    {
        "Facility Name": "ACLC-111",
        "Customer Name": "UPS",
        "AgeInMonths": 1,
        "Count": 1
    },
    {
        "Facility Name": "ACLC-111",
        "Customer Name": "ABC",
        "AgeInMonths": 12,
        "Count": 1
    },
    {
        "Facility Name": "ACLC-111",
        "Customer Name": "XYZ",
        "AgeInMonths": 1,
        "Count": 1
    },
    {
        "Facility Name": "ACLC-111",
        "Customer Name": "Amazon",
        "AgeInMonths": 6,
        "Count": 1
    }
]


const CommercialHeadDashboard = () => {

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isMEdiumScreen, setIsMediumScreen] = useState(false);


    const getAreaByCustomer = useSelector(state => state.getAreaByCustomer)
    const agingReportData = useSelector(state => state.agingReportData)
    const contractsCount = useSelector(state => state.contractsCount);
    const getAreaByCustomerError = useSelector(state => state.getAreaByCustomerError)
    const agingReportDataError = useSelector(state => state.agingReportDataError)
    const contractsCountError = useSelector(state => state.contractsCountError)

    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getContractsCountByStatus());
        dispatch(getCommercialHeadSpaceByCustomers())
        dispatch(getCommercialHeadContractsAgingReport())
    }, [])

    useEffect(() => {

        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768); // Adjust the threshold as needed
            setIsMediumScreen(window.innerWidth > 768 && window.innerWidth < 1070)
        };

        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <>
            <NavBarTop />
            <LeftNavBar />
            <div className="fm-dashboard-container">
                <h1 className="fm-dashboard-heading">Dashboard</h1>
                <div className="dashboard_cards">
                    <DashboardCard
                        total={
                            {
                                name: "Total Contracts",
                                value: contractsCount && contractsCount?.length > 0 && contractsCount[0]?.total_contracts
                            }
                        }
                        moreFields={
                            [
                                {
                                    name: "Active",
                                    value: contractsCount && contractsCount?.length > 0 && contractsCount[0]?.contract_count
                                },
                                {
                                    name: "Pending",
                                    value: contractsCount && contractsCount?.length > 0 && contractsCount[1]?.contract_count
                                },
                                {
                                    name: "Draft",
                                    value: contractsCount && contractsCount?.length > 0 && contractsCount[2]?.contract_count
                                },
                            ]
                        }
                    />
                </div>
                <div style={{ marginRight: "20px" }} className={`d-flex gap-2 ${isSmallScreen ? 'flex-column' : ''}`}>
                    <div style={{ flex: 1 }}>
                        <CommercialHeadContractsAgingBarChart
                            barChartName="Contracts Aging Report"
                            barchartData={agingReportData}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <CommercialHeadContractSpacesPieChart
                            pieChartLabel="Area Distribution by Customers"
                            areaData={getAreaByCustomer}
                        />
                    </div>
                </div>
            </div>
            {agingReportDataError && <AlertPopUp
                alertShow={agingReportDataError}
                alertType="error"
                alertMsg={agingReportDataError}
                alertValue={agingReportDataError}
                alertButtons={[{
                    name: 'Close', action: () => {
                        dispatch({
                            type: GET_COMM_HEAD_CONTRACTS_AGING_REPORT_FAILURE,
                            payload: null
                        })
                    }
                }]}
            />}
            {getAreaByCustomerError && <AlertPopUp
                alertShow={getAreaByCustomerError}
                alertType="error"
                alertMsg={getAreaByCustomerError}
                alertValue={getAreaByCustomerError}
                alertButtons={[{
                    name: 'Close', action: () => {
                        dispatch({
                            type: GET_COMM_HEAD_CUSTOMERS_SPACES_REPORT_FAILURE,
                            payload: null
                        })
                    }
                }]}
            />}
            {contractsCountError && <AlertPopUp
                alertShow={contractsCountError}
                alertType="error"
                alertMsg={contractsCountError}
                alertValue={contractsCountError}
                alertButtons={[{
                    name: 'Close', action: () => {
                        dispatch({
                            type: GET_CONTRACTS_COUNT_BY_STATUS_FAILURE,
                            payload: null
                        })
                    }
                }]}
            />}
        </>
    )
}

export default CommercialHeadDashboard
import React, { useEffect, useState } from 'react'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import FacilityManagerFacilitiesTreeMap from '../Reusable_Components/Dashboard/FacilityManagerFacilitiesTreeMap'
import FacilityManagerElectricityReport from '../Reusable_Components/Dashboard/FacilityManagerElectricityReport'
import FacilityManagerWaterReport from '../Reusable_Components/Dashboard/FacilityManagerWaterReport'
import { GET_FACILITY_MANAGER_CONSUMPTION_BY_CUSTOMER_FAILURE, GET_FACILITY_MANAGER_FACILITIES_AREA_FAILURE, GET_FACILITY_MANAGER_METER_REPORT_DAILY_FAILURE, GET_FACILITY_MANAGER_METER_REPORT_FAILURE, getFacilityManagerConsumptionByCustomer, getFacilityManagerFacilitiesArea, getFacilityManagerMeterReports, getFacilityManagerMeterReportsDaily, getFinanceManagerInvoicesReportByCustomer } from '../../Redux/Actions/Actions'
import { useDispatch, useSelector } from 'react-redux'
import FacilityManagerDailyElectricityReport from '../Reusable_Components/Dashboard/FacilityManagerDailyElectricityReport'
import ToggleSwitch from '../Reusable_Components/ToggleSwitch'
import FacilityManagerDailyWaterReport from '../Reusable_Components/Dashboard/FacilityManagerDailyWaterReport'
import FacilityManagerConsumptionByCustomer from '../Reusable_Components/Dashboard/FacilityManagerConsumptionByCustomer'
import AlertPopUp from '../Reusable_Components/Alert'

export default function FacilityManagerDashboard() {

    const dispatch = useDispatch();

    const facManMeterReport = useSelector(state => state.facManMeterReport);
    const dailyMeterReport = useSelector(state => state.dailyMeterReport);
    const cusmptionByCustomer = useSelector(state => state.cusmptionByCustomer);
    const facManFacilitiesArea = useSelector(state => state.facManFacilitiesArea);
    const facManMeterReportError = useSelector(state => state.facManMeterReportError);
    const dailyMeterReportError = useSelector(state => state.dailyMeterReportError);
    const cusmptionByCustomerError = useSelector(state => state.cusmptionByCustomerError);
    const facManFacilitiesAreaError = useSelector(state => state.facManFacilitiesAreaError);

    const [electricityReportViewMode, setElctricityReportViewMode] = useState('monthly');
    const [waterReportViewMode, setWaterReportViewMode] = useState('monthly');
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isMediumScreen, setIsMediumScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768); // Adjust the threshold as needed
            setIsMediumScreen(window.innerWidth > 768 && window.innerWidth < 1070);
        };

        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (electricityReportViewMode === "monthly") {
            !facManMeterReport && dispatch(getFacilityManagerMeterReports());
        } else {
            !dailyMeterReport && dispatch(getFacilityManagerMeterReportsDaily());
        }

        if (waterReportViewMode === "monthly") {
            !facManMeterReport && dispatch(getFacilityManagerMeterReports());
        } else {
            !dailyMeterReport && dispatch(getFacilityManagerMeterReportsDaily());
        }
    }, [electricityReportViewMode, waterReportViewMode]);

    useEffect(() => {
        dispatch(getFacilityManagerConsumptionByCustomer());
        dispatch(getFacilityManagerFacilitiesArea());
    }, []);

    return (
        <>
            <NavBarTop />
            <LeftNavBar />

            <div className="fm-dashboard-container">
                <h1 className="fm-dashboard-heading">Dashboard</h1>
                <div style={{ marginRight: "20px", flexDirection: isSmallScreen ? 'column' : 'row' }} className='d-flex gap-2' >
                    <div className='dashboard-chart-container' style={{ flex: 1, position: "relative" }}>
                        <ToggleSwitch
                            className="dashboard-toggle-styling"
                            selected={electricityReportViewMode}
                            setSelected={setElctricityReportViewMode}
                        />
                        {electricityReportViewMode === "monthly" ? <FacilityManagerElectricityReport
                            barChartName="Electricity Report"
                            ElectricityReportData={facManMeterReport}
                        /> :
                            <FacilityManagerDailyElectricityReport
                                barChartName="Electricity Report"
                                ElectricityReportData={dailyMeterReport}
                            />
                        }
                    </div>
                    <div className='dashboard-chart-container' style={{ flex: 1, position: "relative" }}>
                        <ToggleSwitch
                            className="dashboard-toggle-styling"
                            selected={waterReportViewMode}
                            setSelected={setWaterReportViewMode}
                        />
                        {waterReportViewMode === "monthly" ? <FacilityManagerWaterReport
                            barChartName="Water Report"
                            WaterReportData={facManMeterReport}
                        /> :
                            <FacilityManagerDailyWaterReport
                                barChartName="Water Report"
                                WaterReportData={dailyMeterReport}
                            />
                        }
                    </div>
                </div>
                <div style={{ marginRight: "20px", flexDirection: isSmallScreen ? 'column' : 'row' }} className='d-flex gap-2 mt-2' >
                    <div style={{ flex: 1 }}>
                        <FacilityManagerConsumptionByCustomer
                            barChartName="Consumption By Customer"
                            consumptioByCustomer={cusmptionByCustomer}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <FacilityManagerFacilitiesTreeMap
                            facilitiesArea={facManFacilitiesArea}
                        />
                    </div>
                </div>
            </div>
            {facManMeterReportError && <AlertPopUp
                alertShow={facManMeterReportError}
                alertType="error"
                alertMsg={facManMeterReportError}
                alertValue={facManMeterReportError}
                alertButtons={[{
                    name: 'Close', action: () => {
                        dispatch({
                            type: GET_FACILITY_MANAGER_METER_REPORT_FAILURE,
                            payload: null
                        })
                    }
                }]}
            />}
            {dailyMeterReportError && <AlertPopUp
                alertShow={dailyMeterReportError}
                alertType="error"
                alertMsg={dailyMeterReportError}
                alertValue={dailyMeterReportError}
                alertButtons={[{
                    name: 'Close', action: () => {
                        dispatch({
                            type: GET_FACILITY_MANAGER_METER_REPORT_DAILY_FAILURE,
                            payload: null
                        })
                    }
                }]}
            />}
            {cusmptionByCustomerError && <AlertPopUp
                alertShow={cusmptionByCustomerError}
                alertType="error"
                alertMsg={cusmptionByCustomerError}
                alertValue={cusmptionByCustomerError}
                alertButtons={[{
                    name: 'Close', action: () => {
                        dispatch({
                            type: GET_FACILITY_MANAGER_CONSUMPTION_BY_CUSTOMER_FAILURE,
                            payload: null
                        })
                    }
                }]}
            />}
            {facManFacilitiesAreaError && <AlertPopUp
                alertShow={facManFacilitiesAreaError}
                alertType="error"
                alertMsg={facManFacilitiesAreaError}
                alertValue={facManFacilitiesAreaError}
                alertButtons={[{
                    name: 'Close', action: () => {
                        dispatch({
                            type: GET_FACILITY_MANAGER_FACILITIES_AREA_FAILURE,
                            payload: null
                        })
                    }
                }]}
            />}
        </>
    )
}

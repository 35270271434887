import React, { useState, useEffect, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { dashboardColors } from '../../../Config';
import DashboardLoader from './DashboardLoader';

// Register the necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function FacilityManagerDailyElectricityReport({ barChartName = 'Line Chart Name', ElectricityReportData }) {

    const generateUniqueColor = (index) => {
        const colors = dashboardColors;
        return colors[index % colors.length];
    };

    const [barChartSelectedFacility, setBarChartSelectedFacility] = useState('');
    const [processedData, setProcessedData] = useState({
        facilitiesList: [],
        billingDates: [],
        InvoicesReport: []
    });

    useEffect(() => {
        const facilities = new Set();
        const dates = new Set();
        const facilityDataMap = new Map();

        ElectricityReportData?.forEach(item => {
            if (item["facility_name"]) facilities.add(item["facility_name"]);
            if (item["billing_date"]) dates.add(item["billing_date"]);
        });

        const sortedDates = [...dates].sort((a, b) => new Date(a) - new Date(b));

        ElectricityReportData?.forEach(item => {
            if (!barChartSelectedFacility || item["facility_name"] === barChartSelectedFacility) {
                if (!facilityDataMap.has(item["facility_name"])) {
                    facilityDataMap.set(item["facility_name"], new Array(sortedDates.length).fill(0));
                }
                const dateIndex = sortedDates.indexOf(item["billing_date"]);
                if (dateIndex !== -1) {
                    facilityDataMap.get(item["facility_name"])[dateIndex] += parseFloat(item["eb_consumption"]);
                }
            }
        });

        setProcessedData({
            facilitiesList: [...facilities],
            billingDates: [...dates].sort((a, b) => new Date(a) - new Date(b)),
            InvoicesReport: Array.from(facilityDataMap.entries()).map(([name, data], index) => ({
                label: name,
                borderColor: generateUniqueColor(index),
                backgroundColor: generateUniqueColor(index),
                data,
                fill: false,
                borderWidth: 2, // Thin lines
                pointRadius: 2, // Small circles
                pointHoverRadius: 5, // Slightly larger on hover
                tension: 0.4, // Smooth curves
            })),
        });
    }, [ElectricityReportData, barChartSelectedFacility]);

    const handleFacilityChange = (facility) => {
        setBarChartSelectedFacility(facility);
    };

    const data = useMemo(() => ({
        labels: processedData.billingDates,
        datasets: processedData.InvoicesReport,
    }), [processedData]);

    const options = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'EB Consumption in (kWh)',
                },
                grid: {
                    display: false,
                },
            },
        },
    }), []);

    return (
        <div className="container" style={{ minHeight: "380px", height: "100%", width: "100%", backgroundColor: "#FFFFFF", padding: "10px" }}>
            <h5 style={{ textAlign: "start", width: "100%" }}>{barChartName}</h5>
            {ElectricityReportData ? (
                <>
                    <div className="d-flex justify-content-end gap-2 w-100">
                        <div className="">
                            <label className="form-label">
                                Select Facility:
                                <select className="form-select form-select-sm" style={{ width: "130px", textOverflow: "ellipsis" }} value={barChartSelectedFacility} onChange={(e) => handleFacilityChange(e.target.value)}>
                                    <option value="">All</option>
                                    {processedData.facilitiesList?.map((facility) => (
                                        <option key={facility} value={facility}>
                                            {facility}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "250px" }}>
                        <Line data={data} options={options} />
                    </div>
                    <div className="legend-container" style={{ overflowX: 'auto', textWrap: "nowrap", width: "500px", margin: "auto" }}>
                        {processedData.InvoicesReport.map((dataset, index) => (
                            dataset?.label && (
                                <span key={index} style={{ marginRight: '10px', display: 'inline-block' }}>
                                    <span style={{ backgroundColor: dataset.borderColor, width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></span>
                                    {dataset.label}
                                </span>
                            )
                        ))}
                    </div>
                </>
            ) : (
                <DashboardLoader />
            )}
        </div>
    );
}

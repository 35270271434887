import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { dashboardColors } from '../../../Config';
import DashboardLoader from './DashboardLoader';

// Register the necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function CommercialHeadContractsAgingBarChart({ barChartName = 'Bar Chart Name', barchartData }) {

    const [agingReportData, setAgingReportData] = useState([]);
    const [barChartFacilitiesList, setBarChartFacilitiesList] = useState([]);
    const [barChartSelectedFacility, setBarChartSelectedFacility] = useState('');

    const generateUniqueColor = (index) => {
        const colors = dashboardColors;
        return colors[index % colors.length];
    };

    useEffect(() => {
        if (!barchartData) return;
        const groupedData = barchartData.reduce((acc, item) => {
            const facility = item.facility_name;
            if (!acc[facility]) {
                acc[facility] = { facility_name: facility, Counts: { "0-30 days": 0, "31-60 days": 0, "61-180 days": 0, "181-365 days": 0, ">1 year": 0 } };
            }
            acc[facility].Counts[item.expiration_range] += item.contract_count;
            return acc;
        }, {});

        const transformedData = Object.values(groupedData);
        const data = transformedData.map((item, index) => {
            return {
                label: item.facility_name,
                data: Object.values(item.Counts),
                backgroundColor: generateUniqueColor(index),
                borderColor: generateUniqueColor(index),
            }
        });
        setAgingReportData(data);
        const facilitiesFromAgingData = barchartData.map(item => item.facility_name);
        setBarChartFacilitiesList([...new Set(facilitiesFromAgingData)]);
    }, [barchartData]);

    const handleFacilityChange = (e) => {
        setBarChartSelectedFacility(e.target.value);
        const filteredData = e.target.value ? barchartData.filter(item => item.facility_name === e.target.value) : barchartData;
        const groupedData = filteredData.reduce((acc, item) => {
            const key = e.target.value ? item.customer_name : item.facility_name;
            if (!acc[key]) {
                acc[key] = { Name: key, Counts: { "0-30 days": 0, "31-60 days": 0, "61-180 days": 0, "181-365 days": 0, ">1 year": 0 } };
            }
            acc[key].Counts[item.expiration_range] += item.contract_count;
            return acc;
        }, {});

        const transformedData = Object.values(groupedData);
        const data = transformedData.map((item, index) => {
            return {
                label: item.Name,
                data: Object.values(item.Counts),
                backgroundColor: generateUniqueColor(index),
                borderColor: generateUniqueColor(index),
            }
        });
        setAgingReportData(data);
    }

    const data = {
        labels: ["1 month", "2 months", "6 months", "1 year", ">1 year"],
        datasets: agingReportData,
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        barPercentage: 0.35,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Number of Contracts',
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    return (
        <div className="container dashboard-chart-container">
            <h6 style={{ textAlign: "start", width: "100%" }}>{barChartName}</h6>
            {barchartData ? <>
                <div className="d-flex justify-content-end gap-2 w-100">
                    <div className="">
                        <label className="form-label">
                            Select Facility:
                            <select className="form-select form-select-sm" style={{ width: "130px", textOverflow: "ellipsis" }} value={barChartSelectedFacility} onChange={handleFacilityChange}>
                                <option value="">All</option>
                                {barChartFacilitiesList?.map((facility) => (
                                    <option key={facility} value={facility}>
                                        {facility}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
                <div style={{ width: "100%", height: "250px" }}>
                    <Bar data={data} options={options} />
                </div>
                <div className="legend-container" style={{ overflowX: 'auto', textWrap: "nowrap", width: "500px", margin: "auto" }}>
                    {agingReportData.map((dataset, index) => (
                        <span key={index} style={{ marginRight: '10px', display: 'inline-block' }}>
                            <span style={{ backgroundColor: dataset.backgroundColor, width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></span>
                            {dataset.label}
                        </span>
                    ))}
                </div>
            </>
                :
                <DashboardLoader />
            }
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { dashboardColors } from '../../../Config';

// Register the necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function FinanceManagerPaymentsBarChart({ barChartName = 'Bar Chart Name', barchartData }) {

    const [agingReportData, setAgingReportData] = useState([]);
    const [barChartFacilitiesList, setBarChartFacilitiesList] = useState([]);
    const [barChartSelectedFacility, setBarChartSelectedFacility] = useState('');

    const generateUniqueColor = (index) => {
        const colors = dashboardColors;
        return colors[index % colors.length];
    };

    useEffect(() => {

        const groupedData = barchartData.reduce((acc, item) => {
            const facility = item["Facility Name"];
            if (!acc[facility]) {
                acc[facility] = { "Facility Name": facility, "Counts": [0, 0, 0, 0] };
            }
            const ageIndex = [1, 2, 6, 12].indexOf(item.AgeInMonths);
            if (ageIndex !== -1) {
                acc[facility]["Counts"][ageIndex] += item.Count;
            }
            return acc;
        }, {});

        const transformedData = Object.values(groupedData);
        const data = transformedData.map((item, index) => {
            return {
                label: item["Facility Name"],
                data: item["Counts"],
                backgroundColor: generateUniqueColor(index),
                borderColor: generateUniqueColor(index),
            }
        })
        setAgingReportData(data);
        const facilitiesFromAgingData = barchartData.map(item => item["Facility Name"]);
        setBarChartFacilitiesList([...new Set(facilitiesFromAgingData)]);
    }, [])

    const handleFacilityChange = (e) => {
        setBarChartSelectedFacility(e.target.value);
        const filteredData = e.target.value ? barchartData.filter(item => item["Facility Name"] === e.target.value) : barchartData;
        const groupedData = filteredData.reduce((acc, item) => {
            const key = e.target.value ? item["Customer Name"] : item["Facility Name"];
            if (!acc[key]) {
                acc[key] = { "Name": key, "Counts": [0, 0, 0, 0] };
            }
            const ageIndex = [1, 2, 6, 12].indexOf(item.AgeInMonths);
            if (ageIndex !== -1) {
                acc[key]["Counts"][ageIndex] += item.Count;
            }
            return acc;
        }, {});

        const transformedData = Object.values(groupedData);
        const data = transformedData.map((item, index) => {
            return {
                label: item["Name"],
                data: item["Counts"],
                backgroundColor: generateUniqueColor(index),
                borderColor: generateUniqueColor(index),
            }
        });
        setAgingReportData(data);
    }

    const getLastTwelveMonths = () => {
        const months = [];
        const date = new Date();
        for (let i = 0; i < 12; i++) {
            months.push(date.toLocaleString('default', { month: 'short' }));
            date.setMonth(date.getMonth() - 1);
        }
        return months.reverse();
    };

    const data = {
        labels: getLastTwelveMonths(),
        datasets: agingReportData,
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        barPercentage: 0.35,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Number of Payments',
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    return (
        <div className="container" style={{ height: "100%", width: "100%", backgroundColor: "#FFFFFF", padding: "10px" }}>
            <h5 style={{ textAlign: "center", width: "100%" }}>{barChartName}</h5>
            <div className="d-flex justify-content-end gap-2 w-100">
                <div className="">
                    <label className="form-label">
                        Facility:
                        <select className="form-select form-select-sm" value={barChartSelectedFacility} onChange={handleFacilityChange}>
                            <option value="">Select Facility</option>
                            {barChartFacilitiesList?.map((facility) => (
                                <option key={facility} value={facility}>
                                    {facility}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
            </div>
            <div style={{ width: "100%", height: "250px" }}>
                <Bar data={data} options={options} />
            </div>
        </div>
    );
}

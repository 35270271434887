import React, { useEffect, useRef, useState } from 'react';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import DatePicker from 'react-datepicker';

Chart.register(ArcElement, Tooltip, Legend);

const generateUniqueColor = (index) => {
    const colors = [
        '#63a0c9', '#ffa557', '#6cbd6c', '#b494d1',
        '#e36868', '#36A2EB', '#FFCE56', '#4BC0C0'
    ];
    return colors[index % colors.length];
};

const FinanceManagerInvoicesPieChart = () => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    const barChartFacilitiesList = [
        "ACLC-11",
        "ACLC-111",
    ];
    const [barChartSelectedFacility, setBarChartSelectedFacility] = useState('');
    const [biilingPeriodFilter, setBillingPeriodFilter] = useState(new Date())

    const invoiceData = [300, 50, 100];
    const totalInvoices = invoiceData.reduce((acc, value) => acc + value, 0);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const getLegendPosition = () => {
            return window.innerWidth < 768 ? 'bottom' : 'right';
        };

        chartInstance.current = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: invoiceData.map((value, index) => {
                    const labelNames = ['Approved Invoices', 'On-Hold Invoices', 'Pending Invoices'];
                    return `${labelNames[index]}: ${value}`;
                }),
                datasets: [
                    {
                        label: 'Invoices',
                        data: invoiceData,
                        backgroundColor: [0, 1, 2].map(index => generateUniqueColor(index)),
                        hoverBackgroundColor: [0, 1, 2].map(index => generateUniqueColor(index)),
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: getLegendPosition(),
                    },
                    tooltip: {
                        callbacks: {
                            label: function (tooltipItem) {
                                return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                            },
                        },
                    },
                },
            },
        });

        const handleResize = () => {
            chartInstance.current.options.plugins.legend.position = getLegendPosition();
            chartInstance.current.update();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleFacilityChange = (e) => {
        setBarChartSelectedFacility(e.target.value);
    }

    return (
        <div className='dashboard-chart-container'  >
            <h5 style={{ textAlign: "center", width: "100%" }}>Invoices</h5>
            <div className="d-flex justify-content-end gap-2 w-100">
                <div className="">
                    <label className="form-label">
                        Billing Period:
                    </label>
                    <DatePicker
                        className="admin-controls-date-picker form-control responsive-add-facility-input-fields"
                        placeholderText="Select Period"
                        selected={biilingPeriodFilter}
                        style={{ width: "20px", height: "1rem !important" }}
                        onChange={(date) => setBillingPeriodFilter(date)}
                        showMonthYearPicker
                        dateFormat="MMM yyyy"
                    // customInput={<input value={biilingPeriodFilter ? changeDateFormat() : ''} />}
                    />
                </div>
                <div className="">
                    <label className="form-label">
                        Facility:
                        <select className="form-select form-select-sm" style={{ width: "130px", textOverflow: "ellipsis" }} value={barChartSelectedFacility} onChange={handleFacilityChange}>
                            <option value="">Select Facility</option>
                            {barChartFacilitiesList?.map((facility) => (
                                <option key={facility} value={facility}>
                                    {facility}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
            </div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', height: "200px" }}>
                <canvas ref={chartRef} />
            </div>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <strong>Total Invoices: {totalInvoices}</strong>
            </div>
        </div>
    )
};

export default FinanceManagerInvoicesPieChart;
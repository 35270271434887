import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { dashboardColors } from '../../../Config';
import { numberFormatWithCommas } from '../ReusableFunctions';
import DashboardLoader from './DashboardLoader';

ChartJS.register(ArcElement, Tooltip);

export default function CommercialHeadContractSpacesDonutChart({ areaData, pieChartLabel = 'Donut Chart Label' }) {

    const [facilitiesList, setFacilitiesList] = useState([]);
    const [occupiedAreas, setOccupiedAreas] = useState([]);
    const [selectedFacility, setSelectedFacility] = useState('');

    useEffect(() => {
        const facilities = areaData?.map(item => item["facility_name"]);
        setFacilitiesList([...new Set(facilities)]);
        setSelectedFacility(facilities?.length > 0 && facilities[0]);
        const initialFilteredAreas = areaData?.filter(item => item["facility_name"] === facilities?.length > 0 && facilities[0]);
        setOccupiedAreas(initialFilteredAreas?.map(item => ({ label: item["customer_name"], area: item["total_recent_area"] })));
    }, [areaData]);

    const handleChangeFacility = (e) => {
        setSelectedFacility(e.target.value);
        const filteredAreas = areaData.filter(item => item["facility_name"] === e.target.value);
        setOccupiedAreas(filteredAreas.map(item => ({ label: item["customer_name"], area: item["total_recent_area"] })));
    };

    const selectedFacilityData = areaData?.find(data => data["facility_name"] === selectedFacility);
    const totalArea = selectedFacilityData ? parseFloat(selectedFacilityData["facility_total_area"]) : 0;

    const occupiedAreaSum = areaData?.filter(data => data["facility_name"] === selectedFacility)?.reduce((sum, contract) => sum + parseFloat(contract["total_recent_area"]), 0);
    const remainingArea = totalArea - occupiedAreaSum;

    const generateColorsByIndex = (numColors) => {
        const premiumColors = dashboardColors;
        const colors = [];
        for (let i = 0; i < numColors; i++) {
            colors.push(premiumColors[i % premiumColors.length]);
        }
        return colors;
    };

    const filteredAreaData = areaData?.filter(data => data["facility_name"] === selectedFacility);

    const colors = generateColorsByIndex(filteredAreaData?.length + 1);

    const data = {
        labels: [...(filteredAreaData || []).map(contract => contract["customer_name"]), 'Unoccupied Area'],
        datasets: [
            {
                label: 'Area Occupied',
                data: [...(filteredAreaData || []).map(contract => parseFloat(contract["total_recent_area"])), remainingArea],
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    afterBody: function (tooltipItems) {
                        return `Total Area: ${numberFormatWithCommas(totalArea)}`;
                    }
                }
            },
        },
        cutout: '50%', // This makes the chart a donut chart
    };

    const totalAreaPlugin = {
        id: 'totalAreaPlugin',
        afterDraw: (chart) => {
            const { ctx, chartArea: { left, right, bottom } } = chart;
            ctx.save();
            ctx.font = '16px Arial';
            ctx.textAlign = 'center';
            ctx.fillText(`Total Area: ${totalArea}`, (left + right) / 2, bottom + 20);
            ctx.restore();
        },
    };

    const CustomLegend = ({ labels, colors }) => (
        <div style={{ maxHeight: '200px', width: "250px", overflowY: 'auto', padding: '10px', border: '1px solid #ddd', borderRadius: '4px' }}>
            {labels?.labels.map((label, index) => (
                <>
                    <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', width: "70%" }}>
                            <div style={{ width: '10px', height: '10px', backgroundColor: colors[index], marginRight: '5px' }}></div>
                            <span style={{ fontSize: '10px' }}>{label}</span>
                        </div>
                        <span style={{ fontSize: '10px', width: "30%" }}>{numberFormatWithCommas(data.datasets[0].data[index])} sq ft</span>
                    </div>
                    <hr style={{ margin: "5px 0px" }}></hr>
                </>
            ))}
        </div>
    );

    return (
        <div className='dashboard-chart-container' >
            <h6 style={{ textAlign: "start" }}>{pieChartLabel}</h6>
            {areaData ? (
                <>
                    <div className='d-flex gap-2 justify-content-end my-3'>
                        <div>
                            <label htmlFor="facilitySelect" className="form-label">Select Facility</label>
                            <select id="facilitySelect" className="form-select form-select-sm" style={{ width: "130px", textOverflow: "ellipsis" }} value={selectedFacility} onChange={handleChangeFacility}>
                                {facilitiesList?.map(facility => (
                                    <option key={facility} value={facility}>{facility}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: "50%" }}>
                            <Doughnut data={data} options={options} plugins={[totalAreaPlugin]} />
                        </div>
                        <CustomLegend labels={data} colors={colors} />
                    </div>
                    <div className='d-flex justify-content-center mt-5'>
                        <h6>Total Area: {numberFormatWithCommas(totalArea)}</h6>
                    </div>
                </>
            ) : (
                <DashboardLoader />
            )}
        </div>
    );
}

import React from "react";

const ToggleSwitch = ({ selected, setSelected, className }) => {
    return (
        <div className={`d-flex ${className}`}>
            <div
                className={`toggle-switch-option ${selected === "monthly" ? "selected" : ""}`}
                onClick={() => setSelected("monthly")}
            >
                Month
            </div>
            <div
                className={`toggle-switch-option ${selected === "day" ? "selected" : ""}`}
                onClick={() => setSelected("day")}
            >
                Day
            </div>
        </div>
    );
};

export default ToggleSwitch;
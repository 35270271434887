import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Element from './Element'
import { RxCross2 } from 'react-icons/rx'
import { LiaEdit } from "react-icons/lia";
import { v4 as uuidv4 } from 'uuid';
import { Button, Modal } from 'react-bootstrap';
import { MdDelete, MdErrorOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { FETCHING_ERROR, getAllForms } from '../../Redux/Actions/Actions';
import FormLoader from '../Reusable_Components/FromLoader';
import { sorting_lists, timeElapsed } from '../Reusable_Components/ReusableFunctions';
import AlertPopUp from '../Reusable_Components/Alert';
import TableComponent from '../Reusable_Components/TableComponent';
import Search from '../Reusable_Components/Search';

export default function FormsList({ handleSetActiveTab }) {

    const dispatch = useDispatch()
    const formsList = useSelector((state) => state.formsList)
    const loading = useSelector((state) => state.loading)
    const fetchingError = useSelector((state) => state.fetchingError)

    const [forms, setForms] = useState([])
    const [preview, setPreview] = useState(false)
    const [previewData, setPreviewData] = useState({})
    const [formData, setFormData] = useState({})
    const [useForm, setUseForm] = useState(false)
    const [formId, setFormId] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [currentTime, setCurrentTime] = useState(new Date())
    const [footerValues, setFooterValues] = useState({})
    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        // let forms = JSON.parse(localStorage.getItem("Forms"))
        // forms = forms && forms?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
        // forms && setForms(forms)

        dispatch(getAllForms())
    }, [])

    const handleFormData = (key, value) => {
        let newFormData = formData
        newFormData[key] = value
        setFormData({ ...newFormData })
    }

    const tableData = (key, row, tableName, value) => {
        let newFormData = { ...formData }
        let tableData = newFormData[tableName] ? newFormData[tableName] : []
        tableData[row] = { ...tableData[row], [key]: value }
        // tableData[row][key] = value
        newFormData[tableName] = tableData

        setFormData(newFormData)
    }

    const handleSaveUsedForm = () => {
        let obj = {
            ...formId,
            entryId: uuidv4(),
            formData: formData,
            time: new Date()
        }
        const usedForms = JSON.parse(localStorage.getItem("Used Forms"))
        const newItems = usedForms ? [...usedForms, obj] : [obj]
        localStorage.setItem('Used Forms', JSON.stringify(newItems));
        setUseForm(false)
        setFormData({})
        setFormId({})
        handleSetActiveTab("Filled Forms")
        setShowModal(false)
    }

    const handleDeleteElement = (id) => {
        const forms = JSON.parse(localStorage.getItem("Forms"))
        const filteredFields = forms.filter((form) => form.id !== id)
        setForms(filteredFields)
        localStorage.setItem("Forms", JSON.stringify(filteredFields))
        // setShowModal(false)
    }

    useEffect(() => {
        setForms(sorting_lists(formsList))
    }, [formsList])

    const handleEnable = (each) => {
        return false;
    }
    return (
        <div>
            <div className='d-flex justify-content-between'>
                <h3>Forms</h3>
                <div className='d-flex align-items-center gap-2 my-2'>
                    <Search
                        value={searchText}
                        handleSearch={(e) => {
                            setSearchText(e.target.value)
                            const filteredData = formsList.filter((form) => form.form_name.toLowerCase().includes(e.target.value.toLowerCase()))
                            setForms(filteredData)
                        }
                        }
                    />
                    <Link to={'/form/forms'}>
                        <button className='btn btn-secondary'>Create new form</button>
                    </Link>
                </div>
            </div>
            <TableComponent
                headers={[
                    {
                        name: "Form Name",
                        type: "text",
                        key: "form_name"
                    }, {
                        name: "Form Type",
                        type: "text",
                        key: "form_type"
                    }, {
                        name: "Description",
                        type: "text",
                        key: "description"
                    }, {
                        name: "Created By",
                        type: "text",
                        key: "created_by",
                    }, {
                        name: "Created",
                        type: "time_stamp",
                        key: "created_at",
                        sort: true
                    },
                    {
                        name: "Action",
                        type: "button",
                        buttons: [{
                            name: "View",
                            icon: "./images/icons/ViewViewIconEye.svg",
                            disable: handleEnable,
                            action: (data) => {
                                setPreview(true)
                                setPreviewData({ items: JSON.parse(data?.json_data) })
                                setFooterValues({
                                    createdAt: data?.created_at,
                                    createdBy: data.created_by,
                                })
                            }
                        }]
                    }
                ]}
                data={forms}
            />
            {preview &&
                <div className='form_preview_container'>
                    <div className='form_view'>
                        <RxCross2 className='close-form' onClick={() => {
                            setPreview(false)
                        }} />
                        {previewData?.items?.map((previewObj, index) => {
                            return <div key={previewObj.id} id={previewObj.id + " " + index} className='d-flex'>
                                {previewObj.items.map((item, index) => {
                                    return <Element preview={true} key={index} object={item} />
                                })}
                            </div>
                        })}
                        <div className='d-flex justify-content-between flex-wrap mt-3 p-2' style={{ fontSize: "10px", borderTop: "1px solid gray" }}>
                            <span><b>Created :</b> {footerValues?.createdAt?.split("T")[0] + "  " + new Date(footerValues?.createdAt).toLocaleTimeString()}</span>
                            {/* <span><b>Last Updated at :</b> {footerValues?.updatedAt?.split("T")[0] + "  " + new Date(footerValues?.createdAt).toLocaleTimeString()}</span> */}
                            <span><b>Created by :</b> {footerValues?.createdBy}</span>
                            {/* <span><b>Last updated by :</b> {footerValues?.updatedBy}</span> */}
                        </div>
                    </div>
                </div>
            }
            {useForm &&
                <div className='form_preview_container'>
                    <div className='form_view'>
                        <RxCross2 className='close-form' onClick={() => {
                            setUseForm(false)
                            setFormData({})
                        }} />
                        {previewData?.items?.map((previewObj, index) => {
                            return <div key={previewObj?.id} id={previewObj?.id + " " + index} className='d-flex'>
                                {previewObj?.items?.map((item, i) => {
                                    return <Element preview={true} key={i} object={item} handleFormData={handleFormData} rowIndex={index} colIndex={i} tableData={tableData} formUse={true} />
                                })}
                            </div>
                        })}
                        {/* <div className='d-flex justify-content-between mt-3'>
                            <label>Created at : {footerValues?.createdAt?.split("T")[0]}</label>
                            <label>Last Updated at : {footerValues?.updatedAt?.split("T")[0]}</label>
                            <label>Created by : {footerValues?.createdBy}</label>
                            <label>Last updated by : {footerValues?.updatedBy}</label>
                        </div> */}
                    </div>
                    <div style={{ backgroundColor: "white", height: "90%", width: "25%" }}>
                        <div style={{ height: "90%", overflow: "auto" }}>
                            {
                                formData && <pre style={{ textWrap: "wrap" }}>{JSON.stringify(formData, null, 2)}</pre>
                            }
                        </div>
                        <div style={{ height: "10%" }} className='d-flex justify-content-end align-items-center p-2'>
                            <button className='btn btn-secondary' onClick={() => setShowModal(true)}>Save</button>
                        </div>
                    </div>
                </div>
            }
            <Modal keyboard={false} backdrop="static" show={showModal} centered onHide={() => setShowModal(false)} style={{ zIndex: "100000000000", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                <Modal.Body>Are you sure you have entered all the fields</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleSaveUsedForm}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            {loading && <FormLoader />}
            {fetchingError && <AlertPopUp
                alertShow={fetchingError}
                alertType="error"
                alertMsg="Error"
                alertValue={fetchingError}
                alertButtons={[{ name: "Close", action: () => { dispatch({ type: FETCHING_ERROR, payload: null }) } }, {
                    name: "Retry", action: () => {
                        dispatch(getAllForms())
                        dispatch({
                            type: FETCHING_ERROR,
                            payload: null
                        })
                    }
                }]}
            />}
        </div>
    )
}

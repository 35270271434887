import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
    AUTHENTICATE_USER_FAILURE, AUTHENTICATE_USER_SUCCESS, SET_LOADER,
    commercialHeadGetAllFacilities,
    commercialHeadGetAllFacilitiesForNav
} from "../../Redux/Actions/Actions";
import FormLoader from "../Reusable_Components/FromLoader";
import axios from "axios";
import { CEO_GROUP, COMMERCIAL_HEAD_GROUP, FACILITY_MANAGER_GROUP, FINANCE_MANAGER_GROUP, TECHNICIAN_GROUP } from "../../Config";
import { base_url } from "../../utilities";
import { IoInformationCircleSharp } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import { MdErrorOutline } from "react-icons/md";
import { set } from "date-fns";
import { eleminateAllSpaces, encrypt } from "../Reusable_Components/ReusableFunctions";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { COMPANY_NAME } from "../../Config";



const LoginPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()


    const loading = useSelector(state => state.loading)
    const authUserSuccess = useSelector(state => state.authUserSuccess)
    const authUserError = useSelector(state => state.authUserError)
    const sendOTPSuccess = useSelector(state => state.sendOTPSuccess)
    const sendOTPFailure = useSelector(state => state.sendOTPFailure)


    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
    const [forgotPasswordOtp, setForgotPasswordOtp] = useState(false)
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
    const [otp, setOTP] = useState(['', '', '', '', '', '']); // Initialize with 4 empty strings
    const [mail, setMail] = useState('');
    const [timeLeft, setTimeLeft] = useState(180); // 3 minutes in seconds
    const [timerActive, setTimerActive] = useState(false);
    const [usernameError, setUsernameError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [termsandConditions, setTermsandConditions] = useState(false)
    const [termsandConditionsError, setTermsandConditionsError] = useState("")
    const [forgotPasswordUsernameError, setForgotPasswordUsernameError] = useState("")
    const [isAnimating, setIsAnimating] = useState(false);
    const [isValidOTP, setIsValidOTP] = useState(null)
    const inputRefs = useRef(otp.map(() => React.createRef())); // Create refs for each input field
    const [lengthError, setLengthError] = useState(true);
    const [uppercaseError, setUppercaseError] = useState(true);
    const [lowercaseError, setLowercaseError] = useState(true);
    const [numberError, setNumberError] = useState(true);
    const [specialCharacterError, setSpecialCharacterError] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetPasswordError, setResetPasswordError] = useState('');
    const [info, setInfo] = useState(false)
    const [successModal, setSuccessModal] = useState(null)
    const [submitPasswordError, setSubmitPasswordError] = useState(null);

    const [showPolicy, setShowPolicy] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);

    // useEffect(() => {
    //     encrypt("DineshDineshDinesh")
    //     encrypt("dinesh_dunna")
    // }, [])

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible); // Toggle password visibility
    };

    const handleChange = (index, event) => {
        const value = event.target.value;
        if (isNaN(value)) return; // Only allow numeric input

        const newOTP = [...otp];
        newOTP[index] = value;
        setOTP(newOTP);

        if (value !== '' && index < inputRefs.current.length - 1) {
            inputRefs?.current[index + 1]?.current?.focus(); // Move focus to the next input field
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace') {
            const newOTP = [...otp];
            if (otp[index] === "") {
                if (index > 0) {
                    inputRefs.current[index - 1].current.focus(); // Move focus to the previous input field if backspace is pressed
                }
            } else {
                newOTP[index] = "";
                setOTP(newOTP);
            }
        } else if ((event.key === 'Enter' && index === otp.length - 1) || (event.key === 'Enter' && otp.join('').length === 6)) {
            handleVerifyOTP()
        }
    };

    const handlePaste = event => {
        event.preventDefault();
        const paste = event.clipboardData.getData('text/plain');
        if (/^\d+$/.test(paste) && paste.length <= 6) {
            const newOTP = [...otp];
            for (let i = 0; i < paste.length; i++) {
                if (i < newOTP.length) {
                    newOTP[i] = paste[i];
                }
            }
            setOTP(newOTP);
        }
    };

    const startTimer = () => {
        setTimeLeft(30); // Reset to 3 minutes
        setTimerActive(true);
    };

    const handleAnimationEnd = () => {
        setIsAnimating(false);
    };

    useEffect(() => {
        if (timerActive) {
            const timer = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(timer);
                        setTimerActive(false);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);

            return () => clearInterval(timer); // Cleanup timer on component unmount
        }
    }, [timerActive]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const handleUsername = (e) => {
        setUsername(e.target.value)
        if (e.target.value === '') {
            setUsernameError('Username is required')
        } else if (e.target.value.length < 3) {
            setUsernameError('Username must be at least 3 characters long');
        } else if (/\s/.test(e.target.value)) {
            setUsernameError('Username cannot contain spaces');
        } else if (/[A-Z]/.test(e.target.value)) {
            setUsernameError('Username must be in lowercase');
        } else {
            setUsernameError('')
        }
    }

    const handlePassword = (e) => {
        if (e.target.value.length === 0) {
            setPasswordError("Password is required")
        } else {
            setPasswordError("");
            setPassword(e.target.value)
        }
    }

    const getGroupName = (group) => {
        switch (group) {
            case COMMERCIAL_HEAD_GROUP:
                return "Commercial Head";
            case TECHNICIAN_GROUP:
                return "Technician";
            case FINANCE_MANAGER_GROUP:
                return "Finance Manager";
            case FACILITY_MANAGER_GROUP:
                return "Facility Manager";
            case CEO_GROUP:
                return "CEO";
            default:
                return "";
        }
    }

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
        if (e.target.value.length < 8) {
            setLengthError(true);
        } else {
            setLengthError(false);
        }

        if (!/[A-Z]/.test(e.target.value)) {
            setUppercaseError(true);
        } else {
            setUppercaseError(false);
        }

        if (!/[a-z]/.test(e.target.value)) {
            setLowercaseError(true);
        } else {
            setLowercaseError(false);
        }

        if (!/[0-9]/.test(e.target.value)) {
            setNumberError(true);
        } else {
            setNumberError(false);
        }

        if (!/[!@#$%^&*]/.test(e.target.value)) {
            setSpecialCharacterError(true);
        } else {
            setSpecialCharacterError(false);
        }

        if (e.target.value !== confirmPassword) {
            setResetPasswordError('Password does not match');
        } else {
            setResetPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (newPassword !== e.target.value) {
            setResetPasswordError('Password does not match');
        } else {
            setResetPasswordError('');
        }
    };

    const handleCheckbox = (e) => {
        setTermsandConditions(e.target.checked);
        setTermsandConditionsError('')
    }

    const handleSubmit = () => {
        if (username === "" || password === "" || termsandConditions === false) {
            if (username === "") {
                setUsernameError("Username is required")
            }
            if (password === "") {
                setPasswordError("Password is required")
            }
            if (termsandConditions === false) {
                setTermsandConditionsError("Please accept the terms and conditions")
            }

        } else {
            dispatch({
                type: SET_LOADER,
                payload: true
            })
            axios.post(base_url + "authorization/validateUser", encrypt({ username, password }))
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(commercialHeadGetAllFacilitiesForNav());
                        localStorage.setItem("isloggedin", true)
                        localStorage.setItem("group", getGroupName(res.data.data.group))
                        localStorage.setItem("access_token", res.data.data.tokens.AccessToken)
                        localStorage.setItem("refresh_token", res.data.data.tokens.RefreshToken)
                        localStorage.setItem("name", res.data.data.name)
                        localStorage.setItem("email", res.data.data.email)
                        localStorage.setItem("username", res.data.data.username)
                        localStorage.setItem("phone_number", res.data.data.phone_number)
                        localStorage.setItem("selected_city_name", "")
                        localStorage.setItem("selected_facility_id", "")
                        localStorage.setItem("selected_facility_name", "")
                        dispatch({
                            type: SET_LOADER,
                            payload: false
                        })
                        const groupName = res.data.data.group;
                        if (groupName === COMMERCIAL_HEAD_GROUP) {
                            navigate('/commercialHeadDashboard', { replace: true });
                            window.location.reload()
                        } else if (groupName === TECHNICIAN_GROUP) {
                            navigate('/technicianDashboard', { replace: true });
                            window.location.reload()

                        } else if (groupName === FINANCE_MANAGER_GROUP) {
                            navigate('/financeManagerDashboard', { replace: true });
                            window.location.reload()

                        } else if (groupName === FACILITY_MANAGER_GROUP) {
                            navigate('/facilityManagerDashboard', { replace: true });
                            window.location.reload()

                        } else if (groupName === CEO_GROUP) {
                            navigate('/users', { replace: true });
                            window.location.reload()

                        } else {
                            navigate('/');
                            window.location.reload()

                        }
                    }
                }).catch((err) => {
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                    dispatch({
                        type: AUTHENTICATE_USER_FAILURE,
                        payload: err.response.data.message
                    })
                })
        }
    }

    const isLoginDisabled = username === '' || password === '' || !termsandConditions || usernameError || passwordError || authUserError;

    // usernameError || termsandConditionsError || passwordError || authUserError ||

    const handleSendOtp = () => {
        if (mail === "") {
            setForgotPasswordUsernameError("Username is required")
        } else {
            setOTP(['', '', '', '', '', ''])
            dispatch({
                type: SET_LOADER,
                payload: true
            })
            axios.post(base_url + `authorization/setOTP`, encrypt({ username: mail }))
                .then((res) => {
                    if (res.status === 200) {
                        dispatch({
                            type: SET_LOADER,
                            payload: false
                        })
                        setShowForgotPasswordModal(false)
                        setForgotPasswordOtp(true)
                        startTimer(true)
                        setTimeLeft(30)
                    }
                })
                .catch((err) => {
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                    forgotPasswordOtp ? setIsValidOTP(err.response.data.message) : setForgotPasswordUsernameError(err.response.data.message)
                })
        }
    }

    const handleVerifyOTP = () => {
        if (otp.join('').length < 6) {
            setIsValidOTP("Please enter the OTP")
        }
        else {
            dispatch({
                type: SET_LOADER,
                payload: true
            })
            let otpValue = otp.join('')
            axios.post(base_url + `authorization/validateOTP`, encrypt({ username: mail, otp: otpValue }))
                .then((res) => {
                    if (res.status === 200) {
                        dispatch({
                            type: SET_LOADER,
                            payload: false
                        })
                        setForgotPasswordOtp(false)
                        setShowForgotPasswordModal(false)
                        setShowResetPasswordModal(true)
                    }
                })
                .catch((err) => {
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                    if (err.response.data.message === "You have exceeded the maximum number of retries") {
                        setTimerActive(false)
                        setTimeLeft(0)
                    }
                    setIsValidOTP(err.response.data.message)
                })
        }
    }

    const handleSubmitResetaPassword = () => {
        if (lengthError || uppercaseError || lowercaseError || numberError || specialCharacterError || resetPasswordError !== "" || newPassword === "" || confirmPassword === "") {
            setResetPasswordError("Please enter a valid password")
        } else {
            dispatch({
                type: SET_LOADER,
                payload: true
            })
            axios.post(base_url + `authorization/resetPassword`, encrypt({ username: mail, password: newPassword }))
                .then((res) => {
                    if (res.status === 200) {
                        setShowResetPasswordModal(false)
                        setSuccessModal(res.data.data.message)
                    }
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                    setShowResetPasswordModal(false)
                    setUsername(mail)
                    setMail('')
                    setOTP(['', '', '', '', '', ''])
                    setNewPassword('')
                    setConfirmPassword('')
                    setResetPasswordError('')
                    setLengthError(true)
                    setUppercaseError(true)
                    setLowercaseError(true)
                    setNumberError(true)
                    setSpecialCharacterError(true)
                })
                .catch((err) => {
                    setShowResetPasswordModal(false)
                    setSubmitPasswordError(err.response.data.message)
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                })

        }

    }

    return (
        <div className="login-container">
            {loading && <FormLoader />}
            <img src='./images/icons/loginimage.jpg' className="login-image" alt="login-image" />
            <div className="login-div">
                <div className="login-div-sm">
                    <h1 className="login-heading">{COMPANY_NAME}</h1>
                    {authUserError && <span className="text-danger">{authUserError && authUserError}</span>}
                    {termsandConditionsError && <span className="text-danger" style={{ textAlign: "center" }}>{termsandConditionsError && termsandConditionsError}</span>}
                    <div className="login-input-div">
                        <div className="login-input" style={{ paddingRight: "10px" }}>
                            <img src='./images/icons/user.svg' className="login-icon" alt="login-image" />
                            <input placeholder="Enter Username" onChange={handleUsername} onFocus={() => {
                                dispatch({
                                    type: AUTHENTICATE_USER_FAILURE,
                                    payload: null
                                })
                                setTermsandConditionsError("")
                            }
                            } />
                            <div style={{ position: "relative" }}>
                                <IoInformationCircleSharp fontSize={20} color="gray" onClick={() => setInfo(!info)} onMouseOver={() => setInfo(true)}
                                    onMouseLeave={() => setInfo(false)}
                                />
                                {info && (
                                    <div style={{
                                        position: 'absolute',
                                        right: '0px',
                                        border: '1px solid #ccc',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '5px',
                                        marginTop: '2px',
                                        fontSize: '10px',
                                        textWrap: 'nowrap',
                                    }}>
                                        Check your email for username
                                    </div>
                                )}
                            </div>
                        </div>
                        {usernameError && <span className="error">{usernameError}</span>}
                        <div className="login-input">
                            <img src='./images/icons/Lock.svg' className="login-icon lock" alt="login-image" />
                            <input type={passwordVisible ? "text" : "password"}
                                placeholder="Enter Password"
                                onKeyDown={eleminateAllSpaces} onChange={handlePassword}
                                onFocus={() => dispatch({
                                    type: AUTHENTICATE_USER_FAILURE,
                                    payload: null
                                })} />
                            <span className="toggle-password-visibility" onClick={togglePasswordVisibility}
                                style={{ marginTop: '20px', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                {!passwordVisible ? <IoEyeOffOutline style={{ marginRight: '10px', marginLeft: '2px' }} size={20} /> : <IoEyeOutline style={{ marginRight: '10px', marginLeft: '2px' }} size={20} />}
                            </span>
                        </div>
                        {passwordError && <span className="error">{passwordError}</span>}
                        <p onClick={() => {
                            setShowForgotPasswordModal(true)
                        }} className="forgot-password C-pointer">Forgot password?</p>
                    </div>
                    <p className="login-terms">
                        <input type="checkbox" checked={termsandConditions} onChange={handleCheckbox} /> {''}
                        I agree to the{''} <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                            Privacy Policy</a> {''}
                        and
                        <a href="/terms-and-conditions" target="_blank" >Terms & Conditions</a>
                    </p>
                    <button
                        disabled={isLoginDisabled}
                        className={`login-button ${isAnimating ? 'click-animation' : ''}`}
                        onClick={() => {
                            handleSubmit()
                            setIsAnimating(true)
                        }}
                        onAnimationEnd={handleAnimationEnd}
                    >
                        LOGIN
                    </button>

                </div>
            </div>
            <Modal keyboard={false} backdrop="static" show={showForgotPasswordModal} centered onHide={() => {
                setShowForgotPasswordModal(false)
                setMail('')
                setForgotPasswordUsernameError('')
            }}>
                <Modal.Header>
                    <Modal.Title><div>
                        <h3>Reset Password </h3>
                        <div style={{ fontSize: "14px", color: "gray" }}>Verification code will be sent to your registered email ID</div>
                    </div></Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column justify-content-center align-items-center gap-3 w-100">
                    <div className="login-input" style={{ border: `${forgotPasswordUsernameError?.length > 0 ? "1px solid red" : "1px solid lightgray"}`, marginTop: "0px" }}>
                        <img src='./images/icons/user.svg' className="login-icon" alt="login-image" />
                        <input type="text"
                            placeholder="Enter your username"
                            value={mail}
                            onFocus={() => setForgotPasswordUsernameError("")}
                            onChange={(e) => {
                                setMail(e.target.value)
                                setForgotPasswordUsernameError("")
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSendOtp()
                                }
                            }}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <span className="text-danger" style={{ textAlign: "left", width: "80%", fontSize: "12px" }}>{forgotPasswordUsernameError && forgotPasswordUsernameError?.length > 0 && forgotPasswordUsernameError}</span>
                </Modal.Body>
                <Modal.Footer className="footer">
                    <Button variant="secondary" className="secondary-left" onClick={() => {
                        setShowForgotPasswordModal(false)
                        setMail('')
                        setOTP(['', '', '', '', '', ''])
                        setForgotPasswordUsernameError('')
                    }}>Back</Button>
                    <Button className="secondary" variant="secondary" onClick={handleSendOtp}>Send</Button>
                </Modal.Footer>
            </Modal>
            <Modal keyboard={false} backdrop="static" show={forgotPasswordOtp} centered onHide={() => {
                setForgotPasswordOtp(false)
                setShowForgotPasswordModal(true)
                setIsValidOTP('')
            }} >
                <Modal.Header>
                    <Modal.Title><div>
                        Enter OTP
                        <div style={{ fontSize: "14px", color: "gray" }}>OTP has been sent to your mail</div>
                    </div></Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column justify-content-center align-items-center gap-3 w-60">
                    <span className="text-danger" style={{ textAlign: "left", width: "80%", fontSize: "12px" }}>{isValidOTP && isValidOTP?.length > 0 && isValidOTP}</span>
                    <div className="d-flex gap-2">
                        {otp.map((digit, index) => (
                            <input
                                style={{ width: "50px", height: "50px", fontSize: "20px", textAlign: "center", borderRadius: "5px", border: "1px solid gray" }}
                                key={index}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={e => handleChange(index, e)}
                                onKeyDown={e => handleKeyDown(index, e)}
                                onFocus={(e) => {
                                    e.target.select()
                                    setIsValidOTP(null)
                                }}
                                onPaste={handlePaste}
                                ref={inputRefs.current[index]}
                            />
                        ))}
                    </div>
                    {timerActive && <div>Resend OTP in : {formatTime(timeLeft)}</div>}
                    {!timerActive && <span className="">Send another OTP? {timeLeft <= 0 ? <Link className="C-pointer" onClick={handleSendOtp}>Resend</Link> : <span title={`Resend in ${timeLeft}`} className="C-pointer" style={{ color: "gray" }}>Resend</span>}</span>}
                </Modal.Body>
                <Modal.Footer className="footer">
                    <Button variant="secondary" className="secondary-left" onClick={() => {
                        setForgotPasswordOtp(false)
                        setShowForgotPasswordModal(true)
                        setOTP(['', '', '', '', '', ''])
                        setIsValidOTP('')
                    }}>Back</Button>
                    <Button variant="secondary" className="secondary" onClick={handleVerifyOTP}>Verify</Button>
                </Modal.Footer>
            </Modal>
            <Modal keyboard={false} backdrop="static" show={showResetPasswordModal} centered>
                <Modal.Header>
                    <Modal.Title><div>
                        Reset Password
                        <div style={{ fontSize: "14px", color: "gray" }}>Enter your new password</div>
                    </div></Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center align-items-center gap-3">
                    <div className="d-flex flex-column gap-2">
                        <div>
                            <label>New Password</label>
                            <input type="password" value={newPassword} onKeyDown={eleminateAllSpaces} className="form-control" onChange={handlePasswordChange} />
                        </div>
                        <div>
                            <label>Confirm Password</label>
                            <input type="password" value={confirmPassword} onKeyDown={eleminateAllSpaces} className="form-control" onChange={handleConfirmPasswordChange} />
                            <span className="text-danger">{resetPasswordError}</span>
                        </div>
                    </div>
                    <div>
                        <h5>Password must contain:</h5>
                        <ul style={{ listStyleType: "none", marginLeft: "-20px" }}>
                            {lengthError ? <li style={{ color: "red" }}><IoIosClose style={{ marginRight: "5px" }} /> At least 8 characters</li> : <li style={{ color: "green" }}><IoMdCheckmark style={{ marginRight: "5px" }} />At least 8 characters</li>}
                            {uppercaseError ? <li style={{ color: "red" }}><IoIosClose style={{ marginRight: "5px" }} /> At least 1 uppercase letter</li> : <li style={{ color: "green" }}><IoMdCheckmark style={{ marginRight: "5px" }} />At least 1 uppercase letter</li>}
                            {lowercaseError ? <li style={{ color: "red" }}><IoIosClose style={{ marginRight: "5px" }} /> At least 1 lowercase letter</li> : <li style={{ color: "green" }}><IoMdCheckmark style={{ marginRight: "5px" }} /> At least 1 lowercase letter</li>}
                            {numberError ? <li style={{ color: "red" }}><IoIosClose style={{ marginRight: "5px" }} /> At least 1 number</li> : <li style={{ color: "green" }}><IoMdCheckmark style={{ marginRight: "5px" }} /> At least 1 number</li>}
                            {specialCharacterError ? <li style={{ color: "red" }}><IoIosClose style={{ marginRight: "5px" }} /> At least 1 special character</li> : <li style={{ color: "green" }}><IoMdCheckmark style={{ marginRight: "5px" }} /> At least 1 special character</li>}
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" className="secondary" onClick={() => handleSubmitResetaPassword()} disabled={
                        lengthError || uppercaseError || lowercaseError || numberError || specialCharacterError || resetPasswordError !== "" || newPassword === "" || confirmPassword === ""
                    }>Submit</Button>
                </Modal.Footer>
            </Modal>
            {successModal && <Modal keyboard={false} backdrop="static" show={successModal} onHide={() => {
                setSuccessModal(null)
                navigate("/")
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <img src="./images/icons/popper.svg" alt="Party Popper" />
                    <h5>Success!</h5>
                    <p className="mb-3">{successModal}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        setSuccessModal(null)
                        navigate("/")
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {submitPasswordError && <Modal keyboard={false} backdrop="static" show={submitPasswordError} onHide={() => {
                setSubmitPasswordError(null)
                setShowResetPasswordModal(true)
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                    <h5>Error!</h5>
                    <p className="mb-3">{submitPasswordError}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        setSubmitPasswordError(null)
                        setShowResetPasswordModal(true)
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
        </div>
    )
}

export default LoginPage

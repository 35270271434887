import React, { useEffect, useState } from 'react'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import TableComponent from '../Reusable_Components/TableComponent'
import axios from '../../axios-interceptor'
import { base_url } from '../../utilities'
import Dropdown from '../Reusable_Components/Dropdown'
import Pagination from '../Reusable_Components/Pagination'
import { getAllServices } from '../../Redux/Actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import FormLoader from '../Reusable_Components/FromLoader';
import { useNavigate } from 'react-router-dom';
import { RECORDS_PER_PAGE } from '../../Config';
import AlertPopUp from '../Reusable_Components/Alert';
import { getMonthName } from '../Reusable_Components/ReusableFunctions'

export default function FinanceManagerGenerateInvoices() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const servicesList = useSelector(state => state.servicesList)

    const [loader, setLoader] = useState(false)

    const [jobRunHistory, setJobRunHistory] = useState([])
    const [jobRunDetail, setJobRunDetail] = useState([])
    const [selectedService, setSelectedService] = useState('Select Invoice Type')
    const [selectedAction, setSelectedAction] = useState('Select Action')
    const [billingPeriodFilter, setBillingPeriodFilter] = useState(new Date())
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [totalRecords, setTotalRecords] = useState(0)
    const [error, setError] = useState(null)
    const [selectedJobRunDetailsInformation, setSelectedJobRunDetailsInformation] = useState(null)

    const handledPaginationNextClicked = () => {
        setCurrentPageNumber(currentPageNumber + 1)
    }

    const handledPaginationPrevClicked = () => {
        setCurrentPageNumber(currentPageNumber - 1)
    }

    const fetchJobRun = async () => {
        setLoader(true)
        axios.get(base_url + 'invoices/jobRun?page_number=' + currentPageNumber + '&items_per_page=' + RECORDS_PER_PAGE)
            .then(res => {
                setJobRunHistory(res?.data?.data)
                fetchJobRunLogs(res.data.data[0].job_run_id)
                setTotalRecords(res?.data?.info?.total_no_of_records)
                setLoader(false)
            })
            .catch(err => {
                console.log(err)
                setLoader(false)
                setError(err.response.data.message)
            })
    }

    const fetchJobRunLogs = async (job_run_id) => {
        setLoader(true)
        axios.get(base_url + `invoices/jobRunLog?request_type=dropdown&filter_condition=%7B%22job_run_id%22%3A%22${job_run_id}%22%7D`)
            .then(res => {
                setLoader(false)
                setJobRunDetail(res?.data?.data)
            })
            .catch(err => {
                setLoader(false)
                setError(err.response.data.message)
                console.log(err)
            })
    }

    useEffect(() => {
        dispatch(getAllServices())
        fetchJobRun()
    }, [currentPageNumber])

    const handleInvoiceGeneration = async () => {
        setLoader(true)
        const data = {
            job_name: `${selectedService.toUpperCase()} Invoices`,
            service_code: selectedService,
            stage: selectedAction,
            invoice_month: billingPeriodFilter.getMonth() + 1,
            invoice_year: billingPeriodFilter.getFullYear(),
        }
        axios.post(base_url + 'invoices/jobRun', data)
            .then(res => {
                fetchJobRun()
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
                setError(err.response.data.message)
                console.log(err)
            })
    }
    const handleEnable = (each) => {
        return false;
    }
    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>
            <div className='generate-invoices-container'>
                <div style={{ height: "90%", overflow: "auto" }}>
                    <h3 className="invoice-heading">Run Invoices</h3>
                    <div className='d-flex gap-3 justify-content-start flex-wrap my-4 align-items-end'>
                        <Dropdown
                            className={"dropdown-list"}
                            name={"Invoice Type"}
                            defaultOption={"Select Invoice Type"}
                            selectedOption={selectedService}
                            handleChange={(e) => {
                                setSelectedService(e.target.value)
                            }}
                            options={servicesList?.map(item => ({
                                name: item.service_name,
                                value: item.service_code
                            }))
                            }
                        />
                        <div className='d-flex flex-column custom-date-picker'>
                            <label className='add-facility-modal-label' style={{ marginBottom: "4px" }}>Billing Period<span style={{ color: "red" }}>*</span></label>
                            <DatePicker
                                className="admin-controls-date-picker form-control responsive-add-facility-input-fields"
                                placeholderText="Select Period"
                                selected={billingPeriodFilter}
                                style={{ width: "1rem", height: "2.5rem !important" }}
                                onChange={(date) => setBillingPeriodFilter(date)}
                                showMonthYearPicker
                                dateFormat="MMM yyyy"
                            />
                        </div>
                        <Dropdown
                            className={"dropdown-list"}
                            name={"Action"}
                            defaultOption={"Select Action"}
                            selectedOption={selectedAction}
                            handleChange={(e) => {
                                setSelectedAction(e.target.value)
                            }}
                            options={[{
                                name: "Generate Invoice",
                                value: "generate"
                            }, {
                                name: "Submit to GST Portal",
                                value: "gstSubmission"
                            },
                            {
                                name: "Generate PDF",
                                value: "pdfGeneration"
                            },
                            {
                                name: "Email Submission",
                                value: "emailSubmission"
                            }
                            ]}
                        />
                        <div>
                            <button className="btn btn-secondary" disabled={
                                selectedService === 'select invoice type' ||
                                selectedAction === 'Select Action' ||
                                billingPeriodFilter < new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                            }
                                onClick={handleInvoiceGeneration}
                            >Submit Invoice Job</button>
                        </div>
                    </div>
                    <b style={{ fontSize: "16px" }}>Invoice Job Run History</b>
                    <div style={{ height: "60%", overflow: "auto", margin: "0px 10px 0px 0px" }}>
                        <TableComponent
                            headers={[{
                                name: "Invoice Type",
                                type: "text",
                                key: "job_name",
                                sort: true
                            }, {
                                name: "Billing Period",
                                type: "text",
                                key: "billing_period",
                                sort: true
                            }, {
                                name: "Current Status",
                                type: "text",
                                key: "status",
                                sort: true
                            }, {
                                name: "Notes",
                                type: "text",
                                key: "notes",
                                sort: true
                            }, {
                                name: "Started Time",
                                type: "time_stamp",
                                key: "start_time",
                                sort: true
                            }, {
                                name: "End Time",
                                type: "number",
                                key: "end_time",
                                sort: true
                            }, {
                                name: "Action",
                                type: "button",
                                buttons: [{
                                    name: "View",
                                    icon: "./images/icons/ViewViewIconEye.svg",
                                    disable: handleEnable,
                                    action: (data) => {
                                        setSelectedJobRunDetailsInformation(data)
                                        fetchJobRunLogs(data.job_run_id)
                                        document.querySelector('.job-run-logs').scrollIntoView({ behavior: 'smooth' });
                                    },
                                }]
                            }]}
                            data={jobRunHistory}
                        />
                    </div>
                    <Pagination
                        handledPaginationNextClicked={handledPaginationNextClicked}
                        handledPaginationPrevClicked={handledPaginationPrevClicked}
                        isApplyClicked
                        totalRecords={totalRecords}
                        recordsPerPage={RECORDS_PER_PAGE}
                        currentPaginationPage={currentPageNumber}
                    />
                    <hr />
                    <b style={{ fontSize: "16px" }}>Invoice Job Run Detail: {selectedJobRunDetailsInformation?.job_name} for {getMonthName(selectedJobRunDetailsInformation?.invoice_month)} {selectedJobRunDetailsInformation?.invoice_year}</b>
                    <div className='mt-3 job-run-logs'>
                        <TableComponent
                            headers={[{
                                name: "Step Name",
                                type: "text",
                                key: "step_description",
                                sort: true
                            }, {
                                name: "Status",
                                type: "text",
                                key: "notes",
                                sort: true
                            }, {
                                name: "Notes",
                                type: "text",
                                key: "log_message",
                                sort: true
                            }, {
                                name: "Start Time",
                                type: "time_stamp",
                                key: "updated_at",
                                sort: true
                            }]}
                            data={jobRunDetail}
                        />
                    </div>
                </div>
                <div className='d-flex justify-content-end p-2'>
                    <button className='invoice_btn' onClick={() => {
                        navigate('/financeManagerInvoices')
                    }}>Back</button>
                </div>
            </div>
            {loader && <FormLoader />}
            {error && <AlertPopUp
                alertShow={true}
                alertType="error"
                alertMsg="Error"
                alertValue={error}
                alertButtons={[{ name: "Close", action: () => { setError(null) } }]}
            />}
        </>
    )
}

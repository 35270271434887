import { set } from 'date-fns';
import React, { useState, useEffect, } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import {
    CREATE_SCHEDULE_EVENT_SUCCESS, getAssetCategories, getAssetsByCategory, scheduleNewEvent, SET_ERROR_FALSE,
    readanCreatedEvent, getAllForms, updateScheduledEvent, UPDATE_SCHEDULE_EVENT_SUCCESS,
    getAssetsByCategoryAndFacility,
} from "../../Redux/Actions/Actions";
import { useSelector } from 'react-redux';
import SearchableDropdown from './SearchableDropdown';
import { useDispatch } from "react-redux";
import Loader from '../Reusable_Components/loader';
import AlertPopUp from "../Reusable_Components/Alert";
import Button from 'react-bootstrap/Button';
import { formatDateView } from "./ReusableFunctions.js";
import { validateValue } from './ReusableFunctions.js';

const ScheduleEvent = ({ show, onHide, daysOfTheWeek, modalData, handleUpdateEventDetails, modifyFacilityFilter,selectedTableDate,currentPage,
    startOfMonth, endOfMonth, currentDay, weeksData, currentWeek, currentYear, currentMonth, date ,selectedCategoryIds, setEventDetails}) => {



    useEffect(() => {
        if (modalData && modalData?.event_id) {
            setSelectedFacility(modalData?.facility_name);
            setSelectedFacilityId(modalData?.facility_id);
            setSelectedEvent(modalData?.category?.category_name);
            setSelectedAssetCategory(modalData?.event_entity_category);
            dispatch(getAssetsByCategoryAndFacility(modalData?.event_entity_category, modalData?.facility_id));
            setEventTitle(modalData?.event_name);
            setSelectedAsset(modalData?.event_entity_name);
            setAssetId(modalData?.event_entity_id);
            setAssetName(modalData?.event_entity_name);
            if(modalData?.all_assets){
                setIsSingleAsset(false);
                setAllAssets(true);
            }
            else{
                setIsSingleAsset(true);
                setAllAssets(false);
            }
            setEventDescription(modalData?.description);
            setSelectedEntityType(modalData?.event_entity_type);
           
            setSelectedSop(modalData?.default_sop_id);
            setSelectedChecklist(modalData?.default_checklist_id);
            setSelectedSopName(modalData?.default_sop_name);
            setSelectedChecklistName(modalData?.default_checklist_name);

            setOneTimeEvent(modalData?.recurrence?.is_one_time);
            setRecurringEvent(!modalData?.recurrence?.is_one_time);
            setOneTimeEventDate(modalData?.recurrence?.begin_date);//
            // take only hour and minute from 17:30:00
            const [hours, minutes] = modalData?.recurrence?.event_start_time?.split(':');
            setoneTimeEventStartTime(`${hours}:${minutes}`); // 

           
            const [day, month, year] = selectedTableDate?.split("-");
            const formattedDate = `${year}-${month}-${day}`;
            setRecurringBeginDate(formattedDate);
            
            setRecurringEventStartTime(`${hours}:${minutes}`);
            
            setRecurringEndDate(modalData?.recurrence?.end_date);
            setRecurrenceType(modalData?.recurrence?.recurrence_type);
            setInterval(modalData?.recurrence?.repeat_interval);

            const selectedDays = (modalData?.recurrence?.recurrence_week_days);
            
            const parsedDays = selectedDays?.match(/\d+/g)?.map(Number);
            const daysInOrder = [ "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];	
            const selectedDaysList = []
            parsedDays?.forEach((day) => {    
                const dayName = daysInOrder[day];
                selectedDaysList.push(dayName);
            });
     
            setDaysOfWeek(selectedDaysList);

            const monthlyRadio = modalData?.recurrence?.recurrence_weeks == null ? "0" : modalData?.recurrence?.recurrence_weeks;
            setMonthlyOption(monthlyRadio);
           
        }

    }, [modalData]);

    const dispatch = useDispatch();
    const contractApiLoader = useSelector(state => state.contractApiLoader);
    const createNewEvent = useSelector(state => state.createNewEvent);
    const updateScheduledEventResponse = useSelector(state => state.updateScheduledEventResponse);

    const error = useSelector(state => state.error);
    const today = new Date().toISOString()?.split('T')[0];

    const [selectedFacility, setSelectedFacility] = useState(`${localStorage.getItem("selected_city_name")}, ${localStorage.getItem("selected_facility_name")}`);

    const [selectedEvent, setSelectedEvent] = useState('');
    const [selectedAssetCategory, setSelectedAssetCategory] = useState('');
    const [selectedAsset, setSelectedAsset] = useState('');

    const [selectedSupervisor, setSelectedSupervisor] = useState('');
    const [selectedSop, setSelectedSop] = useState('');
    const [selectedChecklist, setSelectedChecklist] = useState('');
    const [selectedSopName, setSelectedSopName] = useState('');
    const [selectedChecklistName, setSelectedChecklistName] = useState('');
    const [eventDescription, setEventDescription] = useState('');
  

    const [isSingleAsset, setIsSingleAsset] = useState(true); //settinng single asst as defalt
    const [allAssets, setAllAssets] = useState(false);

    const [oneTimeEvent, setOneTimeEvent] = useState(true); //setting one time event as default
    const [recurringEvent, setRecurringEvent] = useState(false); //setting recurring event as default

    const assetCategory = useSelector(state => state.assetCategory);
    const eventCategory = useSelector(state => state.eventCategory); //storing all event categories from api
    const assetByCategory = useSelector(state => state.assetByCategory);

    const facmanList = useSelector(state => state.facmanList);

    const [selectedCatergoryId, setSelectedCategoryId] = useState(null);
    const [eventCategoryList, setEventCategoryList] = useState([]);

   

    const [recurringEndDate, setRecurringEndDate] = useState('');
    const [recurringBeginDate, setRecurringBeginDate] = useState(today);
    const [oneTimeEventDate, setOneTimeEventDate] = useState(()=>{
        const date  = new Date()?.toISOString()?.split('T')[0];
        return date;
    });
    // const [oneTimeEventDate, setOneTimeEventDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()?.split('T')[0]);
   
    const [oneTimeEventStartTime, setoneTimeEventStartTime] = useState(
        new Date().toLocaleTimeString('en-GB', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' })
    );;
   
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])

    const [checklistOptions, setChecklistOptions] = useState([]);
    const [sopOptions, setSopOptions] = useState([]);
    const [selectedSopId, setSelectedSopId] = useState(null);
    const [selectedChecklistId, setSelectedChecklistId] = useState(null);
    
    

    const [recurrenceType, setRecurrenceType] = useState("daily");
    const [interval, setInterval] = useState(1);


    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [monthlyOption, setMonthlyOption] = useState('');

    const [recurringEventStartTime,setRecurringEventStartTime] = useState(
        new Date().toLocaleTimeString('en-GB', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' })
    );

    const [selectedEntityType, setSelectedEntityType] = useState('');
    const [eventTitle, setEventTitle] = useState('');

    const [reminder, setReminder] = useState({
        option: '30 minutes before',
        customValue: '',
        customUnit: 'minutes',
    });


    const fetchDetailsAfterUpdate = () => {
        let startDate  
        let endDate
        if(currentPage === 2){
            startDate = startOfMonth(currentDay).toISOString()?.split('T')[0];
            endDate = endOfMonth(currentDay).toISOString()?.split('T')[0]+ " 23:59:59";
        }else if(currentPage === 3){
            const data = weeksData[currentWeek];
            if(data){const startDay = data[0];
            const endDay = data[data.length - 1];
            let startDateOfWeek;
            let endDateOfWeek;
            if (currentWeek === 0 && startDay > 20) {
                startDateOfWeek = new Date(currentYear, currentMonth - 1, startDay);
            } else {
                startDateOfWeek = new Date(currentYear, currentMonth, startDay);
            }
            if (currentWeek === weeksData.length - 1 && endDay < 10) {
                endDateOfWeek = new Date(currentYear, currentMonth + 1, endDay);
            } else {
                endDateOfWeek = new Date(currentYear, currentMonth, endDay);
            }

            startDate = new Date(startDateOfWeek.getTime() - (startDateOfWeek.getTimezoneOffset() * 60000)).toISOString()?.split('T')[0];
            endDate = new Date(endDateOfWeek.getTime() - (endDateOfWeek.getTimezoneOffset() * 60000)).toISOString()?.split('T')[0];
            }

        }else{
            const clickDate = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(date).padStart(2, '0')}`;
            const clickDate1 = new Date(clickDate);
            const endDate1 = new Date(clickDate);
            endDate1.setDate(endDate1.getDate() + 1);
            const formattedEndDate = endDate1.toISOString()?.split('T')[0];
            startDate = clickDate1.toISOString()?.split('T')[0];
            endDate = formattedEndDate;
        }

        if(selectedCategoryIds.length === 0) {
            dispatch(readanCreatedEvent(selectedFacilityId,selectedCategoryIds,startDate,endDate))
        } else {
            dispatch(readanCreatedEvent(selectedFacilityId, selectedCategoryIds,startDate,endDate))
        }
    }

    const daysInOrder = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
   
    const dayNumberMap = {Mon:0, Tue:1, Wed:2, Thu:3, Fri:4, Sat:5, Sun:6};
    const fullDayNames = { Sun: "Sunday", Mon: "Monday", Tue: "Tuesday", Wed: "Wednesday", Thu: "Thursday", Fri: "Friday", Sat: "Saturday" };
    const fullDayNumberMap = { "Monday": 0, "Tuesday": 1, "Wednesday": 2, "Thursday": 3, "Friday": 4, "Saturday": 5, "Sunday": 6 };
    const handleDaySelection = (day) => {
    
        setDaysOfWeek((prev) => {
            // If there's only one item left and it's the one being removed, don't update state
            if (prev.length === 1 && prev.includes(day)) {
                return prev; // Prevent removal if it's the last item
            }
            // Otherwise, toggle the day selection
            return prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day];
        });
      };

   

     
      
    const handleRecurrenceWeekDays = (selectedDays) => {
        return selectedDays?.map((day) => dayNumberMap[day]);
      };
      const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0'); // months are zero-based
        const day = d.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
     };

    
   
    
    
    const getNthWeekday = (date) => {
        const day = new Date(date).getDay();
        const monthStart = new Date(date);
        monthStart.setDate(1);
  
        let count = 0;
        for (let i = 1; i <= 31; i++) {
        const currentDate = new Date(monthStart.getFullYear(), monthStart.getMonth(), i);
        if (currentDate.getMonth() !== monthStart.getMonth()) break; // Exit if out of month
        if (currentDate.getDay() === day) count++;
        if (currentDate.getDate() === new Date(date).getDate()) return count;
        }
        return count;
    };

    
  
   // to check if the selected date is the last occurrence of the weekday in the month
    const isLastWeekday = (date) => {
    const currentDay = new Date(date).getDay();
    const nextWeek = new Date(date);
    nextWeek.setDate(nextWeek.getDate() + 7);
    return nextWeek.getMonth() !== new Date(date).getMonth();
    };

    const getNthWeekdayText = (date) => {
        const nthWeek = getNthWeekday(date);
        const weekday = new Date(date).toLocaleDateString(undefined, { weekday: "long" });
    
        if (nthWeek === 5 || isLastWeekday(date)) {
            // Display "Last [Weekday]" if it's the last occurrence
            return `Last ${weekday}`;
        } else {
            return `${getOrdinalSuffix(nthWeek)} ${weekday}`;
        }
      };
      const getWeekdayText = (date) => {
        return new Date(date).toLocaleDateString(undefined, { weekday: "long" });
      };


    const getOrdinalSuffix = (num) => {
        if (num > 3 && num < 21) return `${num}th`;
        switch (num % 10) {
          case 1: return `${num}st`;
          case 2: return `${num}nd`;
          case 3: return `${num}rd`;
          default: return `${num}th`;
        }
      };
      
    useEffect(() => {
        dispatch(getAllForms());
        dispatch(getAssetCategories());
    }, [dispatch]);

    const handleClose = () => {
        backFunction();
        setSelectedFacility(`${localStorage.getItem("selected_city_name")}, ${localStorage.getItem("selected_facility_name")}`);
        setEventDescription('');
        setEventTitle('');
        setSelectedEntityType('');
        setSelectedEvent('');
        setSelectedAssetCategory('');
        setSelectedAsset('');

        setSelectedSupervisor('');
        setIsSingleAsset(true);
        setAllAssets(false);
        setSelectedSop('');
        setSelectedChecklist('');
        setOneTimeEvent(true);
        setRecurringEvent(false);
        setOneTimeEventDate(new Date(new Date().setDate(new Date().getDate())).toISOString()?.split('T')[0]);
        setRecurringBeginDate(today);
        setRecurringEndDate('');
        setRecurringEventStartTime(new Date().toLocaleTimeString()?.split(' ')[0]);
        setRecurrenceType('daily');
        setInterval(1);
        setDaysOfWeek([]);
        setMonthlyOption('0');


        
       
        setReminder({
            option: '30 minutes before',
            customValue: '',
            customUnit: 'minutes',
        });

        setAlertShow(false);
        setAlertType('');
        setAlertMessage('');
        setAlertValue('');
        setAlertButtons([]);
        modalData = null;
        onHide();
    };

    const formsList = useSelector(state => state.formsList);
    const [formList, setFormList] = useState([]);

    useEffect(() => {

        if (formsList) {
            setFormList(formsList);

            const sopOptionsList = formsList
                ?.filter(form => form.form_type === "sop")
                ?.map(form =>
                    form.form_name
                ) || [];

            const checklistOptionsList = formsList
                ?.filter(form => form.form_type === "checklist")
                ?.map(form =>
                    form.form_name
                ) || [];
            setSopOptions(sopOptionsList);
            setChecklistOptions(checklistOptionsList);
        }
    }, [formsList]);



    useEffect(() => {
        // setErrorValue(error?.response?.data.message);
        if (error?.response?.data.message) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    dispatch({
                        type: SET_ERROR_FALSE,
                        payload: null
                    })
                    setAlertShow(false)
                }
            }])
        }
    
    }, [error])

    useEffect(() => {

        if (createNewEvent) {
            onHide();//
        
            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue('Event Scheduled Successfully')
            setAlertButtons([{
                name: 'Close', action: () => {
                    dispatch({
                        type: CREATE_SCHEDULE_EVENT_SUCCESS,
                        payload: null
                    })
                    modifyFacilityFilter(selectedFacilityId);
                    // dispatch(readanCreatedEvent(selectedFacilityId))
                    fetchDetailsAfterUpdate();
                    setAlertShow(false)
                    backFunction();
                }
            }])
        }
    }, [createNewEvent])

    useEffect(() => {
        if (updateScheduledEventResponse) {
            onHide();//
            // handleBack(); //
            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue('Event Updated Successfully')
            setAlertButtons([{
                name: 'Close', action: () => {
                    dispatch({
                        type: UPDATE_SCHEDULE_EVENT_SUCCESS,
                        payload: null
                    })
                    // dispatch(readanCreatedEvent(selectedFacilityId))
                    fetchDetailsAfterUpdate();
                    setAlertShow(false)
                    backFunction();
                }
            }])
        }
    }, [updateScheduledEventResponse])


    const facilitiesListForNav = useSelector(state => state.facilitiesListForNav);
    const [facilityList, setFacilityList] = useState([]);
    const [selectedFacilityId, setSelectedFacilityId] = useState(localStorage.getItem("selected_facility_id"));
    useEffect(() => {
        if (facilitiesListForNav) {
            const options = facilitiesListForNav?.map((facility) => `${facility.city}, ${facility.name}`);
            setFacilityList(options);

        }
    }, [facilitiesListForNav]);

    useEffect(() => {
        if (eventCategory) {
            const categoryList = eventCategory?.map((category) =>
                category.category_name);
            setEventCategoryList(categoryList);
        }
    }, [eventCategory]);

    const [supervisorList, setSupervisorList] = useState([]);
    useEffect(() => {
        if (facmanList) {
            const dataList = facmanList?.map((facman) =>
                facman.username);
            setSupervisorList(dataList);
        }
    }, [facmanList]);


    const [assetsList, setAssetsList] = useState([]);
    const [assetId, setAssetId] = useState(null);
    const [assetName, setAssetName] = useState(null);

    useEffect(() => {
        if (assetByCategory) {
            const assetList = assetByCategory?.map((asset) =>
                asset.asset_name);
            setAssetsList(assetList);
        }
    }, [assetByCategory]);

    const handleToggleChange = () => {
        setIsSingleAsset(!isSingleAsset);
        setAllAssets(!allAssets);
    };

    const OccurenceToggle = () => {
        setOneTimeEvent(!oneTimeEvent);
        setRecurringEvent(!recurringEvent);
    };

    const handleOptionChange = (event) => {
        const { value } = event.target;
        setReminder({
            option: value,
            customValue: '',
            customUnit: 'minutes',
        });
    };

    const handleCustomValueChange = (event) => {
        const { value } = event.target;
        setReminder({
            ...reminder,
            customValue: value,
        });
    };

    const handleCustomUnitChange = (event) => {
        const { value } = event.target;
        setReminder({
            ...reminder,
            customUnit: value,
        });
    };
    const getEndDate = () => {
        if (recurringEndDate){
            return recurringEndDate;
        } 
        else{
            const startDate = new Date(recurringBeginDate);
            startDate.setFullYear(startDate.getFullYear() + 1); // Add 1 year
            return startDate.toISOString()?.split("T")[0];
        }
        
      };
      const eventEndTime = "23:59:59";
      let calValue = []; 
        
        if(monthlyOption !== "0"){
            calValue = [fullDayNumberMap[(getNthWeekdayText(recurringBeginDate)?.split(" "))[1]]]
        }
        const recurrenceWeekDays = recurrenceType === "weekly" ? handleRecurrenceWeekDays(daysOfWeek) : [];

    const handleCreateEvent = () => {

        const isFacility = selectedEntityType === "facility";

        const data = {
            event_name: eventTitle,
            event_entity_type: selectedEntityType,
            event_entity_category: isFacility ? "facility" : selectedAssetCategory,
            event_entity_id: isFacility ? selectedFacilityId : assetId,
            event_entity_name: isFacility ? selectedFacility : assetName,
            description: eventDescription,
            status: "active",
            reminder_type: "email",
            reminder_before_hrs: "48",
            all_assets: !isSingleAsset,
            facility_name: selectedFacility,
            facility_id: selectedFacilityId,
            default_sop_id: selectedSopId,
            default_checklist_id: selectedChecklistId,
            default_sop_name: selectedSopName,
            default_checklist_name: selectedChecklistName,

            "recurrence": {
                "is_one_time": oneTimeEvent,
                "begin_date": oneTimeEvent ? oneTimeEventDate : recurringBeginDate,
                "end_date": oneTimeEvent ? oneTimeEventDate: getEndDate(),
                "event_start_time": oneTimeEvent ? oneTimeEventStartTime : recurringEventStartTime,
                "event_end_time": eventEndTime,

                "recurrence_type": oneTimeEvent ? null: recurrenceType,
                "recurrence_week_days": oneTimeEvent ? null :
                    (recurrenceType === "weekly" ? 
                    recurrenceWeekDays 
                    : recurrenceType === "monthly" 
                    ?
                    (monthlyOption !== "0" ? calValue : [])
                    : []),
                "recurrence_weeks":  oneTimeEvent ?  null : recurrenceType === "monthly" ? (monthlyOption  == "0" ? null : monthlyOption) :null,

                "repeat_interval": oneTimeEvent ? null : ( recurrenceType === 'yearly' ? 1 : interval),
                
            },

            "category": {
                event_category_id: selectedCatergoryId,
                category_name: selectedEvent,
            }
        }
        dispatch(scheduleNewEvent(data));

    }

    const handleUpdate = () => {
        const recurrenceWeekDays = recurrenceType === "weekly" ? handleRecurrenceWeekDays(daysOfWeek) : [];

        const data = {
            event_id: modalData?.event_id,
            event_name: eventTitle,
            event_entity_category: selectedAssetCategory,
            event_entity_id: assetId,
            event_entity_name: assetName,

            description: eventDescription,
            event_entity_type: selectedEntityType,
            status: "active",
            reminder_type: "email",
            reminder_before_hrs: "48",
            all_assets: !isSingleAsset,
            facility_name: selectedFacility,
            facility_id: selectedFacilityId,
            default_sop_id: selectedSopId ? selectedSopId : modalData?.default_sop_id,
            default_checklist_id: selectedChecklistId ? selectedChecklistId : modalData?.default_checklist_id,
            default_sop_name: selectedSopName,
            default_checklist_name: selectedChecklistName,

            "recurrence": {
                "event_id": modalData?.event_id,
                "rule_id": modalData?.recurrence?.rule_id,
                "is_one_time": oneTimeEvent,
                "begin_date":  oneTimeEvent ? oneTimeEventDate : recurringBeginDate,// to b changed for recurring event
                "end_date": oneTimeEvent ? oneTimeEventDate: getEndDate(),// to b changed for recurring event
              
                "event_start_time": oneTimeEvent ? oneTimeEventStartTime : recurringEventStartTime,
                "event_end_time": eventEndTime,

                "recurrence_type": recurrenceType,
                "recurrence_week_days":
                    recurrenceType === "weekly" ?
                    recurrenceWeekDays 
                    : recurrenceType === "monthly" 
                    ?
                    (monthlyOption !== "0" ? calValue : [])
                    : null,
                "recurrence_weeks":  monthlyOption  == "0" ? null : monthlyOption,

                "repeat_interval": oneTimeEvent ? null : (recurrenceType === 'yearly' ? 1 : interval),
            },

            "category": {
                event_category_id : selectedCatergoryId,
                category_name : selectedEvent
            }
        }
       
        dispatch(updateScheduledEvent(data, handleUpdateEventDetails));
        setShowUpdateConfirmationModal(false);
    }
    const [showUpdateConfirmationModal, setShowUpdateConfirmationModal] = useState(false);
    const handleCloseConfirmation = () => {
        setShowUpdateConfirmationModal(false);
        handleClose();
    };
    const handleUpdateEvent = () => {
        setShowUpdateConfirmationModal(true);
    };

    const backFunction = () => {
        setSelectedFacility(`${localStorage.getItem("selected_city_name")}, ${localStorage.getItem("selected_facility_name")}`);
        setEventDescription('');
        setEventDetails({});
        setSelectedEntityType('');
        setSelectedEvent('');
        setSelectedAssetCategory('');
        setSelectedAsset('');
        // setSelectedAssignee('');
        setSelectedSupervisor('');
        setIsSingleAsset(true);
        setAllAssets(false);
        setSelectedSop('');
        setSelectedChecklist('');
        setOneTimeEvent(true);
        setRecurringEvent(false);
        setOneTimeEventDate(new Date(new Date().setDate(new Date().getDate())).toISOString()?.split('T')[0]);
        setRecurringBeginDate(today);
        setRecurringEndDate('');
        setRecurringEventStartTime(new Date().toLocaleTimeString()?.split(' ')[0]);
        setRecurrenceType('daily');
        setInterval(1);
        setDaysOfWeek([]);
        setMonthlyOption('0');
      
     
        setRecurringBeginDate(today);
        setRecurringEndDate('');
        setReminder({
            option: '30 minutes before',
            customValue: '',
            customUnit: 'minutes',
        });
        setAlertShow(false);
        setAlertType('');
        setAlertMessage('');
        setAlertValue('');
        setAlertButtons([]);
        setoneTimeEventStartTime(new Date().toLocaleTimeString()?.split(' ')[0]);
        setSelectedSopName('');
        setSelectedChecklistName('');
        onHide();
        setEventTitle('');

    }

    const validateEventName = (value) => {
        return value === "" || /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/.test(value);
    };

    


    const handleSaveButtonDisabling = () => {

        const value1 = !selectedFacility || !selectedEvent || !selectedEntityType || !selectedSop || !selectedChecklist || eventTitle === "";

        let value2 = true

        if(selectedEntityType === "asset" && isSingleAsset){
            value2 = !selectedAssetCategory || !selectedAsset;
        }
        if(selectedEntityType === "asset" && !isSingleAsset){
            value2 = !selectedAssetCategory;
        }

        if(selectedEntityType === "facility"){
            value2 = false;
        }


        const finalValue = value1 || value2;

        return finalValue;


    }


    return (
        <>
            <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
            {contractApiLoader && <Loader />}
            <Modal
                keyboard={false}
                backdrop="static"
                show={show}
                className="schedule-mail-popup-modal"
                style={{display: 'block'}}
                centered
                onHide={handleClose}
            >
                <Modal.Header >
                        <Modal.Title>Schedule Event</Modal.Title>
                    </Modal.Header>
                <Modal.Body style={{ padding: '0px' }}>
                <div className="schedule-mail-popup">
                    <div className="schedule-mail-body">
                        <div className="schedule-mail-details-con">
                            <div>
                                <SearchableDropdown
                                    options = {facilityList? facilityList:[]} //facilityList is the array of facilities
                                    selectedOption = {selectedFacility}
                                    noOptionsAvailable = "Facility unavailable"
                                    onChange = {(e) => {
                                        setSelectedFacility(e.value)
                                        const selectedFacility = facilitiesListForNav?.find((facility) => `${facility.city}, ${facility.name}` === e.value); 
                                        setSelectedFacilityId(selectedFacility.facility_id);
                                        setAssetId("");  
                                        setAssetName("");
                                        setSelectedAssetCategory("")
                                        setSelectedAsset("");
                                    }}
                                    name={
                                        <span>
                                            Facility <span style={{ color: 'red' }}>*</span>
                                        </span>
                                    } 
                                    placeholder = "Select Facility"
                                    isDisabled = {modalData && modalData?.event_id}
                                    isRequired = {true}
                                />
                            </div>
                            <div>
                                <SearchableDropdown
                                    options={["asset", "facility"]}
                                    selectedOption={selectedEntityType}
                                    noOptionsAvailable="Type unavailable"
                                    onChange={(e) => {
                                        setSelectedEntityType(e.value); 
                                    
                                    }}
                                    name={
                                        <span>
                                            Entity Type <span style={{ color: 'red' }}>*</span>
                                        </span>
                                    }
                                    placeholder="Select Type"
                                    isDisabled={modalData && modalData?.event_entity_type}
                                    isRequired={true}
                                />                                  
                            </div>
                            <div>
                                <SearchableDropdown
                                        options = {eventCategoryList? eventCategoryList:[]} 
                                        selectedOption = {selectedEvent}
                                        noOptionsAvailable = "Category unavailable"
                                        onChange = {(e) => {
                                            setSelectedEvent(e.value)
                                            const selectedCatergory = eventCategory.find((category) => category.category_name === e.value);  
                                            setSelectedCategoryId(selectedCatergory.event_category_id);
                                        }}
                                        name={
                                            <span>
                                                Event <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        } 
                                        placeholder = "Select Event"
                                        isDisabled = {modalData && modalData?.event_id}
                                />
                            </div>
                            <div className="mt-3">
                                <p>Event Name <span style={{ color: 'red' }}>*</span></p>
                                <textarea
                                    id="textarea-input"
                                    disabled={modalData && modalData?.event_id}
                                    placeholder="Enter event name.."
                                    style={{
                                        width: '100%',padding: '8px',borderRadius: '5px', border: '1px solid #D9D9D9', height:'60px',marginTop:'-10px'
                                    }}
                                    value={eventTitle}
                                    maxLength={50}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (validateEventName(value)) {
                                            setEventTitle(value);
                                        }
                                    }}
                                />
                            </div>

                            {selectedEntityType !== "facility" && (
                                <>
                            <div className="mt-4" style={{ display: 'flex', justifyContent:'space-between' }}>
                                    <div>
                                        <label className="switch">
                                            <input type="checkbox"
                                            disabled={modalData && modalData?.event_id}
                                            checked={isSingleAsset} onClick={()=>{handleToggleChange()}} />
                                            <span className="slider round"></span>
                                        </label>
                                        <span className='mx-2'>Single Asset</span>
                                    </div>
                                
                                    <div>
                                        <label className="switch">
                                            <input type="checkbox" 
                                                disabled={modalData && modalData?.event_id}
                                                checked={allAssets} 
                                                onClick={()=>{handleToggleChange()}}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                        <span className='mx-2'>All Assets</span>
                                    </div>                              
                            </div>
                            

                            <div className="mt-2">
                                    <SearchableDropdown
                               
                                        options = {assetCategory? assetCategory:[]} //assetCategory is the array of asset categories
                                        selectedOption = {selectedAssetCategory}
                                        noOptionsAvailable = "Category unavailable"
                                        onChange = {(e) => {
                                            setSelectedAssetCategory(e.value)  //note here we have to give e.value instead of e.target.value
                                            dispatch(getAssetsByCategoryAndFacility(e.value, selectedFacilityId));
                                            setSelectedAsset("");
                                            setAssetId("");  
                                            setAssetName("");
                                        }}
                                        name={
                                            <span>
                                                Asset Category <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        } 
                                        placeholder = "Select Asset Category"
                                        isDisabled = {modalData && modalData?.event_id}
                                />
                            </div>
                            
                        
                            {isSingleAsset && 
                                <div className='mt-3'>
                                    <SearchableDropdown
                                         options = {assetsList} 
                                        selectedOption = {selectedAsset}
                                        noOptionsAvailable = "Asset unavailable"
                                        onChange = {(e) => {
                                            const asset =  assetByCategory.find((asset) => asset.asset_name === e.value);
                                            setSelectedAsset(e.value);
                                            setAssetId(asset.asset_id);  
                                            setAssetName(asset.asset_name);
                                        }}
                                        name={
                                            <span>
                                                Asset <span style={{ color: 'red' }}>*</span>
                                            </span>
                                        } 
                                        placeholder = "Select Asset"
                                        isDisabled = {(modalData && modalData?.event_id) || !selectedAssetCategory}
                                    />
                                </div>
                            }  
                            </>
                        )}                   
                            <div className="mt-3">
                                <p>Description</p>
                                <textarea
                                    id="textarea-input"
                                    placeholder="Enter text..."
                                    style={{
                                        width: '100%',padding: '8px',borderRadius: '5px', border: '1px solid #D9D9D9'
                                    }}
                                    value={eventDescription}
                                    maxLength={200}
                                    onChange={(e) => setEventDescription(e.target.value)}
                                ></textarea>
                                 <p style={{ fontSize: '12px', color: '#888' }}>
                                    {eventDescription.length}/200 characters
                                </p>
                            </div>
                            <div>
                        
                         
                            <div>
                                <div className="mt-3">
                                    <SearchableDropdown
                                    options = {sopOptions}
                                    selectedOption = {selectedSopName}
                                    noOptionsAvailable = "SOP unavailable"
                                    onChange = {(e) => {
                                        setSelectedSop(e.value)
                                        formsList?.map(form => {
                                            if (form.form_name === e.value) {
                                                setSelectedSopId(form.form_id)
                                                setSelectedSopName(form.form_name)
                                            }
                                        })
                                    }}
                                    name = {<span>SOP <span style={{ color: 'red' }}>*</span></span>}
                                    placeholder = "Select SOP"
                                    isDisabled = {false}
                                />
                                </div>
                           
                                <div className="mt-3">
                                <SearchableDropdown
                                        options = {checklistOptions}
                                        selectedOption = {selectedChecklistName}
                                        noOptionsAvailable = "Checklist unavailable"
                                        onChange = {(e) => {
                                            setSelectedChecklist(e.value)
                                            formsList?.map(form => {
                                                if (form.form_name === e.value) {
                                                    setSelectedChecklistId(form.form_id)
                                                    setSelectedChecklistName(form.form_name)
                                                }
                                            })
                                        }}
                                        name = {<span>Checklist <span style={{ color: 'red' }}>*</span></span>}
                                        placeholder = "Select Checklist"
                                        isDisabled = {false}
                                    />
                                </div></div>

    {/* ************************************************************************************** */}
                            </div>
                            <div className = "mt-3"> 
                                <div style={{ display: 'flex'}}>
                                    <img src="images/icons/schedule.svg" alt="Schedule Icon" style={{ marginRight: '8px' }} />
                                    <p style = {{marginTop:"10px"}}>Schedule On</p>
                                </div>
                                <div>
                                    <div>
                                            <label className="switch">
                                                <input type="checkbox" checked={oneTimeEvent}  onChange={OccurenceToggle}/>
                                                <span className="slider round"></span>
                                            </label>
                                            <span className='mx-2'>One Time Event</span>
                                    </div>
                                    {!recurringEvent &&
                                            <div className="d-flex justify-content-between schedule-mail-inputs mt-3">
                                                <div>
                                                    <p>Start Date</p>
                                                    <input
                                                    // disabled={modalData && modalData?.event_id}
                                                    type="date" min={today} value={oneTimeEventDate} onChange={(e) => {
                                                        setOneTimeEventDate(e.target.value)
                                                    }} style={{ width: '210px', height: '45px', marginTop: '-10px' }} />
                                                </div>
                                                <div>
                                                    <p>Start Time</p>
                                                    <select type = "time" value = {oneTimeEventStartTime} onChange = {(e) => {
                                                            setoneTimeEventStartTime(e.target.value)
                                                        }} style={{ width:'210px' ,height:'45px', marginTop:'-10px' }} >
                                                        {Array.from({ length: 48 })?.map((_, index) => {
                                                            const hour = Math.floor(index / 2);
                                                            const minutes = index % 2 === 0 ? "00" : "30";
                                                            const time = `${hour.toString().padStart(2, "0")}:${minutes}`;
                                                            return (
                                                                <option key={time} value={time}>
                                                                    {time}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>}
                                </div>
                                {/* recurring event div */}
                                <div>
                                    <div className='mt-3'>
                                            <label className="switch">
                                                <input type="checkbox" checked={recurringEvent}  onChange={OccurenceToggle}/>
                                                <span className="slider round"></span>
                                            </label>
                                            <span className='mx-2'>Recurring Event</span>
                                    </div>
                                </div>
                                {recurringEvent && (
                                    <div>
                
                                    <div className="d-flex justify-content-between schedule-mail-inputs mt-3 gap-4">
                                        <div>
                                            <p>Start Date</p>
                                                <input
                                                    type="date"
                                                    min={today}
                                                    value={recurringBeginDate}
                                                    onChange={(e) =>{
                                                        setRecurringBeginDate(e.target.value)
                                                        if(recurringEndDate === ''){
                                                            setRecurringEndDate(getEndDate())
                                                        }

                                                        if ((recurringEndDate !== '') &&  (e.target.value > recurringEndDate)) {
                                                            setRecurringEndDate('')
                                                        }
                                                    }}
                                                        
                                                    style={{ width: "210px",  height: "45px",  marginTop: "-10px", borderRadius: "4px", border: "1px solid #ccc", }}
                                                    onKeyDown={(e) => e.preventDefault()}
                                            />
                                        </div>
                                        <div>
                                            <p>Start Time</p>
                                            <select type= "time" value ={recurringEventStartTime} 
                                             onChange = {(e) => {
                                                setRecurringEventStartTime(e.target.value)
                                            }}
                                            style={{  width:'210px' ,height:'45px', marginTop:'-10px' }} >
                                          
                                            {Array.from({ length: 48 })?.map((_, index) => {
                                                const hour = Math.floor(index / 2);
                                                const minutes = index % 2 === 0 ? "00" : "30";
                                                const time = `${hour.toString().padStart(2, "0")}:${minutes}`;
                                                return (
                                                    <option key={time} value={time}>
                                                        {time}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        </div>

                                        </div>
                                        {/* Interval and Frequency */}
                                        <div className="d-flex justify-content-between schedule-mail-inputs mt-2 gap-4">
                                        <div>
                                            <p>Recurrence Type</p>
                                            <select
                                            value={recurrenceType}
                                            onChange={(e) => {
                                                
                                                setRecurrenceType(e.target.value)
                                                if(e.target.value === "weekly"){
                                                    const todayIndex = new Date().getDay(); // Get the index of today's day (0-6)
                                                    const todayString = daysInOrder[todayIndex]; // Convert index to day string
                                                    setDaysOfWeek([todayString]); // Set the state with today's day in string format
                                                }
                                            }
                                            }
                                            style={{
                                                width: "210px",
                                                height: "45px",
                                                marginTop: "-10px",
                                                borderRadius: "4px",
                                                border: "1px solid #ccc",
                                            }}
                                            >
                                            <option value="daily">Daily</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="monthly">Monthly</option>
                                            <option value="yearly">Yearly</option>
                                            </select>
                                        </div>
                                        <div>
                                            <p>Repeat Every</p>
                                            <input
                                            type="number"
                                            value={recurrenceType === "yearly" ? 1 : interval}
                                            min="1"
                                            max="99"
                                            disabled={recurrenceType === "yearly"}
                                            onChange={(e) => setInterval(Number(e.target.value))}
                                            style={{
                                                width: '210px',
                                                height: '45px',
                                                marginTop: '-10px',
                                                borderRadius: "4px",
                                                border: "1px solid #ccc",
                                                backgroundColor: recurrenceType === "yearly" ? "#f5f5f5" : "white",
                                                cursor: recurrenceType === "yearly" ? "not-allowed" : "text",
                                            }}
                                            />
                                        </div>
                                        </div>

                                        {/* Recurrence Type Specific Options */}
                                        <div className="schedule-mail-inputs mt-4">
                                        {recurrenceType === "daily" && (
                                             <p style={{ margin: "0.5rem 0", fontSize: "0.85rem", color: "#555" }}>
                                             Occurs every {interval} day{interval > 1 ? "s" : ""}
                                             {recurringEndDate ? ` until ${formatDateView(recurringEndDate)}.` : '.'}
                                         </p>
                                        )}

                                    {recurrenceType === "weekly" && (
                                    <div style={{ marginBottom: "1rem" }}>
                                         <div style={{ display: "flex", gap: "0.5rem", marginBottom: "0.5rem" }}>
                                           {daysInOrder?.map((day) => (
                                             <label key={day} style={{ display: "flex", alignItems: "center" }}>
                                               <input
                                                 type="checkbox"
                                                 checked={daysOfWeek.includes(day)}
                                                 onChange={() => handleDaySelection(day)}
                                                 style={{
                                                   marginRight: "0.5rem",
                                                   width: "18px",
                                                   height: "18px",
                                                   cursor: "pointer",
                                                 }}
                                               />
                                               {day}
                                             </label>
                                           ))}
                                         </div>
                                         {daysOfWeek.length > 0 && interval > 0 && (
                                           <p style={{ margin: "0.5rem 0", fontSize: "0.85rem", color: "#555" }}>
                                             Occurs on{" "}
                                                    {daysOfWeek
                                                    ?.slice() 
                                                    .sort((a, b) => {
                                                        
                                                        const adjustedOrder = (day) =>
                                                        day === "Sun" ? -1 : dayNumberMap[day]; 
                                                        return adjustedOrder(a) - adjustedOrder(b);
                                                    })
                                                    .map((day) => fullDayNames[day])
                                                    .map((day, index, arr) => {
                                                        if (index === arr.length - 1 && arr.length > 1) {
                                                        return `and ${day}`;
                                                        }
                                                        return day;
                                                    })
                                                    .join(", ")}{" "}
                                                    every {interval} week{interval > 1 ? "s" : ""}{" "}
                                                    {recurringEndDate ? ` until ${formatDateView(recurringEndDate)}.` : "."}
                                                </p>
                                                )}
                                            </div>
                                            )}

                                        
                                        {recurrenceType === "monthly" && (
                                            <div style={{ marginBottom: "1rem" }}>
                                                <label style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}>
                                                    <input
                                                    type="radio"
                                                    value="0"
                                                    checked={monthlyOption === "0"}
                                                    onChange={() => setMonthlyOption("0")}
                                                    style={{
                                                        width: "22px",
                                                        height: "22px",
                                                        marginRight: "0.5rem",
                                                    }}
                                                    />
                                                    <span>On day {new Date(recurringBeginDate).getDate()}</span>
                                                </label>

                                                <label style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}>
                                                <input
                                                    type="radio"
                                                    value={`${getNthWeekday(recurringBeginDate)}`}
                                                    checked={monthlyOption === `${getNthWeekday(recurringBeginDate)}`}
                                                    onChange={() => setMonthlyOption(`${getNthWeekday(recurringBeginDate)}`)}
                                                    style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    marginRight: "0.5rem",
                                                    }}
                                                />
                                                <span>On the {getNthWeekdayText(recurringBeginDate)}</span>
                                                </label>
                                            {getNthWeekday(recurringBeginDate) === 4 && (
                                                <label style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}>
                                                <input
                                                    type = "radio"
                                                    value = "5"
                                                    checked = {monthlyOption === "5"}
                                                    onChange = {() => setMonthlyOption("5")}
                                                    style = {{width: "24px", height: "24px", marginRight: "0.5rem"}}
                                                />
                                                <span>On the last {getWeekdayText(recurringBeginDate)} of the month</span>
                                                </label>
                                                )}

                                                    <p style={{ margin: "0.5rem 0", fontSize: "0.85rem", color: "#555" }}>
                                                        {monthlyOption === "0"
                                                            ? `Occurs on day ${new Date(recurringBeginDate).getDate()} until ${formatDateView(recurringEndDate) || "an year"}.`
                                                            : monthlyOption === "5"
                                                            ? `Occurs on the last ${getWeekdayText(recurringBeginDate)} of the month until ${
                                                                formatDateView(recurringEndDate) || "an year"
                                                            }.`
                                                            : `Occurs on the ${getNthWeekdayText(recurringBeginDate)} until ${formatDateView(recurringEndDate) || "an year"}.`}
                                                    </p>
                                            </div>
                                        )}

                                        {recurrenceType === "yearly" && (
                                            <p style={{ margin: "0.5rem 0", fontSize: "0.85rem", color: "#555" }}>
                                            Occurs on{" "}
                                            {new Date(recurringBeginDate).toLocaleDateString("en-IN", {
                                                month: "long",
                                                day: "numeric",
                                            })}{" "}
                                            every year {recurringEndDate ? `until ${formatDateView(recurringEndDate)}.` : `.`}
                                        </p>
                                        )}
                                        </div>

                                        {/* End Date */}
                                        <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                <p>End Date</p>
                                                <input
                                                type="date"
                                                min={new Date(new Date(recurringBeginDate).setDate(new Date(recurringBeginDate).getDate() + 1))
                                                    .toISOString()
                                                    ?.split("T")[0]}
                                                value={recurringEndDate}
                                                onChange={(e) => setRecurringEndDate(e.target.value)}
                                                style={{
                                                    width: "210px",
                                                    height: "45px",
                                                    marginTop: "-10px",
                                                    borderRadius: "4px",
                                                    border: "1px solid #ccc",
                                                }}
                                                />
                                                {/* {!recurringEndDate && ( */}
                                                {/* <p
                                                    style={{
                                                    // color: "#d9534f",
                                                    fontSize: "0.85rem",
                                                    marginTop: "0.5rem",
                                                    }}
                                                >
                                                    choose an end date.
                                                </p>
                                                )} */}
                                            </div>

                                         
                                                <div
                                                style={{
                                                    marginLeft: "1rem",
                                                    alignSelf: "flex-end",
                                                    color: "#007bff",
                                                    fontSize: "0.85rem",
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => setRecurringEndDate('')}
                                                >
                                                Remove End Date
                                                </div>
                                            
                                            </div>

                                    </div>
                                    )}
                                
                                {/* <div className="d-flex justify-content-between mt-3">
                                    <div className="d-flex gap-2" style={{display:'flex', alignItems: 'center'}}>
                                        <img className="alarm" src="./images/icons/alarm.png" alt="upload" />
                                        <select value={reminder.option} onChange={handleOptionChange}
                                        className='set-reminder'>
                                            <option value="30 minutes before">30 minutes before</option>
                                            <option value="1 hour before">1 hour before</option>
                                            <option value="custom">Custom</option>
                                        </select>

                                        {reminder.option === 'custom' && (
                                            <div className='d-flex gap-3'>
                                                <input
                                                    className='set-reminder-input'
                                                    // style={{width:'40px', height:'25px',padding: '8px', borderRadius: '4px', border: '1px solid  #9D9D9C'}}
                                                    type="number"
                                                    min="1"
                                                    value={reminder.customValue}
                                                    onChange={handleCustomValueChange}
                                                />
                                                <select value={reminder.customUnit} onChange={handleCustomUnitChange} className='responsive-set-reminder'>
                                                    <option value="minutes">minutes</option>
                                                    <option value="hours">hours</option>
                                                    <option value="days">days</option>
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                </Modal.Body>

                <Modal.Footer>
                        <Button className='btn btn-light px-5' style={{ border: "1px solid #878787", backgroundColor: "#FFFFFF" }} 
                        onClick={handleClose}
                        >
                            Back
                        </Button>
                        {
                            modalData && modalData?.event_id ? (
                                <Button className='btn btn-secondary px-5'
                                onClick={handleUpdateEvent}
                            >
                            Update
                        </Button>
                            ) : (
                                <Button className='btn btn-secondary px-5'
                                onClick={handleCreateEvent}
                                disabled = {handleSaveButtonDisabling()}
                            >
                            Save
                        </Button>
                            )
                        }
                        
                    </Modal.Footer>
            </Modal>
            <Modal
                keyboard={false}
                backdrop="static"
                show={showUpdateConfirmationModal}
                centered
                onHide={handleCloseConfirmation}
             >
            <Modal.Body className="text-center">
                    <h5>
                        These changes will update all the future tasks of this
                        event.
                    </h5>
                </Modal.Body>
                <Modal.Footer className="footer">
                    <Button
                        variant="secondary"
                        className="confirmation"
                        onClick={handleCloseConfirmation}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="secondary"
                        className="confirmation-left"
                        onClick={handleUpdate}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ScheduleEvent;

import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { dashboardColors } from '../../../Config';
import DashboardLoader from './DashboardLoader';

// Register the necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function FinanceManagerInvoiceReportByCustomer({ barChartName = 'Bar Chart Name', InvoicesReportData }) {

    const [InvoicesReport, setInvoicesReport] = useState([]);
    const [customersList, setCustomersList] = useState([]);
    const [barChartSelectedCustomer, setBarChartSelectedCustomer] = useState('');

    const generateUniqueColor = (index) => {
        const colors = dashboardColors;
        return colors[index % colors.length];
    };

    useEffect(() => {
        const customersFromAgingData = [...new Set(InvoicesReportData?.map(item => item["company_name"]).filter(name => name !== null))];
        setCustomersList(customersFromAgingData);
    }, [InvoicesReportData]);

    useEffect(() => {
        const transformData = (data) => {
            const invoiceTypes = [...new Set(data?.map(item => item["company_name"]))];
            const billingPeriods = [...new Set(data?.map(item => item["billing_period"]))].sort((a, b) => new Date(a) - new Date(b));

            return invoiceTypes.map((type, index) => {
                return {
                    label: type,
                    backgroundColor: generateUniqueColor(index),
                    data: billingPeriods.map(period => {
                        const monthlyData = data.filter(item => item["billing_period"] === period && item["company_name"] === type);
                        return monthlyData.reduce((sum, item) => sum + parseFloat(item["invoice_amount"]), 0);
                    }),
                };
            });
        };
        const filteredData = InvoicesReportData?.filter(item => {
            return (
                (barChartSelectedCustomer ? item["company_name"] === barChartSelectedCustomer : true)
            );
        });
        setInvoicesReport(transformData(filteredData));
    }, [InvoicesReportData]);


    const handleCustomerChange = (customer) => {
        setBarChartSelectedCustomer(customer);
        if (customer) {
            const filteredData = InvoicesReportData?.filter(item => {
                return (
                    (customer ? item["company_name"] === customer : true)
                );
            });
            const data = billingPeriods.map((period) => {
                const monthlyData = filteredData.filter(item => item["billing_period"] === period);
                return monthlyData.reduce((sum, item) => sum + parseFloat(item["invoice_amount"]), 0);
            });

            setInvoicesReport([{
                label: customer,
                backgroundColor: generateUniqueColor(0),
                data: data,
            }]);
        } else {
            const transformData = (data) => {
                const invoiceTypes = [...new Set(data?.map(item => item["company_name"]))];
                const billingPeriods = [...new Set(data?.map(item => item["billing_period"]))].sort((a, b) => new Date(a) - new Date(b));

                return invoiceTypes.map((type, index) => {
                    return {
                        label: type,
                        backgroundColor: generateUniqueColor(index),
                        data: billingPeriods.map(period => {
                            const monthlyData = data.filter(item => item["billing_period"] === period && item["company_name"] === type);
                            return monthlyData.reduce((sum, item) => sum + parseFloat(item["invoice_amount"]), 0);
                        }),
                    };
                });
            };
            setInvoicesReport(transformData(InvoicesReportData));
        }
    };



    const billingPeriods = [...new Set(InvoicesReportData?.map(item => item["billing_period"]))].sort((a, b) => new Date(a) - new Date(b));

    const data = {
        labels: billingPeriods,
        datasets: InvoicesReport,
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        barPercentage: 0.35,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Invoice Amount',
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    return (
        <div className="container dashboard-chart-container">
            <h5 style={{ textAlign: "start", width: "100%" }}>{barChartName}</h5>
            {InvoicesReportData ? <>
                <div className="d-flex justify-content-end gap-2 w-100">
                    <div className="">
                        <label className="form-label">
                            Select Customer:
                            <select className="form-select form-select-sm" style={{ width: "130px", textOverflow: "ellipsis" }} value={barChartSelectedCustomer} onChange={(e) => {
                                handleCustomerChange(e.target.value);
                            }}>
                                <option value="">All</option>
                                {customersList?.map((customer) => (
                                    <option key={customer} value={customer}>
                                        {customer}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
                <div style={{ width: "100%", height: "150px" }}>
                    <Bar data={data} options={options} />
                </div>
                <div className="legend-container" style={{ overflowX: 'auto', textWrap: "nowrap", width: "500px", margin: "auto" }}>
                    {InvoicesReport.map((dataset, index) => (
                        dataset?.label && <span key={index} style={{ marginRight: '10px', display: 'inline-block' }}>
                            <span style={{ backgroundColor: dataset.backgroundColor, width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></span>
                            {dataset.label}
                        </span>
                    ))}
                </div>
            </> :
                <DashboardLoader />
            }
        </div>
    );
}

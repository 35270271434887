import React from 'react'
import { numberFormatWithCommas } from '../ReusableFunctions'

export default function DashboardCard({ total, moreFields }) {

    return (
        <div className="dashboard-card-container d-flex gap-3">
            <div className='d-flex flex-column justify-content-center align-items-start'>
                <div className={`total-count ${!(total?.value) && 'loading'}`}>
                    {numberFormatWithCommas(total?.value)}
                </div>
                <div style={{ fontSize: "13px", fontWeight: "600", height: !(total?.name) && "20px", width: !(total?.name) && "100px" }} className={!(total?.name) && 'loading'}>
                    {total?.name}
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center gap-1'>
                {
                    moreFields?.map((field, index) => {
                        return (
                            <div key={index} className={`${!(total?.name) && 'loading'} d-flex`}>
                                <div style={{ width: "80px", fontSize: "10px" }}>
                                    {field?.name}
                                </div>
                                <div style={{ fontWeight: "600" }}>
                                    {numberFormatWithCommas(field?.value)}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Element from './Element'
import { MdErrorOutline, MdOutlineDelete } from "react-icons/md";
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_FIELD, DELETE_FIELD_ERROR, FETCHING_ERROR, SHOW_MODAL, deleteField, getAllFields } from '../../Redux/Actions/Actions';
import FormLoader from '../Reusable_Components/FromLoader';
import { sorting_lists, timeElapsed } from '../Reusable_Components/ReusableFunctions';
import AlertPopUp from '../Reusable_Components/Alert';
import TableComponent from '../Reusable_Components/TableComponent';
import Search from '../Reusable_Components/Search';

export default function FieldsList() {

    const dispatch = useDispatch()
    const fieldsList = useSelector((state) => state.fieldsList)
    const loader = useSelector((state) => state.loading)
    const modal = useSelector((state) => state.showModal)
    const deleteFieldSuccess = useSelector((state) => state.deleteField)
    const fetchingError = useSelector((state) => state.fetchingError)
    const deleteFieldError = useSelector((state) => state.deleteFieldError)

    const [fields, setFields] = useState(useSelector((state) => state.fieldsList))
    const [preview, setPreview] = useState(false)
    const [previewObject, setPreviewObject] = useState({})
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState("")
    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        dispatch(getAllFields())
    }, [])

    useEffect(() => {
        setFields(fieldsList)
    }, [fieldsList])

    const handleCloseModal = () => {
        dispatch({
            type: SHOW_MODAL,
            payload: false
        })
    };

    useMemo(() => {
        return sorting_lists(fieldsList)
    }, [fieldsList]);

    const handleDeleteElement = () => {
        // const fields = JSON.parse(localStorage.getItem("Fields"))
        // const filteredFields = fields.filter((field) => field.items.id !== deleteId)
        // setFields(filteredFields)
        // localStorage.setItem("Fields", JSON.stringify(filteredFields))
        // setShowModal(false)
        dispatch(deleteField(deleteId))
    }

    const componentToRender = () => {
        return <div style={{ padding: "20px", width: "210mm", backgroundColor: "white" }}>
            <div style={{ overflow: "auto", width: "100%" }} className='d-flex flex-column gap-3'>
                <div className='d-flex align-items-center gap-3 container'>
                    <Element object={previewObject} />
                    <div className='d-flex justify-content-end' onClick={() => {
                        setPreview(false)
                        setPreviewObject({})
                    }}>
                        <button className='btn btn-secondary'>Back</button>
                    </div>
                </div>

            </div>

        </div>
    }

    const handleDeleteElementModal = (id) => {
        setDeleteId(id)
        dispatch({
            type: SHOW_MODAL,
            payload: true
        })
    }
    const handleEnable = (each) => {
        return false;
    }
    return (
        <>
            {loader && <FormLoader />}
            {preview ? <div>{componentToRender()}</div> : <>
                <div className='d-flex justify-content-between my-2'>
                    <h3>Fields</h3>
                    <div className='d-flex align-items-center gap-1'>
                        <Search
                            value={searchText}
                            placeholder='Search Fields'
                            handleSearch={(e) => {
                                setSearchText(e.target.value)
                                const filteredData = fieldsList.filter((field) => field.field_name.toLowerCase().includes(e.target.value.toLowerCase()))
                                setFields(filteredData)
                            }}
                        />
                        <Link to={'/form/fields'}><button className='btn btn-secondary mx-4'>Create new field</button></Link>
                    </div>
                </div>
                <TableComponent
                    headers={[{
                        name: "Field Name",
                        type: "text",
                        key: "field_name"
                    }, {
                        name: "Field Type",
                        type: "text",
                        key: "field_type"
                    }, {
                        name: "Description",
                        type: "date",
                        key: "description"
                    }, {
                        name: "Created By",
                        type: "date",
                        key: "created_by",
                    }, {
                        name: "Updated By",
                        type: "text",
                        key: "updated_by",
                    }, {
                        name: "Created",
                        type: "time_stamp",
                        key: "created_at",
                        sort: true
                    },
                    {
                        name: "Action",
                        type: "button",
                        buttons: [{
                            name: "View",
                            icon: "./images/icons/ViewViewIconEye.svg",
                            disable: handleEnable,
                            action: (data) => {
                                setPreview(true)
                                setPreviewObject(JSON.parse(data?.json_data))
                            }
                        },
                        {
                            name: "Delete",
                            icon: "./images/icons/Delete.svg",
                            disable: handleEnable,
                            action: (data) => handleDeleteElementModal(data.field_id)
                        }

                        ]
                    }
                    ]}
                    data={fields}
                />
            </>}
            {modal && <Modal keyboard={false} backdrop="static" show={modal} centered onHide={handleCloseModal}>
                <Modal.Body>Are you sure you want to delete this Field?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteElement}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>}
            {deleteFieldSuccess && <AlertPopUp
                alertShow={deleteFieldSuccess}
                alertType="success"
                alertMsg="Success"
                alertValue={deleteFieldSuccess}
                alertButtons={[{ name: "Close", action: () => { dispatch({ type: DELETE_FIELD, payload: null }) } }]}
            />}
            {fetchingError && <AlertPopUp
                alertShow={fetchingError}
                alertType="error"
                alertMsg="Error"
                alertValue={fetchingError}
                alertButtons={[{ name: "Close", action: () => { dispatch({ type: FETCHING_ERROR, payload: null }) } }, {
                    name: "Retry", action: () => {
                        dispatch(getAllFields())
                        dispatch({
                            type: FETCHING_ERROR,
                            payload: null
                        })
                    }
                }]}
            />}
            {deleteFieldError && <AlertPopUp
                alertShow={deleteFieldError}
                alertType="error"
                alertMsg="Error"
                alertValue={deleteFieldError}
                alertButtons={[{
                    name: "Close", action: () => {
                        dispatch({
                            type: DELETE_FIELD_ERROR,
                            payload: null
                        })
                        dispatch({
                            type: SHOW_MODAL,
                            payload: false
                        })
                    }
                }, {
                    name: "Retry", action: () => {
                        handleDeleteElement()
                        dispatch({
                            type: DELETE_FIELD_ERROR,
                            payload: null
                        })
                    }
                }]}
            />}
        </>
    )
}
import React, { useEffect, useState } from "react";
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import Footer from "../Login/Footer";
import DashboardCard from "../Reusable_Components/Dashboard/DashboardCard";
import FinanceManagerInvoicesPieChart from "../Reusable_Components/Dashboard/FinanceManagerInvoicesPieChart";
import FinanceManagerPaymentsBarChart from "../Reusable_Components/Dashboard/FinanceManagerPaymentsBarChart";
import FinanceManagerInvoicesReport from "../Reusable_Components/Dashboard/FinanceManagerInvoicesReport";
import FinanceManagerInvoiceReportByCustomer from "../Reusable_Components/Dashboard/FinanceManagerInvoiceReportByCustomer";
import { useDispatch, useSelector } from "react-redux";
import { GET_CONTRACTS_COUNT_BY_STATUS_FAILURE, GET_FINANCE_MANAGER_INVOICE_REPORT_BY_CUSTOMER_FAILURE, GET_FINANCE_MANAGER_INVOICES_REPORT_FAILURE, GET_INVOICES_COUNT_BY_STATUS_FAILURE, getContractsCountByStatus, getFinanceManagerInvoicesReport, getFinanceManagerInvoicesReportByCustomer, getInvoicesCountByStatus } from "../../Redux/Actions/Actions";
import AlertPopUp from "../Reusable_Components/Alert";

const FinanceManagerDashboard = () => {

    const dispatch = useDispatch();

    const invoicesReport = useSelector(state => state.invoicesReport);
    const invoiceReportByCustomer = useSelector(state => state.invoiceReportByCustomer);
    const invoiceCounts = useSelector(state => state.invoiceCounts);
    const contractsCount = useSelector(state => state.contractsCount);
    const invoicesReportError = useSelector(state => state.invoicesReportError);
    const invoiceReportByCustomerError = useSelector(state => state.invoiceReportByCustomerError);
    const invoiceCountsError = useSelector(state => state.invoiceCountsError);
    const contractsCountError = useSelector(state => state.contractsCountError);

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isMEdiumScreen, setIsMediumScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768); // Adjust the threshold as needed
            setIsMediumScreen(window.innerWidth > 768 && window.innerWidth < 1070)
        };

        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(getInvoicesCountByStatus());
        dispatch(getContractsCountByStatus());
        dispatch(getFinanceManagerInvoicesReport());
        dispatch(getFinanceManagerInvoicesReportByCustomer());
    }, []);


    return (
        <>
            <NavBarTop />
            <LeftNavBar />

            <div className="fm-dashboard-container">
                <h1 className="fm-dashboard-heading">Dashboard</h1>
                <div className="fm-dashboard-card-container my-2">
                    <DashboardCard total={
                        {
                            name: "Total Invoices",
                            value: invoiceCounts && invoiceCounts?.length > 0 && invoiceCounts[0]?.total_invoices
                        }
                    }
                        moreFields={
                            [{
                                name: "Draft",
                                value: invoiceCounts && invoiceCounts?.length > 0 && invoiceCounts[1]?.invoice_count
                            },
                            {
                                name: "Rejected",
                                value: invoiceCounts && invoiceCounts?.length > 0 && invoiceCounts[2]?.invoice_count
                            },
                            {
                                name: "Emailed",
                                value: invoiceCounts && invoiceCounts?.length > 0 && invoiceCounts[0]?.invoice_count
                            }
                            ]
                        }
                    />
                    <DashboardCard
                        total={
                            {
                                name: "Total Contracts",
                                value: contractsCount && contractsCount?.length > 0 && contractsCount[0]?.total_contracts
                            }
                        }
                        moreFields={
                            [
                                {
                                    name: "Active",
                                    value: contractsCount && contractsCount?.length > 0 && contractsCount[0]?.contract_count
                                },
                                {
                                    name: "Pending",
                                    value: contractsCount && contractsCount?.length > 0 && contractsCount[1]?.contract_count
                                },
                                {
                                    name: "Draft",
                                    value: contractsCount && contractsCount?.length > 0 && contractsCount[2]?.contract_count
                                },
                            ]
                        }
                    />
                </div>
                <div style={{ marginRight: "20px" }} className={`d-flex gap-2 ${isSmallScreen ? 'flex-column' : ''}`}>
                    <div style={{ flex: 1 }}>
                        <FinanceManagerInvoicesReport
                            barChartName="Invoices Report"
                            barchartData={invoicesReport}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <FinanceManagerInvoiceReportByCustomer
                            barChartName="Invoice Report By Customer"
                            InvoicesReportData={invoiceReportByCustomer}
                        />
                    </div>
                </div>
                <div style={{ margin: "10px 20px 30px 0px" }} className={`d-flex gap-2 ${isSmallScreen ? 'flex-column' : ''}`}>
                    <div style={{ flex: 1 }}>
                        {/* <FinanceManagerInvoicesPieChart /> */}
                    </div>
                    <div style={{ flex: 1 }}>

                    </div>
                </div>
            </div>
            {invoicesReportError && <AlertPopUp
                alertShow={invoicesReportError}
                alertType="error"
                alertMsg={invoicesReportError}
                alertValue={invoicesReportError}
                alertButtons={[{
                    name: 'Close', action: () => {
                        dispatch({
                            type: GET_FINANCE_MANAGER_INVOICES_REPORT_FAILURE,
                            payload: null
                        })
                    }
                }]}
            />}
            {invoiceReportByCustomerError && <AlertPopUp
                alertShow={invoiceReportByCustomerError}
                alertType="error"
                alertMsg={invoiceReportByCustomerError}
                alertValue={invoiceReportByCustomerError}
                alertButtons={[{
                    name: 'Close', action: () => {
                        dispatch({
                            type: GET_FINANCE_MANAGER_INVOICE_REPORT_BY_CUSTOMER_FAILURE,
                            payload: null
                        })
                    }
                }]}
            />}
            {invoiceCountsError && <AlertPopUp
                alertShow={invoiceCountsError}
                alertType="error"
                alertMsg={invoiceCountsError}
                alertValue={invoiceCountsError}
                alertButtons={[{
                    name: 'Close', action: () => {
                        dispatch({
                            type: GET_INVOICES_COUNT_BY_STATUS_FAILURE,
                            payload: null
                        })
                    }
                }]}
            />}
            {contractsCountError && <AlertPopUp
                alertShow={contractsCountError}
                alertType="error"
                alertMsg={contractsCountError}
                alertValue={contractsCountError}
                alertButtons={[{
                    name: 'Close', action: () => {
                        dispatch({
                            type: GET_CONTRACTS_COUNT_BY_STATUS_FAILURE,
                            payload: null
                        })
                    }
                }]}
            />}

            <Footer />
        </>
    )
}

export default FinanceManagerDashboard
import React, { useState, useEffect, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { dashboardColors } from '../../../Config';
import DashboardLoader from './DashboardLoader';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function FacilityManagerWaterReport({ barChartName = 'Line Chart Name', WaterReportData }) {

    const [InvoicesReport, setInvoicesReport] = useState([]);
    const [facilitiesList, setFacilitiesList] = useState([]);
    const [barChartSelectedFacility, setBarChartSelectedFacility] = useState('');
    const [transformedData, setTransformedData] = useState([]);

    const generateUniqueColor = (index) => {
        const colors = dashboardColors;
        return colors[index % colors.length];
    };

    useEffect(() => {
        const facilitiesFromData = [...new Set(WaterReportData?.map(item => item["facility_name"]).filter(name => name !== null))];
        setFacilitiesList(facilitiesFromData);
    }, [WaterReportData]);

    const billingPeriods = useMemo(() => {
        return [...new Set(WaterReportData?.map(item => item["billing_period"]))].sort((a, b) => new Date(a) - new Date(b));
    }, [WaterReportData]);

    useEffect(() => {
        const transformData = (data) => {
            const facilityNames = [...new Set(data?.map(item => item["facility_name"]))];
            return facilityNames.map((name, index) => {
                return {
                    label: name,
                    borderColor: generateUniqueColor(index),
                    backgroundColor: generateUniqueColor(index),
                    data: billingPeriods.map(period => {
                        const monthlyData = data.filter(item => item["billing_period"] === period && item["facility_name"] === name);
                        return monthlyData.reduce((sum, item) => sum + parseFloat(item["water_consumption"]), 0);
                    }),
                    fill: false,
                    borderWidth: 2, // Thin lines
                    pointRadius: 2, // Small circles
                    pointHoverRadius: 5, // Slightly larger on hover
                    tension: 0.4, // Smooth curves
                };
            });
        };

        const filteredData = WaterReportData?.filter(item => {
            return (
                (barChartSelectedFacility ? item["facility_name"] === barChartSelectedFacility : true)
            );
        });
        setTransformedData(transformData(filteredData));
    }, [WaterReportData, barChartSelectedFacility, billingPeriods]);

    useEffect(() => {
        setInvoicesReport(transformedData);
    }, [transformedData]);

    const handleFacilityChange = (facility) => {
        setBarChartSelectedFacility(facility);
    };

    const data = {
        labels: billingPeriods,
        datasets: InvoicesReport,
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Water Consumption in (kL)',
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    return (
        <div className="container" style={{ minHeight: "380px", height: "100%", width: "100%", backgroundColor: "#FFFFFF", padding: "10px" }}>
            <h5 style={{ textAlign: "start", width: "100%" }}>{barChartName}</h5>
            {WaterReportData ? <>
                <div className="d-flex justify-content-end gap-2 w-100">
                    <div className="">
                        <label className="form-label">
                            Select Facility:
                            <select className="form-select form-select-sm" style={{ width: "130px", textOverflow: "ellipsis" }} value={barChartSelectedFacility} onChange={(e) => {
                                handleFacilityChange(e.target.value);
                            }}>
                                <option value="">All</option>
                                {facilitiesList?.map((facility) => (
                                    <option key={facility} value={facility}>
                                        {facility}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
                <div style={{ width: "100%", height: "250px" }}>
                    <Line data={data} options={options} />
                </div>
                <div className="legend-container" style={{ overflowX: 'auto', textWrap: "nowrap", width: "500px", margin: "auto" }}>
                    {InvoicesReport.map((dataset, index) => (
                        dataset?.label && (
                            <span key={index} style={{ marginRight: '10px', display: 'inline-block' }}>
                                <span style={{ backgroundColor: dataset.backgroundColor, width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></span>
                                {dataset.label}
                            </span>
                        )
                    ))}
                </div>
            </> :
                <DashboardLoader />
            }
        </div>
    );
}
